import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_URLS } from '../../../../helpers/http';
import {
	getStudents,
	getStudentsError,
	getStudentsSuccess,
	deleteStudent,
	deleteStudentSuccess,
	deleteStudentError,
} from '../slices/studentsSlice';
import { StudentsState } from '../types';

function* getStudentsSaga() {
	try {
		const response: StudentsState = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.STUDENT}`
		);
		yield put(getStudentsSuccess({ students: response }));
	} catch (err: any) {
		yield put(getStudentsError({ err: err.response }));
	}
}

function* deleteStudentSaga(action: PayloadAction<number>) {
	const id = action.payload;
	try {
		const response: StudentsState = yield call(
			axios.delete,
			`${API_URLS.BASE_URL}${API_URLS.STUDENT}/${id}`
		);
		yield put(deleteStudentSuccess({ response }));
	} catch (err: any) {
		yield put(deleteStudentError({ err: err.response }));
	}
}

function* studentsSaga() {
	yield all([
		takeLatest(getStudents, getStudentsSaga),
		takeLatest(deleteStudent, deleteStudentSaga),
	]);
}

export default studentsSaga;
