import { useSelector } from 'react-redux';
import dashboardContentImage from '../../../assets/images/dashboard-image.png';
import { AppState } from '../../../store/rootReducer';
import './style/style.css';

const Home = () => {
	const user = useSelector((state: AppState) => state.sidebarMenu);
	return (
		<>
			<div className="welcome-user-container">
				<h1>Bine ai venit, {user.firstName + ' ' + user.lastName}!</h1>
			</div>
			<img
				src={dashboardContentImage}
				className="dashboard-content-image"
				alt="hero-image"
			/>
		</>
	);
};

export default Home;
