import { Row, Col } from 'react-bootstrap';
import privacy from '../../../assets/images/generic-page-images/privacy.jpg';

import '../../Common/styles/genericPageStyles/style.css';

const PrivacyPolicy = () => {
	return (
		<div className="generic-page-container ">
			<Row className="generic-page-background-image">
				<img
					src={privacy}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header">
					<h2>Politica de confidentialitate</h2>
				</div>
			</Row>
			<Row className="generic-page-context-container">
				<Col className="col-10 offset-1 generic-page-context-content">
					<br />
					<h3>Informații generale</h3>
					<br />
					<p>
						Scopul prezentei Politici de confidențialitate este de a
						vă informa cu privire la prelucrarea datelor
						dumneavoastră cu caracter personal ca urmare a
						utilizării platformei{' '}
						<a target="_blank" href="https://www.tech-jobs.ro/">
							{' '}
							https://www.tech-jobs.ro/
						</a>
					</p>
					<p>
						Confidențialitatea datelor dumneavoastră cu caracter
						personal reprezintă una dintre preocupările principale
						ale Universitatii Tehnice „Gheorghe Asachi” din Iaşi, cu
						sediul în Bd. Dimitrie Mangeron, nr. 67, 700050 Iaşi,
						România., în calitate de autor/ administrator al
						aplicatiei{' '}
						<a target="_blank" href="https://www.tech-jobs.ro/">
							{' '}
							https://www.tech-jobs.ro/
						</a>{' '}
						și în calitate de operator de date.
					</p>
					<p>
						Accesarea acestui website implică acceptarea de către
						dumneavoastră a termenilor și condițiilor de utilizare
						și politicii de confidențialitate prezentate mai jos.
					</p>
					<br />
					<h3>Definiții</h3>
					<br />
					<p>
						Date cu caracter personal – orice informații care se
						referă la o persoană fizică identificată sau
						identificabilă.; Informațiile diferite care, adunate,
						pot duce la identificarea unei anumite persoane
						constituie și ele date cu caracter personal.
					</p>
					<p>
						Prelucrarea datelor cu caracter personal – orice
						operațiune sau set de operațiuni care se efectuează
						asupra datelor cu caracter personal, prin mijloace
						automate sau neautomate, cum ar fi colectarea,
						înregistrarea, stocarea, modificarea, utilizarea,
						dezvăluirea către terți prin transmitere, diseminare sau
						în orice alt mod, alăturarea ori combinarea, blocarea,
						ștergerea sau distrugerea;
					</p>
					<p>
						Stocarea – păstrarea pe orice fel de suport a datelor cu
						caracter personal culese;
					</p>
					<p>
						Vizitatori – persoane care accesează anonim platforma{' '}
						<a target="_blank" href="https://www.tech-jobs.ro/">
							{' '}
							https://www.tech-jobs.ro/
						</a>{' '}
						;
					</p>
					<p>
						Utilizatori - persoane care utilizează și completează
						formularele de înregistrare de pe platformă;
					</p>
					<p>
						Operator – orice persoană fizică sau juridică, de drept
						privat ori de drept public, inclusiv autoritățile
						publice, instituțiile și structurile teritoriale ale
						acestora, care stabilește scopul și mijloacele de
						prelucrare a datelor cu caracter personal;
					</p>
					<p>
						Terț – orice persoană fizică sau juridică, de drept
						privat ori de drept public, inclusiv autoritățile
						publice, instituțiile și structurile teritoriale ale
						acestora, alta decât persoana vizată, operatorul ori
						persoana împuternicită sau persoanele care, sub
						autoritatea directa a operatorului sau a persoanei
						împuternicite, sunt autorizate să prelucreze date;
					</p>
					<br />
					<h3>Categoriile de date cu caracter personal prelucrate</h3>
					<br />
					<p>
						Vizitatori - dacă sunteți vizitator al site-ului, datele
						colectate vor fi informațiile pe care le furnizați în
						mod direct în contextul utilizării platformei (cum ar fi
						datele pe care le furnizați în cadrul secțiunii de
						contact);
					</p>
					<p>
						Utilizator – ca si utilizator al platformei, vor fi
						colectate și prelucrate datele dumneavoastră cu caracter
						personal, precum:
					</p>
					<ul>
						<li>
							Date de identificare (nume, prenume, cnp, data
							nașterii)
						</li>
						<li>
							Date de contact (adresă, numere telefon, e-mail)
						</li>
						<li>Vârstă</li>
						<li>Cetățenie</li>
						<li>
							Situația studentaesca actuală ( facultate, an de
							studii, grupa)
						</li>
						<li>Date privind locul de domiciliu (urban/rural)</li>
					</ul>
					<br />
					<h3>Scopul prelucrării datelor cu caracter personal</h3>
					<br />
					<p>
						Informațiile personale transmise și încărcate prin
						intermediul platformei{' '}
						<a target="_blank" href="https://www.tech-jobs.ro/">
							{' '}
							https://www.tech-jobs.ro/
						</a>{' '}
						vor fi prelucrate și utilizate pentru:
					</p>
					<ul>
						<li>
							înscrierea în cadrul proiectul „TechJobs - Stagii de
							practicǎ pentru studenții Universitǎții Tehnice
							"Gheorghe Asachi" din Iași ȋntr-un mediu de lucru
							real”
						</li>
						<li>
							transmiterea buletinului informativ (newsletter)
							prin e-mail;
						</li>
						<li>raportarea datelor către finanțator</li>
					</ul>
					<br />
					<h3>Durata pentru care vă prelucrăm datele</h3>
					<br />
					<p>
						Datele personale colectate vor fi prelucrate și păstrate
						pe întreaga perioadă de implementare a proiectului
						„TechJobs - Stagii de practicǎ pentru studenții
						Universitǎții Tehnice "Gheorghe Asachi" din Iași ȋntr-un
						mediu de lucru real” sau cât timp aveți statutul de
						utilizator înregistrat.
					</p>
					<br />
					<h3>Drepturile de care beneficiati</h3>
					<br />
					<p>
						Utilizatorii platformei{' '}
						<a target="_blank" href="https://www.tech-jobs.ro/">
							{' '}
							https://www.tech-jobs.ro/
						</a>{' '}
						beneficiază de următoarele drepturi:
					</p>
					<ul>
						<li>
							de acces la date personale, respectiv dreptul de a
							cere informații cu privire la prelucrarea datelor cu
							caracter personal, modalitatea în care sunt
							prelucrate datele, scopul în care se face
							prelucrarea, destinatarii sau categoriile de
							destinatari ai datelor, perioada de stocare etc,
							prin transmiterea unei cereri la adresa{' '}
							<a href="mailto:proiect133398@gmail.com">
								proiect133398@gmail.com
							</a>
						</li>
						<li>
							dreptul la rectificarea datelor, în cazul în care
							sesizați că datele sunt greșite sau incomplete
						</li>
						<li>
							dreptul la ștergerea datelor, fără întârzieri
							nejustificate, în cazul în care se aplică unul
							dintre următoarele motive:
						</li>
						<ul>
							<li>
								datele nu mai sunt necesare pentru scopurile
								pentru care au fost colectate sau prelucrate;
							</li>
							<li>
								retragerea consimțământului pe baza căruia are
								loc prelucrarea și nu există niciun temei
								juridic pentru prelucrare;
							</li>
							<li>
								când există neclarități legate de legalitatea
								prelucrării datelor cu caracter personal;
							</li>
							<li>pentru respectarea unei obligații legale;</li>
							<li>
								dacă datele cu caracter personal aparțin unor
								copii, cu vârsta sub 16 ani, iar parinții sau
								persoanele care exercită drepturile părintești
								iși retrag consimțământul acordat.
							</li>
						</ul>
					</ul>
					<p>
						Retragerea acordului/consimțământului prelucrării
						datelor personale are ca efect încetarea comunicarilor
						și prelucrării, precum si a colaborării.
					</p>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default PrivacyPolicy;
