import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import images from '../../../assets/images/images';
import { Container, Row, Col } from 'react-bootstrap';
import './style/style.css';

const Company = () => {
	interface Company {
		companyId: number | null;
		address: string;
		email: string;
		interships: [];
		name: string;
		phone: number;
		taxIdentificationNumber: number;
		website: string;
		description: string;
	}
	const params = useParams();
	const [company, setCompany] = useState<Company>({
		companyId: null,
		address: '',
		email: '',
		interships: [],
		name: '',
		phone: 0,
		taxIdentificationNumber: 0,
		website: '',
		description:''
	});
	const getCompany = async () => {
		await axios
			.get(`https://api-techjobs-dev.marchi.ro/api/company/${params.id}`)
			.then((res) => {
				setCompany(res.data);
			});
	};
	useEffect(() => {
		getCompany();
	}, []);

	return (
		<div className="company-container">
			<Container fluid>
				<Row className="background-image mb-5">
					<img
						src={images.bg_company_sm}
						className="p-0"
						alt="company-background"
					/>
					<div className="company-content">
						<h2>{company.name}</h2>
					</div>
				</Row>
				{company.description && (
					<Row>
						<Col className='offset-1 col-md-10 company-description mt-5' dangerouslySetInnerHTML={{ __html: company.description}}></Col>
					</Row>
				)}
				<Row className="company-contact">
					<Col className="d-flex justify-content-center align-items-center">
						<img src={images.iconAddress} alt="company-icon" />
						<div>
							<h3>Adresă</h3>
							<p>{company.address}</p>
						</div>
					</Col>
					<Col className="d-flex justify-content-center align-items-center">
						<img src={images.iconEmail} alt="company-icon" />
						<div>
							<h3>Email</h3>
							<p>{company.email}</p>
						</div>
					</Col>
				</Row>
				<Row className="background-image">
					<img
						src={images.bg_map}
						className="p-0"
						alt="company-background"
					/>
				</Row>
				<Row className="register-container">
					<Col
						xs={8}
						className="d-flex flex-column justify-content-center align-items-end "
					>
						<h2>
							Descoperă stagiul de practică care ți se potrivește
						</h2>
						<p>Aplică la una din cele peste 200 de companii</p>
					</Col>
					<Col className="d-flex justify-content-start align-items-center">
						<a
							href="/"
							className="btn btn-outline-light btn-lg register-btn"
							role="button"
							aria-pressed="true"
						>
							Înregistrează-te
						</a>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Company;
