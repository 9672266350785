import { Row, Col } from 'react-bootstrap';
import '../../Common/styles/genericPageStyles/style.css';
import generalImg from '../../../assets/images/generic-page-images/anunturi.jpg';

const ProjectGeneralObjective = () => {
	return (
		<div className="generic-page-container ">
			<Row className="generic-page-background-image">
				<img
					src={generalImg}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header">
					<h2>Obiectiv general</h2>
				</div>
			</Row>
			<Row className="generic-page-context-container">
				<Col className="col-10 offset-1  generic-page-context-content">
					<br />
					<br />
					<p>
						Proiectul{' '}
						<b>
							„TechJobs - Stagii de practicǎ pentru studenții
							Universitǎții Tehnice "Gh Asachi" din Iași ȋntr-un
							mediu de lucru real” cod MySmis 133398
						</b>{' '}
						- nr. contract 10574/23.09.2020 se încadreaza în AXA
						PRIORITARA 6 „Educație și competențe”, OBIECTIVUL
						TEMATIC 10 Efectuarea de investiții în domeniul
						educației, al formării și al formării profesionale în
						vederea dobandirii de competente și a învțării pe tot
						parcursul vietii, PRIORITATEA DE INVESTITII 10.iv
						Sporirea relevanței pe piața forțelor de muncă a
						educației și a sistemelor de formare, facilitarea
						tranziției de la educație la piața forței de muncă și
						consolidarea formării și a sistemelor de formare
						profesională, precum și a calității lor, inclusiv prin
						mecanisme de anticipare a compețentelor, adaptarea
						programelor de învățământ și instituirea și dezvoltarea
						unor sisteme de învățare la locul de muncă, inclusiv a
						unor sisteme de învățare duală și programe de ucenicie,
						OBIECTIV SPECIFIC (OS): OS 6.13 ”Creșterea numărului
						absolvenților din învățământul terțiar universitar și
						non universitar care își găsesc un loc de muncă urmare a
						accesului la activități de învățare la un potențial loc
						de muncă / cercetare / inovare, cu accent pe sectoarele
						economice cu potențial competitiv identificate conform
						SNC și domeniile de specializare inteligentă conform
						SNCDI”.
					</p>
					<p>
						Obiectivul general proiectului constă în creşterea cu
						129 a numărului absolvenţilor Universităţii Gheorghe
						Asachi din Iași care îşi găsesc un loc de muncă urmare a
						accesului la activităţi de învăţare la un potenţial loc
						de muncă / cercetare / inovare, cu accent pe sectoarele
						economice cu potențial competitiv identificate conform
						SNC şi domeniile de specializare inteligentă conform
						SNCDI, creşterea cu 321 a numărului studenţilor
						Universităţii Gheorghe Asachi din Iași sprijiniţi pentru
						tranziţia de la şcoală la piaţa muncii prin participarea
						la stagii de practică la un potenţial loc de muncă /
						cercetare / inovare, cu accent pe sectoarele economice
						cu potențial competitiv identificate conform SNC şi
						domeniile de specializare inteligentă conform SNCDI cu
						respectarea unor proceduri clare şi a unor standarde de
						calitate definite.
					</p>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default ProjectGeneralObjective;
