import { useEffect, useState, useRef } from 'react';
import useImagePreloader from '../../../helpers/useImagePreloader';

import { ListGroup, Form } from 'react-bootstrap';

import internshipImg from '../../../assets/images/Dashboard/DashboardImages/internship.jpg';
import './style/style.css';
import { useDispatch, useSelector } from 'react-redux';

import {
	deleteInternship,
	getInternships,
	postInternship,
} from '../../Common/slices/internshipsSlice';
import { AppState } from '../../../store/rootReducer';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import PageLoading from '../../../Components/PageLoading';
import { Link } from 'react-router-dom';
import { getDashboardCompanies } from '../DashboardCompanies/slices/dashboardCompaniesSlice';
import { getInternSessions } from '../InternshipSession/slices/internSessionSlice';

import infoLogo from '../../../assets/images/Dashboard/dashboard-icons/info.png';

const Internships = () => {
	const internships = useSelector((state: AppState) => state.internships);
	const preloadSrcList: string[] = [internshipImg];
	const [showPopup, setShowPopup] = useState({
		show: false,
		internshipId: '',
	});
	const { imagesPreloaded } = useImagePreloader(preloadSrcList);
	const [showAddInternship, setShowAddInternship] = useState({
		show: false,
		emptyInputs: false,
	});
	const nameRef = useRef<HTMLInputElement>(null);
	const descriptionRef = useRef<HTMLInputElement>(null);
	const company = useRef<HTMLSelectElement>(null);
	const session = useRef<HTMLSelectElement>(null);
	const sessionStage = useRef<HTMLSelectElement>(null);
	const [internshipDate, setInternshipDate] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	const companies = useSelector(
		(state: AppState) => state.dashboardCompanies
	);
	const internSession = useSelector(
		(state: AppState) => state.internSessions
	);

	const handleDeleteInternship = () => {
		const id = showPopup.internshipId;
		dispatch(deleteInternship({ id }));
		setShowPopup({
			show: false,
			internshipId: '',
		});
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getInternships());
		dispatch(getDashboardCompanies());
		dispatch(getInternSessions());
	}, []);

	useEffect(() => {
		if (internships.deletedPending === false) {
			dispatch(getInternships());
		}
	}, [internships.deletedPending]);

	useEffect(() => {
		if (internships.postPending === false) {
			dispatch(getInternships());
			setShowAddInternship({
				emptyInputs: false,
				show: false,
			});
		}
	}, [internships.postPending]);

	const addInternship = () => {
		setShowAddInternship({
			...showAddInternship,
			show: true,
		});
	};

	const postNewInternship = () => {
		if (
			nameRef.current !== null &&
			nameRef.current.value !== '' &&
			descriptionRef.current !== null &&
			descriptionRef.current.value !== '' &&
			company.current !== null &&
			company.current.value !== '' &&
			session.current !== null &&
			session.current.value !== '' &&
			sessionStage.current !== null &&
			sessionStage.current.value !== ''
		) {
			dispatch(
				postInternship({
					name: nameRef.current.value,
					description: descriptionRef.current.value,
					startDate: moment(internshipDate.startDate)
						.toISOString()
						.split('.')[0],
					endDate: moment(internshipDate.endDate)
						.toISOString()
						.split('.')[0],
					companyId: parseInt(company.current.value),
					internshipStatusId: parseInt(sessionStage.current.value),
					internshipSessionId: parseInt(session.current.value),
				})
			);
		} else {
			setShowAddInternship({
				...showAddInternship,
				emptyInputs: true,
			});
		}
	};

	return (
		<div className="internship-content">
			{showPopup.show && (
				<div className="confirmation-popup">
					<div className="confirmation-container">
						<h2>Sunteti sigur ca vreti sa stergeti stagiul?</h2>
						<div className="confirm-buttons">
							<button
								className="btn btn-primary mr-5"
								onClick={() => handleDeleteInternship()}
							>
								Da
							</button>
							<button
								className="btn btn-danger"
								onClick={() =>
									setShowPopup({
										show: false,
										internshipId: '',
									})
								}
							>
								Nu
							</button>
						</div>
					</div>
				</div>
			)}
			<div className="internship-header">
				<h1>Stagii</h1>
			</div>
			<img src={internshipImg} alt="internship-logo" />
			{!imagesPreloaded && internships.pending ? (
				<PageLoading />
			) : internships.deletedPending ? (
				<PageLoading />
			) : null}

			<div className="internship-list">
				{showAddInternship.show && (
					<div className="post-internship-container">
						<button
							type="button"
							className="btn-close btn-close-white close-add-internship"
							aria-label="Close"
							onClick={() => {
								setShowAddInternship({
									...showAddInternship,
									show: false,
								});
							}}
						></button>
						<h1>Introduceti un nou stagiu</h1>
						{showAddInternship.emptyInputs === true && (
							<div className="alert alert-danger" role="alert">
								Va rugam completati toate campurile!
							</div>
						)}

						<Form.Group className="mb-3">
							<Form.Label>Numele stagiului</Form.Label>
							<Form.Control
								type="text"
								placeholder="Nume stagiu..."
								ref={nameRef}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Descriere stagiu</Form.Label>
							<Form.Control
								type="text"
								placeholder="Descriere stagiu..."
								ref={descriptionRef}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Alege Compania</Form.Label>
							<Form.Select
								aria-label="Default select example"
								ref={company}
							>
								{companies.companies.map((el: any) => {
									return (
										<option value={el.companyId}>
											{el.name}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Perioada de internship</Form.Label>
							<Form.Select
								aria-label="Default select example"
								ref={session}
							>
								{internSession.sessions.map((el: any) => {
									return (
										<option value={el.internshipSessionId}>
											{el.title}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Stadiul stagiului</Form.Label>
							<Form.Select
								aria-label="Default select example"
								ref={sessionStage}
							>
								<option value="1">Ascuns</option>
								<option value="2">
									Deschis pentru candidatura
								</option>
								<option value="3">
									Acest stagiu nu mai accepta candidaturi
								</option>
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Start Stagiu</Form.Label>
							<DatePicker
								className="form-control"
								selected={internshipDate.startDate}
								onChange={(selectedDate: any) => {
									setInternshipDate((state) => {
										return {
											...state,
											startDate: selectedDate,
										};
									});
								}}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Sfarsit Stagiu</Form.Label>
							<DatePicker
								className="form-control"
								selected={internshipDate.endDate}
								onChange={(selectedDate: any) => {
									setInternshipDate((state) => {
										return {
											...state,
											endDate: selectedDate,
										};
									});
								}}
							/>
						</Form.Group>
						<button
							type="button"
							className="btn btn-outline-info text-light"
							onClick={() => postNewInternship()}
						>
							Adauga stagiu
						</button>
					</div>
				)}
				{!showAddInternship.show && (
					<>
						{internships.error !== '' && (
							<div className="alert alert-danger" role="alert">
								{internships.error}
							</div>
						)}
						<div className="add-internship">
							<h3>Lista stagiilor</h3>

							<button
								type="button"
								className="btn btn-primary"
								onClick={() => addInternship()}
							>
								Adauga stagiu
							</button>
						</div>
						<ListGroup as="ol" className="mb-5">
							{internships.internships.map((el: any) => {
								return (
									<ListGroup.Item
										as="li"
										action
										key={el.internshipId}
										className="d-flex justify-content-between flex-column"
									>
										<div className="internship-status">
											<p
												className={`internship-info${el.internshipStatusId}`}
											>
												<img
													src={infoLogo}
													alt="info"
												/>
												{el.internshipStatusId === 1
													? 'Ascuns'
													: el.internshipStatusId ===
													  2
													? 'Deschis pentru candidatura'
													: el.internshipStatusId
													? 'Stagiul nu mai accepta candidati'
													: null}
											</p>
										</div>
										<div className="internship-content d-flex justify-content-between">
											<Link
												to={`/dashboard/stagii/${el.internshipId}`}
												key={el.internshipId}
											>
												<div className="ms-2 me-auto">
													<div className="fw-bold">
														{el.name}
													</div>
													<p className="internships-description">
														{el.description}
													</p>
												</div>
											</Link>
											<button
												type="button"
												className="btn btn-danger mt-1 delete-internship"
												onClick={() =>
													setShowPopup({
														show: true,
														internshipId:
															el.internshipId,
													})
												}
											>
												🗑️ Sterge stagiu
											</button>
										</div>
									</ListGroup.Item>
								);
							})}
						</ListGroup>
					</>
				)}
			</div>
		</div>
	);
};

export default Internships;
