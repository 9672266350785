import { useEffect, useRef, useState } from 'react';
import { Navbar, Nav, NavDropdown, Col } from 'react-bootstrap';
import images from '../../assets/images/images.jsx';
import './style/style.css';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/rootReducer.js';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';

const Header = () => {
	const loginState = useSelector((state: AppState) => state.login);
	const checkCookie: string | undefined = Cookies.get('token');
	const cookieRole: any = checkCookie !== undefined && jwtDecode(checkCookie);
	const imgContainer = useRef<HTMLDivElement>(null);
	const isAuthenticated: any =
		checkCookie !== undefined
			? cookieRole.UserType
			: loginState.role
			? loginState.role
			: undefined;
	const [show, setShow] = useState(false);
	const [showGt, setShowGt] = useState(false);
	const [showDU, setShowDU] = useState(false);
	const [showSG, setShowSG] = useState(false);
	const [showVIS, setShowVIS] = useState(false)

	const showDropdown = (e: any) => {
		setShow(!show);
	};
	const hideDropdown = (e: any) => {
		setShow(false);
	};

	const showDropdownGt = (e: any) => {
		setShowGt(!show);
	};
	const hideDropdownGt = (e: any) => {
		setShowGt(false);
	};

	const showDropdownDU = (e: any) => {
		setShowDU(!showDU);
	};
	const hideDropdownDU = (e: any) => {
		setShowDU(false);
	};

	const showDropdownSG = (e: any) => {
		setShowSG(!showSG);
	};
	const hideDropdownSG = (e: any) => {
		setShowSG(false);
	};

	const showDropdownVIS = (e: any) => {
		setShowVIS(!showVIS);
	};
	const hideDropdownVIS = (e: any) => {
		setShowVIS(false);
	};

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				document.documentElement.scrollTop > 50 &&
				imgContainer.current !== null
			) {
				imgContainer.current.classList.remove('images-container');
				imgContainer.current.classList.add('images-container-shrink');
			} else if (imgContainer.current !== null) {
				imgContainer.current.classList.remove('images-container-shrink');
				imgContainer.current.classList.add('images-container');
			}
		});
	}, []);

	return (
		<div className="menu-container">
			<div className="scroll-header-container">
				<Col className="images-container" ref={imgContainer}>
					<img src={images.logoeu} alt="Logo EU" />
					<img src={images.logogov} alt="Logo EU" />
					<img src={images.logois} alt="Logo EU" />
				</Col>
				<Col className="text-container">
					<span>
						Proiect cofinanțat din Fondul Social European prin Programul
						Operațional Capital Uman 2014-2020
					</span>
				</Col>
			</div>
			<Navbar bg="light" expand="xl" className="navbar-container">
				<Navbar.Brand href="/">
					<img src={images.logo} alt="Logo" />
				</Navbar.Brand>
				<Navbar.Toggle />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
					<Nav className="ml-auto mr-5">
						<NavDropdown
							title="PROIECT"
							id="collasible-nav-dropdown"
							show={show}
							onMouseEnter={showDropdown}
							onMouseLeave={hideDropdown}
						>
							<NavDropdown.Item href="/context-proiect">
								CONTEXT
							</NavDropdown.Item>
							<NavDropdown.Item href="/obiectiv-general-proiect">
								OBIECTIV GENERAL
							</NavDropdown.Item>
							<NavDropdown.Item href="/obiective-specifice-proiect">
								OBIECTIVE SPECIFICE
							</NavDropdown.Item>
							<NavDropdown.Item href="/justificare-proiect">
								JUSTIFICARE
							</NavDropdown.Item>
							<NavDropdown.Item href="/rezultate-proiect">
								REZULTATE
							</NavDropdown.Item>
							<NavDropdown.Item href="/grup-tinta">GRUP TINTA</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link href="/stagii">STAGII</Nav.Link>
						<Nav.Link href="/parteneri">PARTENERI</Nav.Link>
						<NavDropdown
							title="DOCUMENTE UTILE"
							id="collasible-nav-dropdown"
							show={showGt}
							onMouseEnter={showDropdownGt}
							onMouseLeave={hideDropdownGt}
						>
							<NavDropdown.Item href="/documente-utile-metodologie-selectie">
								Metodologie selectie grup tinta
							</NavDropdown.Item>
							<NavDropdown.Item href="/documente-utile-metodologie-derulare">
								Metodologie derulare stagii de practica
							</NavDropdown.Item>
							<NavDropdown.Item
								tag={Link}
								href="https://tech-jobs.ro/static/files/2022/Notificare_GDPR.pdf"
								target="_blank"
							>
								Notificare GDPR
							</NavDropdown.Item>
							<NavDropdown.Item
								tag={Link}
								href="https://tech-jobs.ro/static/files/2022/Metodologie_consiliere.pdf"
								target="_blank"
							>
								Metodologie de consiliere
							</NavDropdown.Item>
							<NavDropdown.Item
								tag={Link}
								href="https://tech-jobs.ro/static/files/2022/Metodologie_privind_politica_de_confidentialitate.pdf"
								target="_blank"
							>
								Metodologie privind politica de confidentialitate
							</NavDropdown.Item>														
							<NavDropdown.Divider />
							<NavDropdown
								title="Vizite externe"
								id="collasible-nav-dropdown"
								show={showVIS}
								onMouseEnter={showDropdownVIS}
								onMouseLeave={hideDropdownVIS}
								drop="start"
								className="multi-level-dropdown"
							>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-arpor" target="_blank">
									Arpor
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-fevi" target="_blank">
									Compania FEVI
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-taverna" target="_blank">
									Compania Taverna
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-giardini-d-oriente" target="_blank">
									Giardini D'Oriente
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-metapontum-agrobios" target="_blank">
									Metapontum Agrobios
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-alsia-ricerca" target="_blank">
									Alsia Ricerca
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-primaria-bari" target="_blank">
									Primaria din Bari
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-universitatea-bari" target="_blank">
									Universitatea din Bari
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-politehnica-bari" target="_blank">
									Politehnica din Bari
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-eredi-marinelli" target="_blank">
									Eredi Marinelli
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-fablab-poliba" target="_blank">
									Fablab Poliba
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-sitec" target="_blank">
									Sitec
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-tecnopolis" target="_blank">
									Tecnopolis
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-mtm-project" target="_blank">
									MTM Project
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-inreslab" target="_blank">
									InResLab
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-mimi" target="_blank">
									Mimi Srl
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-ditro-designia" target="_blank">
									Ditro Designia
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/visits-vitone-eco" target="_blank">
									Vitone Eco
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown
								title="Cum sa cauti un job"
								id="collasible-nav-dropdown"
								show={showSG}
								onMouseEnter={showDropdownSG}
								onMouseLeave={hideDropdownSG}
								drop="start"
								className="multi-level-dropdown"
							>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-cv-ul-care-atrage-atentia" target="_blank">
									CV-ul care atrage atentia
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-cele-mai-bune-siteuri-job" target="_blank">
									Cele mai bune 10 site-uri de căutare a locurilor de muncă din 2022
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-formatul-cv-ului" target="_blank">
									Formatul CV-ului	
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-adreasa-profesionala" target="_blank">
									Adresa de e-mail profesională
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/interviul-video-pentru-angajare" target="_blank">
									Interviul video pentru angajare
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-emailul-de-angajare" target="_blank">
									Emailul de angajare
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-corespondenta-electronica-pentru-angajare" target="_blank">
									Corespondenta electronică pentru angajare
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-interviul_de_angajare_intrebari_incomode" target="_blank">
									Interviul de angajare - întrebări incomode si recomandări
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-inportanta_soft_skills" target="_blank">
									Importanta abilitătilor de relationare (Soft Skills)
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/anunt-cum-va-puteti-dezvolta-competentele" target="_blank">
									Cum vă puteti dezvolta propriile competente interpersonale?
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/tips-primul-pas-in-cariera" target="_blank">
									Căutarea - Primul pas important către o cariera de succes
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/tips-munca-la-distanta-si-joburile-hibride" target="_blank">
									Munca la distanța (telemunca) si job-urile hibride
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/tips-managementul-timpului" target="_blank">
									Managementul timpului
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/tips-tipuri-de-competente-interpersonale" target="_blank">
									Tipuri de competențe interpersonale
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/tips-relationarea-cu-oamenii-dificili" target="_blank">
									Relaționarea cu oamenii dificili la locul de muncă
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/tips-abilitati-de-gestionare-a-timpului" target="_blank">
									Abilități de gestionare a timpului
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/tips-munca-la-distanta-si-dezvoltarea-personala" target="_blank">
									Munca la distanță și dezvoltarea profesională
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="/anunturi/2022/tips-cum-sa-fim-mai-productivi" target="_blank">
								Cum să fiți mai productivi la locul de muncă
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown
								title="Regulament concurs SA5.1"
								id="collasible-nav-dropdown"
								show={showDU}
								onMouseEnter={showDropdownDU}
								onMouseLeave={hideDropdownDU}
								drop="start"
								className="multi-level-dropdown"
							>
								<NavDropdown.Item tag={Link} href="https://tech-jobs.ro/static/files/2022/1.Regulament_concurs_SA5.1.pdf" target="_blank">
									1.Regulament concurs SA5.1
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="https://tech-jobs.ro/static/files/2022/2.Calendar_concurs_SA5.1.pdf" target="_blank">
									2.Calendar concurs SA5.1
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="https://tech-jobs.ro/static/files/2022/3.Anexa_1_OPIS_DOSAR.pdf" target="_blank">
									3.Anexa 1 OPIS_DOSAR
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="https://tech-jobs.ro/static/files/2022/4.Anexa_2_Cerere_inscriere_in_cadrul_concursului_de_premiere.docx" target="_blank">
									4.Anexa 2 Cerere inscriere
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="https://tech-jobs.ro/static/files/2022/Declaratie-GDPR-premii.docx" target="_blank">
									Declaratie GDPR premii
								</NavDropdown.Item>
								<NavDropdown.Item tag={Link} href="https://tech-jobs.ro/static/files/2022/Model_eseu_motivational.docx" target="_blank">
									Model eseu motivational
								</NavDropdown.Item>
							</NavDropdown>							
						</NavDropdown>
						{/* <Nav.Link href="/articles">ARTICOLE</Nav.Link> */}
						{/* <Nav.Link href="/#">ECHIPA</Nav.Link>
					<Nav.Link href="/#">DATE TEHNICE</Nav.Link>
					<Nav.Link href="/#">FAQ</Nav.Link> */}
						{/* <Nav.Link href="/documente-utile">DOCUMENTE UTILE</Nav.Link> */}
						<Nav.Link href="/anunturi">ANUNTURI</Nav.Link>
						<Nav.Link href="/contact">CONTACT</Nav.Link>
						{isAuthenticated === undefined ? (
							<Nav.Link href="/login">LOGIN</Nav.Link>
						) : (
							<Nav.Link href="/dashboard">DASHBOARD</Nav.Link>
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default Header;
