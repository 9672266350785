import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import images from '../../../assets/images/images';
import { Form } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/rootReducer';
import {
	getDashboardCompany,
	putDashboardCompany,
} from './slices/dashboardCompanySlice';

import phone from '../../../assets/images/Dashboard/dashboard-icons/phone.png';
import websiteImg from '../../../assets/images/Dashboard/dashboard-icons/website.png';

import PhoneInput from 'react-phone-number-input';

import './style/style.css';
import PageLoading from '../../../Components/PageLoading';

const DashboardCompany = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const dashboardCompany = useSelector(
		(state: AppState) => state.dashboardCompany
	);
	const [showEdit, setShowEdit] = useState(false);
	const [phoneValue, setPhoneValue] = useState<any>(dashboardCompany.phone);
	const [editCompanyError, setEditCompanyError] = useState({
		show: false,
		errMsg: '',
		edited: 'no',
	});

	const companyNameRef = useRef<HTMLInputElement>(null);
	const addressRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const websiteRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		dispatch(getDashboardCompany(params.id));
	}, []);

	useEffect(() => {
		dashboardCompany.phone.length > 0 &&
			setPhoneValue(dashboardCompany.phone);
	}, [dashboardCompany.phone]);

	const handleEditCompany = () => {
		const phoneNumber = (
			document.getElementById('edit-phone-number') as HTMLInputElement
		).value;
		if (
			companyNameRef.current !== null &&
			companyNameRef.current.value !== '' &&
			addressRef.current !== null &&
			addressRef.current.value !== '' &&
			phoneNumber.length === 15 &&
			emailRef.current !== null &&
			emailRef.current.value !== '' &&
			companyNameRef.current !== null &&
			companyNameRef.current.value !== '' &&
			websiteRef.current !== null &&
			websiteRef.current.value !== '' &&
			editCompanyError.edited === 'yes'
		) {
			setEditCompanyError({
				...editCompanyError,
				show: false,
				edited: 'yes',
				errMsg: '',
			});
			const regex =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (regex.test(emailRef.current.value) === false) {
				setEditCompanyError({
					...editCompanyError,
					show: true,
					errMsg: 'Adresa de email nu este valida',
				});
			} else {
				dispatch(
					putDashboardCompany({
						id: params.id,
						name: companyNameRef.current.value,
						address: addressRef.current.value,
						phone: phoneNumber,
						email: emailRef.current.value,
						website: websiteRef.current.value,
						isVisible: false,
					})
				);
			}
		} else {
			if (phoneNumber.length < 15) {
				setEditCompanyError({
					...editCompanyError,
					show: true,
					errMsg: 'Numarul de telefon nu este valid',
				});
			} else if (editCompanyError.edited === 'no') {
				setEditCompanyError({
					...editCompanyError,
					show: true,
					errMsg: 'Va rugam modificati compania',
				});
			} else
				setEditCompanyError({
					...editCompanyError,
					show: true,
					errMsg: 'Va rugam completati toate campurile',
				});
		}
	};

	useEffect(() => {
		if (
			dashboardCompany.putPending === false &&
			dashboardCompany.err === ''
		) {
			setShowEdit(false);
			dispatch(getDashboardCompany(params.id));
		}
		if (dashboardCompany.err !== '') {
			setEditCompanyError({
				...editCompanyError,
				show: true,
				errMsg: dashboardCompany.err,
			});
		}
	}, [dashboardCompany.putPending]);

	return (
		<div className="dashboard-company-container">
			{dashboardCompany.pending && <PageLoading />}
			<div className="company-background-image">
				<img
					src={images.bg_company}
					className="p-0"
					alt="company-background"
				/>

				<div className="dashboard-company-name d-flex flex-column">
					<h2>{dashboardCompany.name}</h2>
					{!showEdit && (
						<button
							className="btn btn-primary"
							onClick={() => setShowEdit(true)}
							id="edit-company-btn"
						>
							Editeaza compania
						</button>
					)}
				</div>
			</div>
			{!showEdit && (
				<div className="dashboard-company-content-container">
					<div className="dashboard-company-content d-flex justify-content-center align-items-center">
						<img src={images.iconAddress} alt="company-icon" />
						<div className="w-100">
							<h3>Adresă</h3>
							<p>{dashboardCompany.address}</p>
						</div>
					</div>
					<div className="dashboard-company-content d-flex justify-content-center align-items-center">
						<img src={images.iconEmail} alt="company-icon" />
						<div className="w-100">
							<h3>Email</h3>
							<p>{dashboardCompany.email}</p>
						</div>
					</div>
					<div className="dashboard-company-content d-flex justify-content-center align-items-center">
						<img src={phone} alt="company-icon" />
						<div className="w-100">
							<h3>Telefon</h3>
							<p>{dashboardCompany.phone}</p>
						</div>
					</div>
					<div className="dashboard-company-content d-flex justify-content-center align-items-center">
						<img src={websiteImg} alt="company-icon" />
						<div className="w-100">
							<h3>Website</h3>
							<p>{dashboardCompany.website}</p>
						</div>
					</div>
				</div>
			)}

			{showEdit && (
				<div className="edit-company-container d-flex flex-column justify-content-center align-items-center">
					<Form className="d-flex justify-content-center flex-column edit-company">
						{editCompanyError.show && (
							<div className="alert alert-danger" role="alert">
								{editCompanyError.errMsg !== '' &&
									editCompanyError.errMsg}
							</div>
						)}
						<button
							type="button"
							className="btn-close btn-close close-edit-company"
							aria-label="Close"
							onClick={() => setShowEdit(false)}
						></button>
						<Form.Group className="mb-3">
							<Form.Label>Nume Companie</Form.Label>
							<Form.Control
								type="text"
								name="company-name"
								onChange={() =>
									setEditCompanyError({
										...editCompanyError,
										edited: 'yes',
									})
								}
								ref={companyNameRef}
								defaultValue={dashboardCompany.name}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Adresa</Form.Label>
							<Form.Control
								onChange={() =>
									setEditCompanyError({
										...editCompanyError,
										edited: 'yes',
									})
								}
								type="text"
								name="address"
								ref={addressRef}
								defaultValue={dashboardCompany.address}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Email</Form.Label>
							<Form.Control
								onChange={() =>
									setEditCompanyError({
										...editCompanyError,
										edited: 'yes',
									})
								}
								type="text"
								name="Email"
								ref={emailRef}
								defaultValue={dashboardCompany.email}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Telefon</Form.Label>
							<PhoneInput
								international
								country="RO"
								defaultCountry="RO"
								value={phoneValue}
								onChange={(phone) => {
									setPhoneValue(phone);
									setEditCompanyError({
										...editCompanyError,
										edited: 'yes',
									});
								}}
								maxLength={15}
								id="edit-phone-number"
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Website</Form.Label>
							<Form.Control
								onChange={() =>
									setEditCompanyError({
										...editCompanyError,
										edited: 'yes',
									})
								}
								type="text"
								name="website"
								ref={websiteRef}
								defaultValue={dashboardCompany.website}
							/>
						</Form.Group>
						<button
							type="button"
							className="btn btn-info mt-2 text-white"
							onClick={handleEditCompany}
							id="edit-company"
						>
							Salveaza modificarile
						</button>
					</Form>
				</div>
			)}
		</div>
	);
};

export default DashboardCompany;
