import { ListGroup, ListGroupItem } from 'react-bootstrap';
import './style/style.css';
const PressReleasePage = ({
	allPressReleases,
}: {
	allPressReleases: { url: string; title: string }[];
}) => {
	return (
		<div className="press-releases-container">
			<div className="press-releases-content">
				<h1>Anunturi</h1>
				<ListGroup>
					{allPressReleases.map((el: any) => {
						if(!el.isHidden)
						{
						return (
							
							<ListGroup.Item key={el.title}>
								📄 <a href={`/${el.url}`}>{el.title}</a>
							</ListGroup.Item>
						);
						}
					})}
				</ListGroup>
			</div>
		</div>
	);
};

export default PressReleasePage;
