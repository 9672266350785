import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Row, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';

import './style/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import CMMI from '../../assets/images/logos/logo-cmmi-104.png';
import techJobs from '../../assets/images/logo-blue.png';
import tuIasiLogo from '../../assets/images/logos/tuiasi-logo-combined.png';
import { postLoginDetails } from './slices/userSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppState } from '../../store/rootReducer';
import PageLoading from '../../Components/PageLoading';

const LoginPage = () => {
	const [showPassword, setShowPassword] = useState('password');
	const userState = useSelector((state: AppState) => state.login);
	const previousUrl = window.sessionStorage.getItem('previousUrl');
	const username = useRef<HTMLInputElement>(null);
	const password = useRef<HTMLInputElement>(null);
	const invalidFeedBack = useRef<any>(null);

	const show = (
		<FontAwesomeIcon icon={faEyeSlash} className="eye eye-closed" />
	);
	const hide = <FontAwesomeIcon icon={faEye} className="eye eye-open" />;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const changeInputType = () => {
		showPassword === 'password'
			? setShowPassword('text')
			: setShowPassword('password');
	};

	const submitCredentials = () => {
		if (username.current !== null && password.current !== null) {
			if (
				username.current.value !== '' &&
				password.current.value !== ''
			) {
				dispatch(
					postLoginDetails({
						username: username.current!.value,
						password: password.current!.value,
					})
				);
			} else if (
				username.current.value === '' ||
				password.current.value === ''
			) {
				if (invalidFeedBack.current !== null) {
					invalidFeedBack.current.style.display = 'block';
					invalidFeedBack.current.innerHTML =
						'Vă rugăm să completați ambele câmpuri!';
				}
			}
		}
	};

	useEffect(() => {
		let userToken: string | undefined = Cookies.get('token');
		if (userToken) {
			JSON.parse(userToken).token && navigate('/dashboard');
		}

		if (userState.error === 'Unauthorized' || userState.error !== '') {
			if (invalidFeedBack.current !== null)
				invalidFeedBack.current.style.display = 'block';
			invalidFeedBack.current.innerHTML = 'Email sau parola incorectă!';
		}

		if (userState.accessToken) {
			Cookies.set(
				'token',
				JSON.stringify({
					token: userState.accessToken,
					expires: userState.expires,
				}),
				{ expires: new Date(userState.expires) }
			);

			if (previousUrl !== null) {
				navigate(-1);
			} else {
				navigate('/dashboard', { replace: true });
			}
		}
	}, [userState]);

	return (
		<div className="login-page">
			{userState.pending && <PageLoading style={{ marginRight: 0 }} />}
			<Container
				fluid
				className="d-flex justify-content-center flex-column align-items-center login-input-container"
			>
				<Row className="d-flex flex-column img-container text-center">
					<Col>
						<img src={CMMI} alt="cmmi-logo" id="cmmi-logo" />
					</Col>
					<Col>
						<img
							src={techJobs}
							alt="tech-jobs-logo"
							id="techjobs-logo"
						/>
					</Col>
				</Row>
				<Row className="d-flex flex-column input-container">
					<Col>
						<input type="text" placeholder="Email" ref={username} />
					</Col>
					<Col>
						<input
							type={showPassword}
							placeholder="Password"
							id="password"
							ref={password}
						/>
						<span onClick={changeInputType}>
							{showPassword === 'password' ? show : hide}
						</span>
						<div
							className="invalid-feedback"
							ref={invalidFeedBack}
						></div>
					</Col>
				</Row>
				<Row className="mt-3">
					<Button
						variant="outline-primary"
						className="login-btn"
						onClick={submitCredentials}
					>
						LOGIN
					</Button>
				</Row>
				<Row className="tuiasi-logo">
					<img src={tuIasiLogo} alt="tuiasi-logo" />
				</Row>
			</Container>
		</div>
	);
};

export default LoginPage;
