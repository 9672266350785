import { Row, Col } from 'react-bootstrap';
import '../../Common/styles/genericPageStyles/style.css';
import rezultate from '../../../assets/images/generic-page-images/rezultate.jpg';

const ProjectResults = () => {
	return (
		<div className="generic-page-container ">
			<Row className="generic-page-background-image">
				<img
					src={rezultate}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header">
					<h2>Rezultate</h2>
				</div>
			</Row>
			<Row className="generic-page-context-container">
				<Col className="col-10 offset-1 mt-5  generic-page-context-content">
					<br />
					<br />
					<p>
						Rezultatele urmărite sunt: creșterea cu 321 a numărului
						de studenți sprijiniți în tranziția de la școală la
						piața muncii cu accent pe sectorul creativ recunoscut ca
						sector economic cu potențial competitiv identificat
						conform SNC și domeniile de specializare inteligentă
						conform SNCDI. Prin proiect se va crea o conexiune între
						specializările TU Iași și domeniile de specializare
						inteligentă. Proiectul asigură continuarea/consolidarea
						parteneriatelor existente între TU Iași și partenerii de
						practică actuali prin dezvoltarea împreună cu aceștia a
						unui sistem funcțional de stagii de practică și, în
						același timp, proiectul asigură formarea de parteneriate
						între TU Iași și noi parteneri de practică.
					</p>
					<p>
						1. R1- Un sistem funcțional de stagii de practică la un
						potențial angajator, 30 de potențiali angajatori parte
						din sistemul multifunctional, 4 parteneriate noi cu
						potențiali angajatori, o aplicație online ca suport
						pentru sistemul funcțional de stagii de practică la un
						potențial angajator.
					</p>
					<p>
						2. R2-Sprijinirea a 321 studenți pentru trecerea pe
						piata muncii - stagii de practică, dezvoltarea și
						implementarea programelor de internship/traineeship,
						furnizarea de servicii de consiliere și orientare
						profesională pentru 321 de studenți, 36 studenți
						participanți la stagii de stagii de practică externă.
					</p>
					<p>
						3. R3 -Un program de antreprenoriat și CSR
						(responsabilitate socială).
					</p>
					<p>
						4. R4- 321 persoane informate asupra proiectului și
						atrase în proiect, 2 anunțuri în presa scrisa și online
						pentru lansarea, respectiv închiderea proiectului, o
						identitate vizuala a proiectului cu respectarea
						Manualului de Identitate Vizuala POCU 2014-2020, un afiș
						de minim A3 expus vizibil la sediul fiecarui partener,
						toate documentele adresate publicului și grupului țintă
						vor include o mențiune cu privire la faptul ca
						operațiunea a fost sprijinita în cadrul FSE prin POCU
						2014-2020, vor fi publicate informații privind
						proiectul: denumirea Beneficiarului, titlul și rezumatul
						Proiectului, valoarea totală a finanțarii, datele de
						începere și de finalizare ale Proiectului, locul de
						implementare al acestuia.
					</p>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default ProjectResults;
