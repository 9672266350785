import { createSlice } from '@reduxjs/toolkit';
import { StudentsState } from '../types';

const initialState: StudentsState = {
	students: [],
	pending: false,
	err: '',
};

const studentsSlice = createSlice({
	name: 'Students',
	initialState,
	reducers: {
		getStudents: (state) => {
			return {
				...state,
				pending: true,
			};
		},
		getStudentsSuccess: (state, action) => {
			return {
				...state,
				students: action.payload.students.data,
				pending: false,
			};
		},
		getStudentsError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload.err,
			};
		},
		deleteStudent: (state, action) => {
			return {
				...state,
				pending: true,
				deletePending: true,
			};
		},
		deleteStudentSuccess: (state, action) => {
			return {
				...state,
				...action.payload.response,
				pending: false,
				deletePending: false,
			};
		},
		deleteStudentError: (state, action) => {
			return {
				...state,
				err: action.payload.err,
				pending: false,
				deletePending: false,
			};
		},
	},
});

export const {
	getStudents,
	getStudentsSuccess,
	getStudentsError,
	deleteStudent,
	deleteStudentError,
	deleteStudentSuccess,
} = studentsSlice.actions;

export default studentsSlice.reducer;
