import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store/rootReducer';

import { getInternships } from '../../Common/slices/internshipsSlice';
import { getDashboardCompanies } from '../../AdminDPages/DashboardCompanies/slices/dashboardCompaniesSlice';

import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { Table } from 'react-bootstrap';

import infoLogo from '../../../assets/images/Dashboard/dashboard-icons/info.png';

import './style/style.css';
import { useNavigate } from 'react-router-dom';

const PublicInternships = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loginState = useSelector((state: AppState) => state.login);
	const checkCookie: string | undefined = Cookies.get('token');
	const cookieRole: any = checkCookie !== undefined && jwtDecode(checkCookie);
	const isAuthenticated: any =
		checkCookie !== undefined
			? cookieRole.UserType
			: loginState.role
			? loginState.role
			: undefined;
	const internships = useSelector((state: AppState) => state.internships);
	const companies = useSelector(
		(state: AppState) => state.dashboardCompanies
	);
	const fallback = (
		<div className="internship-fallback">
			<p>
				Ne pare rau, in acest moment nu exista niciun stagiu disponibil
				😞
			</p>
		</div>
	);
	const renderInternships = (statusID: number) => {
		if (internships.internships.length > 0) {
			return internships.internships
				.filter((el: any, index: number) => {
					if (statusID === 2) {
						if (el.internshipStatusId === statusID) {
							return el;
						}
					} else if (statusID === 3) {
						if (el.internshipStatusId === statusID) {
							return el;
						}
					}
				})
				.map((el: any, index: number) => {
					if (el.internshipStatusId === 2) {
						return (
							<React.Fragment key={el.internshipId}>
								<tr key={el.internshipId}>
									<td>{index + 1}</td>
									<td>{el.name}</td>
									<td>{el.description}</td>
									<td>
										{
											companies.companies
												.filter((company: any) => {
													if (
														company.companyId ===
														el.companyId
													)
														return company.name;
												})
												.map((company: any) => {
													return company.name;
												})[0]
										}
									</td>
									{isAuthenticated === undefined ? (
										<td>
											<button
												className="btn btn-info text-white"
												onClick={() =>
													navigate('/login')
												}
											>
												Aplica
											</button>
										</td>
									) : isAuthenticated === 'Administrator' ? (
										<td>
											<button className="btn btn-secondary text-white disabled admin-apply-btn">
												Aplica
											</button>
										</td>
									) : (
										isAuthenticated === 'Student' && (
											<td>
												<a
													href={`/dashboard/stagii/${el.internshipId}`}
													className="btn btn-info text-white"
												>
													Aplica
												</a>
											</td>
										)
									)}
								</tr>
							</React.Fragment>
						);
					}

					if (el.internshipStatusId === 3) {
						return (
							<React.Fragment key={el.internshipId}>
								<tr key={el.internshipId}>
									<td>{index + 1}</td>
									<td>{el.name}</td>
									<td>{el.description}</td>
									<td>
										{
											companies.companies
												.filter((company: any) => {
													if (
														company.companyId ===
														el.companyId
													)
														return company.name;
												})
												.map((company: any) => {
													return company.name;
												})[0]
										}
									</td>
								</tr>
							</React.Fragment>
						);
					}
				});
		}
	};

	useEffect(() => {
		dispatch(getInternships());
		dispatch(getDashboardCompanies());
	}, []);

	return (
		<div className="public-internships-container">
			<div className="public-internships-content">
				{internships.internships.length === 0 ? (
					fallback
				) : renderInternships(2).length === 0 &&
				  renderInternships(3).length === 0 ? (
					fallback
				) : (
					<>
						{renderInternships(2).length > 0 && (
							<div className="open-internships-container">
								<div className="internship-status">
									<p className="open-internship-info">
										<img src={infoLogo} alt="info" />
										Deschis pentru candidatura
									</p>
									<Table bordered hover>
										<thead>
											<tr>
												<th>#</th>
												<th>Nume stagiu</th>
												<th>Descriere</th>
												<th>Companie</th>
												<th>Aplica</th>
											</tr>
										</thead>
										<tbody>{renderInternships(2)}</tbody>
									</Table>
								</div>
							</div>
						)}
						{internships.internships.length > 0 &&
							!renderInternships(3).every((el: any) => {
								return el === undefined;
							}) && (
								<div className="closed-internship-container">
									<div className="internship-status">
										<p className="closed-internship-info">
											<img src={infoLogo} alt="info" />
											Nu mai accepta candidati
										</p>
										<Table striped bordered hover>
											<thead>
												<tr>
													<th>#</th>
													<th>Nume stagiu</th>
													<th>Descriere</th>
													<th>Companie</th>
												</tr>
											</thead>
											<tbody>
												{renderInternships(3)}
											</tbody>
										</Table>
									</div>
								</div>
							)}
					</>
				)}
			</div>
		</div>
	);
};

export default PublicInternships;
