import './style/style.css';
import { Form } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/rootReducer';
import {
	getDashboardInternSessionId,
	putDashboardInternSessionId,
} from './slices/dashboardInternSessionSlice';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DateInterface } from '../../Common/types';
import PageLoading from '../../../Components/PageLoading';

import moment from 'moment';

const DashboardInternSession = () => {
	const dispatch = useDispatch();
	const internSession = useSelector(
		(state: AppState) => state.dashboardInternSession
	);
	const titleRef = useRef<HTMLInputElement>(null);
	const [internSessionDate, setInternSessionDate] = useState<DateInterface>(
		() => {
			return {
				startDate: internSession.startDate
					? new Date(internSession.startDate)
					: new Date(),
				endDate: internSession.endDate
					? new Date(internSession.endDate)
					: new Date(),
				edited: false,
			};
		}
	);

	const params = useParams();
	const [showErrors, setShowErrors] = useState({
		show: false,
		edited: false,
		errMsg: '',
	});

	const handleSubmitChanges = () => {
		if (titleRef.current !== null && titleRef.current.value !== '') {
			if (showErrors.edited) {
				dispatch(
					putDashboardInternSessionId({
						startDate: moment(internSessionDate.startDate)
							.toISOString()
							.split('.')[0],
						endDate: moment(internSessionDate.endDate)
							.toISOString()
							.split('.')[0],
						id: params.id,
						title:
							titleRef.current !== null && titleRef.current.value,
					})
				);
			} else {
				setShowErrors({
					show: true,
					edited: false,
					errMsg: 'Va rugam modificati sesiunea de stagiu',
				});
			}
		} else {
			setShowErrors({
				show: true,
				edited: false,
				errMsg: 'Va rugam completati toate campurile',
			});
		}
	};

	useEffect(() => {
		dispatch(getDashboardInternSessionId(params.id));
	}, []);

	const navigate = useNavigate();
	useEffect(() => {
		internSession.putPending === false &&
			navigate('/dashboard/sesiunea-de-stagii');
		internSession.startDate !== '' &&
			setInternSessionDate({
				...internSessionDate,
				startDate: new Date(internSession.startDate),
				endDate: new Date(internSession.endDate),
			});
	}, [internSession]);

	return (
		<div className="d-session-container">
			<div className="d-session-background-img"></div>
			{internSession.pending && <PageLoading />}
			<div
				className="add-new-intern-session-container"
				id="edit-intern-session-container"
			>
				<h2>Modificati sesiunea de stagiu</h2>
				<div className="add-new-intern-session">
					{showErrors.show && (
						<div className="alert alert-danger" role="alert">
							{showErrors.errMsg !== '' && showErrors.errMsg}
						</div>
					)}
					<button
						type="button"
						className="btn-close"
						onClick={() =>
							navigate('/dashboard/sesiunea-de-stagii')
						}
						aria-label="Close"
					></button>
					<Form.Group className="mb-3">
						<Form.Label>Titlul stagiului</Form.Label>
						<Form.Control
							type="text"
							placeholder="Titlul stagiului..."
							defaultValue={internSession.title}
							onChange={() =>
								setShowErrors({
									...showErrors,
									edited: true,
								})
							}
							ref={titleRef}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Start perioada de stagiu</Form.Label>
						<DatePicker
							className="form-control"
							selected={internSessionDate.startDate}
							onChange={(selectedDate: any) => {
								setShowErrors({
									...showErrors,
									edited: true,
								});
								setInternSessionDate((state) => {
									return {
										...state,
										startDate: selectedDate,
									};
								});
							}}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Sfarsit perioada de stagiu</Form.Label>
						<DatePicker
							className="form-control"
							selected={internSessionDate.endDate}
							onChange={(selectedDate: any) => {
								setShowErrors({
									...showErrors,
									edited: true,
								});
								setInternSessionDate((state) => {
									return {
										...state,
										endDate: selectedDate,
									};
								});
							}}
						/>
					</Form.Group>
					<button
						type="button"
						className="btn btn-primary text-light w-100"
						onClick={() => {
							handleSubmitChanges();
						}}
					>
						Salvati sesiunea de stagiu
					</button>
				</div>
			</div>
		</div>
	);
};

export default DashboardInternSession;
