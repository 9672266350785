export const economics_partners = [
	{
		name: 'SC Marcel SRL',
	},
	{
		name: 'SC Casa Auto SRL',
	},
	{
		name: 'SC Casa Auto Valea Lupului SRL',
	},
	{
		name: 'SC Electroputere VFU Pașcani',
	},
	{
		name: 'SC OMCO Romania',
	},
	{
		name: 'SC IGWATTEU Iași',
	},
	{
		name: 'SC Dohu Consulting SRL',
	},
	{
		name: 'SC Aerostar SA Bacau',
	},
	{
		name: 'SC Transcar Tour SRL Moineşti',
	},
	{
		name: 'SC URB SA Bîrlad',
	},
	{
		name: 'SC Electra ELT SRL Iaşi',
	},
	{
		name: 'SC Electra EMS SRL Iași',
	},
	{
		name: 'SC Falcon Trading SRL Iaşi',
	},
];
export const students_enrolled = [
	{ name: 'SC BorgWarner Iaşi', students: 10 },
	{ name: 'SC Casa Auto SRL + SC Casa Auto Valea Lupului SRL', students: 54 },
	{ name: 'SC Falcon Trading SRL Iaşi', students: 8 },
	{ name: 'SC Dohu Consulting SRL', students: 26 },
	{ name: 'SC URB SA Bîrlad', students: 1 },
	{ name: 'SC Aerostar SA Bacău', students: 2 },
	{ name: 'SC Transcar Tour SRL Moineşti', students: 1 },
	{ name: 'SC Electroputere VFU Paşcani', students: 4 },
	{ name: 'SC Sonovision Ortec SRL Iaşi', students: 13 },
	{ name: 'SC OMCO Romania Iaşi', students: 12 },
	{ name: 'SC IGWATTEU Iaşi', students: 8 },
	{ name: 'SC Marcel SRL', students: 1 },
];
