import { createSlice } from '@reduxjs/toolkit';
import { DashboardAdmin } from '../types';

const initialState: DashboardAdmin = {
	firstName: '',
	lastName: '',
	email: '',
	pending: false,
	err: '',
	putPending: true,
};

const dashboardAdminSlice = createSlice({
	name: 'DashboardAdmin',
	initialState,
	reducers: {
		getAdmin: (state, action) => {
			return {
				...state,
				pending: true,
				err: '',
			};
		},
		getAdminSuccess: (state, action) => {
			return {
				...state,
				...action.payload.response.data,
				pending: false,
				err: '',
			};
		},
		getAdminError: (state, action) => {
			return {
				...state,
				err: action.payload.err.data,
				pending: false,
			};
		},
		putAdmin: (state, action) => {
			return {
				...state,
				err: '',
				pending: true,
				putPending: true,
			};
		},
		putAdminSuccess: (state, action) => {
			return {
				...state,
				err: '',
				pending: false,
				putPending: false,
			};
		},
		putAdminError: (state, action) => {
			return {
				...state,
				err: action.payload.err,
				pending: false,
				putPending: false,
			};
		},
	},
});

export const {
	getAdmin,
	getAdminSuccess,
	getAdminError,
	putAdmin,
	putAdminSuccess,
	putAdminError,
} = dashboardAdminSlice.actions;

export default dashboardAdminSlice.reducer;
