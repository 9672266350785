import { Route } from 'react-router-dom';
import PrivateComponent from '../../Components/PrivateComponent';
import StudentFiles from '../../Pages/StudentDPages/StudentFiles';
import { Profile } from '../../Pages/CommonDPages';

const studentRoutes = [
	<Route
		path="/dashboard/fisierele-mele"
		element={
			<PrivateComponent>
				<StudentFiles />
			</PrivateComponent>
		}
	></Route>,
];
export default studentRoutes;
