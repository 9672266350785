import { createSlice } from '@reduxjs/toolkit';
import { InternSession } from '../types';

const initialState: InternSession = {
	sessions: [],
	pending: false,
	err: '',
};

const internSessionSlice = createSlice({
	name: 'internship session',
	initialState,
	reducers: {
		getInternSessions(state) {
			return {
				...state,
				deletePending: true,
				pending: true,
			};
		},

		getInternSessionsSuccess(state, action) {
			return {
				...state,
				pending: false,
				sessions: action.payload.data,
			};
		},

		getInternSessionsError(state, action) {
			return {
				...state,
				pending: false,
				err: action.payload.err,
			};
		},

		postInternSession(state, action) {
			return {
				...state,
				pending: true,
				err: '',
				postPending: true,
			};
		},

		postInternSessionSuccess(state, action) {
			return {
				...state,
				pending: false,
				err: '',
				postPending: false,
			};
		},

		postInternSessionError(state, action) {
			return {
				...state,
				pending: false,
				postPending: false,
				err: action.payload.err,
			};
		},

		deleteInternSession(state, action) {
			return {
				...state,
				pending: true,
				err: '',
				deletePending: true,
			};
		},

		deleteInternSessionSuccess(state, action) {
			return {
				...state,
				pending: false,
				err: '',
				deletePending: false,
			};
		},
		deleteInternSessionError(state, action) {
			return {
				...state,
				pending: false,
				err: action.payload.err,
				deletePending: false,
			};
		},
	},
});

export const {
	getInternSessions,
	getInternSessionsSuccess,
	getInternSessionsError,
	postInternSession,
	postInternSessionSuccess,
	postInternSessionError,
	deleteInternSession,
	deleteInternSessionError,
	deleteInternSessionSuccess,
} = internSessionSlice.actions;

export default internSessionSlice.reducer;
