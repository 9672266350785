import { call, put, takeLatest, all } from 'redux-saga/effects';
import { API_URLS } from '../../../helpers/http';
import {
	getInternships,
	getInternshipsFailure,
	getInternshipsSuccess,
	postInternship,
	postInternshipSuccess,
	postInternshipError,
	deleteInternship,
	deleteInternshipSuccess,
	deleteInternshipFailure,
} from '../slices/internshipsSlice';
import axios from 'axios';

import { DeleteInternship, Internships, PostInterface } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';

function* getInternshipsSaga() {
	try {
		const response: Internships = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.ADMIND_INTERNSHIP}`
		);

		yield put(getInternshipsSuccess({ internships: response }));
	} catch (e: any) {
		yield put(getInternshipsFailure({ error: e }));
	}
}

function* postInternshipSaga(action: PayloadAction<PostInterface>) {
	try {
		const {
			name,
			description,
			startDate,
			endDate,
			companyId,
			internshipSessionId,
			internshipStatusId,
		} = action.payload;
		const response: PostInterface = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.ADMIND_INTERNSHIP}`,
			{
				name,
				description,
				startDate,
				endDate,
				companyId,
				internshipStatusId,
				internshipSessionId,
			}
		);
		yield put(postInternshipSuccess({ ...response }));
	} catch (err: any) {
		yield put(postInternshipError({ error: err.response.statusText }));
	}
}

function* deleteInternshipSaga(action: PayloadAction<DeleteInternship>) {
	const id = action.payload.id;
	try {
		const response: Internships = yield call(
			axios.delete,
			`${API_URLS.BASE_URL}${API_URLS.ADMIND_INTERNSHIP}/${id}`
		);

		yield put(deleteInternshipSuccess({ internship: response }));
	} catch (err: any) {
		yield put(deleteInternshipFailure({ error: err }));
	}
}

function* internshipsSaga() {
	yield all([
		takeLatest(getInternships, getInternshipsSaga),
		takeLatest(deleteInternship, deleteInternshipSaga),
		takeLatest(postInternship, postInternshipSaga),
	]);
}

export default internshipsSaga;
