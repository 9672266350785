import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_URLS } from '../../../../helpers/http';
import {
	getDashboardInternSessionId,
	getDashboardInternSessionIdError,
	getDashboardInternSessionIdSuccess,
	putDashboardInternSessionId,
	putDashboardInternSessionsIdError,
	putDashboardInternSessionsIdSuccess,
} from '../slices/dashboardInternSessionSlice';
import { DashboardIdSession, PutDashboardIdSession } from '../types';

function* getDashboardInternSessionSaga(action: PayloadAction) {
	try {
		const id = action.payload;
		const response: DashboardIdSession = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIPSESSION}/${id}`
		);
		yield put(getDashboardInternSessionIdSuccess({ response }));
	} catch (err: any) {
		yield put(getDashboardInternSessionIdError({ err: err.response }));
	}
}

function* putDashboardInternSessionSaga(
	action: PayloadAction<PutDashboardIdSession>
) {
	try {
		const { id, title, startDate, endDate } = action.payload;
		const response: PutDashboardIdSession = yield call(
			axios.put,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIPSESSION}/${id}`,
			{
				title,
				startDate,
				endDate,
			}
		);
		yield put(putDashboardInternSessionsIdSuccess({ response }));
	} catch (err: any) {
		yield put(putDashboardInternSessionsIdError({ err: err.response }));
	}
}

function* dashboardInternSessionSaga() {
	yield all([
		takeLatest(getDashboardInternSessionId, getDashboardInternSessionSaga),
		takeLatest(putDashboardInternSessionId, putDashboardInternSessionSaga),
	]);
}

export default dashboardInternSessionSaga;
