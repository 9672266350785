import { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getAdmin } from './slices/dashboardAdminSlice';
import { AppState } from '../../../store/rootReducer';
import { putAdmin } from './slices/dashboardAdminSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './style/style.css';
import { checkPassword } from '../../CommonDPages/Profile/slices/profileSlice';

const DashboardAdmin = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [showPassword, setShowPassword] = useState('password');
	const firstName = useRef<HTMLInputElement>(null);
	const lastName = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const confirmPasswordRef = useRef<HTMLInputElement>(null);
	const checkPasswordInfo = useSelector((state: AppState) => state.profile);
	const email = useRef<HTMLInputElement>(null);
	const show = (
		<FontAwesomeIcon icon={faEyeSlash} className="eye eye-closed" />
	);
	const hide = <FontAwesomeIcon icon={faEye} className="eye eye-open" />;

	const admin = useSelector((state: AppState) => state.dashboardAdmin);
	const [editAdminError, setEditAdminError] = useState({
		show: false,
		errMsg: '',
		edited: 'no',
		confirmPassword: 'no',
	});

	const changeInputType = () => {
		showPassword === 'password'
			? setShowPassword('text')
			: setShowPassword('password');
	};

	const handleEditAdmin = () => {
		if (
			(firstName.current !== null &&
				firstName.current.value !== '' &&
				lastName.current !== null &&
				lastName.current.value !== '' &&
				editAdminError.edited === 'yes') ||
			(passwordRef.current !== null &&
				passwordRef.current.value !== '' &&
				confirmPasswordRef.current !== null &&
				confirmPasswordRef.current.value !== '')
		) {
			if (
				passwordRef.current !== null &&
				passwordRef.current.value !== '' &&
				confirmPasswordRef.current !== null &&
				confirmPasswordRef.current.value !== ''
			) {
				if (
					passwordRef.current.value !==
					confirmPasswordRef.current.value
				) {
					setEditAdminError({
						...editAdminError,
						show: true,
						errMsg: 'Cele doua parole nu se potrivesc',
					});
				} else if (
					passwordRef.current.value ===
					confirmPasswordRef.current.value
				) {
					dispatch(
						checkPassword(
							passwordRef.current !== null &&
								passwordRef.current.value
						)
					);
				}
				if (editAdminError.confirmPassword === 'yes') {
					dispatch(
						putAdmin({
							id: params.id,
							firstName: firstName!.current!.value,
							lastName: lastName!.current!.value,
							password: passwordRef.current.value,
						})
					);
				}
			} else {
				dispatch(
					putAdmin({
						id: params.id,
						firstName: firstName!.current!.value,
						lastName: lastName!.current!.value,
					})
				);
			}
		} else {
			if (editAdminError.edited === 'no') {
				setEditAdminError({
					...editAdminError,
					show: true,
					errMsg: 'Va rugam modificati administratorul',
				});
			} else
				setEditAdminError({
					...editAdminError,
					show: false,
				});
		}
	};

	useEffect(() => {
		dispatch(getAdmin(params.id));
	}, []);

	useEffect(() => {
		if (admin.putPending === false && admin.err === '') {
			navigate('/dashboard/admin');
		}
		if (admin.err !== '') {
			setEditAdminError({
				...editAdminError,
				show: true,
				errMsg: admin.err,
			});
		}
	}, [admin.putPending]);

	useEffect(() => {
		if (checkPasswordInfo.err !== '') {
			setEditAdminError({
				...editAdminError,
				show: true,
				errMsg: checkPasswordInfo.err,
			});
		}
		if (checkPasswordInfo.err === '') {
			setEditAdminError({
				...editAdminError,
				errMsg: '',
				show: false,
			});
		}

		if (checkPasswordInfo.infoText === 'OK') {
			setEditAdminError({
				...editAdminError,
				errMsg: '',
				show: false,
				confirmPassword: 'yes',
			});
		}
	}, [checkPasswordInfo]);

	useEffect(() => {
		editAdminError.confirmPassword === 'yes' && handleEditAdmin();
	}, [editAdminError.confirmPassword]);

	return (
		<div className="admins-container">
			<div className="admins-img-background"></div>
			<div className="post-new-admin">
				<button
					type="button"
					className="btn-close"
					aria-label="Close"
					onClick={() => {
						navigate('/dashboard/admin');
					}}
				></button>
				<h3>Editeaza detaliile despre administrator</h3>
				<div className="post-new-admin-content">
					<Form.Group className="mb-3">
						<Form.Label>Nume</Form.Label>
						<Form.Control
							type="text"
							placeholder="Nume..."
							defaultValue={admin.lastName}
							ref={lastName}
							onChange={() =>
								setEditAdminError({
									...editAdminError,
									edited: 'yes',
								})
							}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Prenume</Form.Label>
						<Form.Control
							type="text"
							placeholder="Prenume..."
							defaultValue={admin.firstName}
							ref={firstName}
							onChange={() =>
								setEditAdminError({
									...editAdminError,
									edited: 'yes',
								})
							}
						/>
					</Form.Group>

					<Form.Label htmlFor="Schimba parola">
						Schimba parola 🔓
					</Form.Label>
					<Form.Control
						type={showPassword}
						placeholder="Noua parola..."
						ref={passwordRef}
						className="change-pass"
					/>
					<span onClick={changeInputType} className="show-hide-pass">
						{showPassword === 'password' ? show : hide}
					</span>
					<Form.Label htmlFor="Schimba parola">
						Confirma parola 🔓
					</Form.Label>
					<Form.Control
						type={showPassword}
						placeholder="Confirma parola..."
						ref={confirmPasswordRef}
					/>
					{/* <Form.Group className="mb-3">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="text"
							placeholder="Email..."
							defaultValue={admin.email}
							ref={email}
							onChange={() =>
								setEditAdminError({
									...editAdminError,
									edited: 'yes',
								})
							}
						/>
					</Form.Group> */}

					<button
						className="btn btn-primary mt-4"
						onClick={() => handleEditAdmin()}
					>
						Salveaza modificarile
					</button>
				</div>
				{editAdminError.show && (
					<div className="alert alert-danger mt-3" role="alert">
						{editAdminError.errMsg !== ''
							? editAdminError.errMsg
							: // : checkPasswordInfo.err !== ''
							  // ? checkPasswordInfo.err
							  ''}
					</div>
				)}
			</div>
		</div>
	);
};

export default DashboardAdmin;
