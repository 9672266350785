import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { AppState } from '../store/rootReducer';

import jwtDecode from 'jwt-decode';

const RenderComponentByRole = (student: any, admin: any) => {
	const userProfileDetails = useSelector((state: AppState) => state.login);
	let cookie: string | undefined = Cookies.get('token');
	if (cookie) {
		cookie = jwtDecode<any>(cookie).UserType;
		switch (cookie) {
			case 'Administrator':
				return admin;
			case 'Student':
				return student;
			default:
				break;
		}
	}
	if (userProfileDetails.role !== '') {
		switch (userProfileDetails.role) {
			case 'Administrator':
				return admin;
			case 'Student':
				return student;
			default:
				break;
		}
	}
};

export default RenderComponentByRole;
