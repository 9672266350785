import { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';

import './style/style.css';

const StudentForm = () => {
	const [postStudentApplicationError, setPostStudentApplicationError] =
		useState({
			show: false,
			errMsg: '',
		});

	const firstNameRef = useRef<HTMLInputElement>(null);
	const lastNameRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);

	const handleSubmitStudentApplication = () => {
		const phoneNumber = (
			document.getElementById('student-phone-number') as HTMLInputElement
		).value;
		if (
			firstNameRef.current !== null &&
			firstNameRef.current.value !== '' &&
			lastNameRef.current !== null &&
			lastNameRef.current.value !== '' &&
			emailRef.current !== null &&
			emailRef.current.value !== '' &&
			phoneNumber.length === 15
		) {
			const regex =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (regex.test(emailRef.current.value) === false) {
				setPostStudentApplicationError({
					show: true,
					errMsg: 'Adresa de email nu este valida',
				});
			} else {
				setPostStudentApplicationError({
					show: false,
					errMsg: '',
				});
			}
		} else {
			if (phoneNumber.length > 3 && phoneNumber.length < 15) {
				setPostStudentApplicationError({
					show: true,
					errMsg: 'Numarul de telefon nu este valid',
				});
			} else
				setPostStudentApplicationError({
					show: true,
					errMsg: 'Va rugam completati toate campurile',
				});
		}
	};

	return (
		<div className="student-form">
			<div className="student-form-content">
				<div className="student-form-content-inputs">
					<h3 className="lets-start">Să începem!</h3>
					<Form.Group className="mb-3">
						<Form.Label>Nume</Form.Label>
						<Form.Control
							type="text"
							name="student-first-name"
							placeholder="Nume..."
							ref={firstNameRef}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Prenume</Form.Label>
						<Form.Control
							type="text"
							name="student-last-name"
							placeholder="Prenume..."
							ref={lastNameRef}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="text"
							name="student-email"
							placeholder="Email..."
							ref={emailRef}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Telefon</Form.Label>
						<PhoneInput
							international
							country="RO"
							defaultCountry="RO"
							maxLength={15}
							onChange={() => console.log('')}
							id="student-phone-number"
						/>
					</Form.Group>
					<button
						className="btn btn-primary mt-3 mb-3"
						onClick={handleSubmitStudentApplication}
					>
						Finalizare
					</button>
					{postStudentApplicationError.show && (
						<div className="alert alert-danger" role="alert">
							{postStudentApplicationError.errMsg !== '' &&
								postStudentApplicationError.errMsg}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default StudentForm;
