import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../store/rootReducer';
import HomePage from '../Pages/PublicPages/HomePage';
import Companies from '../Pages/PublicPages/Companies';
import Articles from '../Pages/PublicPages/Articles';
import Company from '../Pages/PublicPages/Company';
import LoginPage from '../Pages/LoginPage';
import PageNotFound from '../Pages/404Page';
import PrivateComponent, { Jwt } from '../Components/PrivateComponent';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { Internships } from '../Pages/AdminDPages';
import { Home, Profile } from '../Pages/CommonDPages';
import {
	StudentDashboardInternship,
	StudentInternships,
} from '../Pages/StudentDPages';
import RenderComponentByRole from '../helpers/RenderComponentByRole';
import DashboardInternship from '../Pages/AdminDPages/DashboardInternship';
import SpecificPressRelease from '../Components/SpecificPressRelease';
import TermsAndConditions from '../Pages/PublicPages/TermsAndConditions';
import PressReleasePage from '../Pages/PublicPages/PressReleasePage';
import PrivacyPolicy from '../Pages/PublicPages/PrivacyPolicy';
import TargetGroup from '../Pages/PublicPages/TargetGroupPage';
import ProjectPage from '../Pages/PublicPages/ProjectPage';
import UsefulDocuments from '../Pages/PublicPages/UsefulDocumentsPage';
import ProjectContext from '../Pages/PublicPages/ProjectContextPage';
import ProjectGeneralObjective from '../Pages/PublicPages/ProjectGeneralObjectivePage';
import ProjectSpecificObjectives from '../Pages/PublicPages/ProjectSpecificObjectivesPage';
import ProjectJustification from '../Pages/PublicPages/ProjectJustificationPage';
import ProjectResults from '../Pages/PublicPages/ProjectResultsPage';
import UsefulDocumentsSelectionMethodology from '../Pages/PublicPages/UsefulDocumentsSelectionMethodologyPage';
import UsefulDocumentsWorkingMethodology from '../Pages/PublicPages/UsefulDocumentsWokingMethodologyPage';
import ContactPage from '../Pages/PublicPages/ContactPage';
import PublicInternships from '../Pages/PublicPages/PublicInternships';
import studentsRoutes from './studentsRoutes';
import { pressReleasesData } from '../helpers/constants';
import adminRoutes from './adminRoutes';
import React from 'react';
import StudentForm from '../Pages/PublicPages/StudentForm';
import CompanyForm from '../Pages/PublicPages/CompanyForm';

const AppRoute = () => {
	const loginState = useSelector((state: AppState) => state.login);
	const checkCookie: string | undefined = Cookies.get('token');
	const cookieRole: any = checkCookie !== undefined && jwtDecode(checkCookie);
	const isAuthenticated: any =
		checkCookie !== undefined
			? cookieRole.UserType
			: loginState.role
			? loginState.role
			: undefined;
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/login" element={<LoginPage />} />
				{['/', '/home'].map((path, index) => (
					<Route path={path} element={<HomePage />} key={index} />
				))}
				<Route
					path="/anunturi"
					element={<PressReleasePage allPressReleases={pressReleasesData} />}
				/>
				<Route path="/formular-student" element={<StudentForm />} />
				<Route path="/formular-angajator" element={<CompanyForm />} />
				<Route path="/stagii" element={<PublicInternships />} />
				{pressReleasesData.map((pressRelease: any, index: number) => {
					return (
						<Route
							path={pressRelease.url}
							key={index}
							element={<SpecificPressRelease pressRelease={pressRelease} />}
						/>
					);
				})}
				<Route
					path="/politica-de-confidentialitate"
					element={<PrivacyPolicy />}
				/>
				<Route path="/grup-tinta" element={<TargetGroup />} />
				<Route path="/context-proiect" element={<ProjectContext />} />
				<Route
					path="/obiectiv-general-proiect"
					element={<ProjectGeneralObjective />}
				/>
				<Route
					path="/obiective-specifice-proiect"
					element={<ProjectSpecificObjectives />}
				/>
				<Route path="/justificare-proiect" element={<ProjectJustification />} />
				<Route path="/rezultate-proiect" element={<ProjectResults />} />
				<Route path="/proiect" element={<ProjectPage />} />
				<Route path="/documente-utile" element={<UsefulDocuments />} />
				<Route
					path="/documente-utile-metodologie-selectie"
					element={<UsefulDocumentsSelectionMethodology />}
				/>
				<Route
					path="/documente-utile-metodologie-derulare"
					element={<UsefulDocumentsWorkingMethodology />}
				/>
				<Route path="/parteneri" element={<Companies />} />
				<Route path="/articles" element={<Articles />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/company/:id" element={<Company />} />

				<Route
					path="/dashboard"
					element={
						<PrivateComponent>
							<Home />
						</PrivateComponent>
					}
				/>
				<Route
					path="/dashboard/profil"
					element={
						<PrivateComponent>
							<Profile />
						</PrivateComponent>
					}
				/>

				<Route
					path="/dashboard/stagii"
					element={
						<PrivateComponent>
							{RenderComponentByRole(<StudentInternships />, <Internships />)}
						</PrivateComponent>
					}
				/>
				<Route
					path="/dashboard/stagii/:id"
					element={
						<PrivateComponent>
							{RenderComponentByRole(
								<StudentDashboardInternship />,
								<DashboardInternship />
							)}
						</PrivateComponent>
					}
				/>
				<Route
					path="/dashboard/stagii"
					element={
						<PrivateComponent>
							{RenderComponentByRole(<StudentInternships />, <Internships />)}
						</PrivateComponent>
					}
				/>
				<Route
					path="/dashboard/stagii/:id"
					element={
						<PrivateComponent>
							{RenderComponentByRole(
								<StudentDashboardInternship />,
								<DashboardInternship />
							)}
						</PrivateComponent>
					}
				/>
				{isAuthenticated === 'Administrator' &&
					adminRoutes.map((route: any) => (
						<React.Fragment key={route}>{route}</React.Fragment>
					))}
				{isAuthenticated === 'Student' &&
					studentsRoutes.map((route: any) => (
						<React.Fragment key={route}>{route}</React.Fragment>
					))}

				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoute;
