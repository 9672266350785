import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import companies from '../../../assets/images/Dashboard/DashboardImages/dashboard-companies.jpg';
import PageLoading from '../../../Components/PageLoading';
import { AppState } from '../../../store/rootReducer';
import {
	getDashboardCompanies,
	postDashboardCompany,
	deleteDashboardCompany,
} from './slices/dashboardCompaniesSlice';
import useImagePreloader from '../../../helpers/useImagePreloader';
import { Form } from 'react-bootstrap';
import addCompany from '../../../assets/images/Dashboard/dashboard-icons/company.png';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import './style/style.css';

const DashboardCompanies = () => {
	const dashboardCompanies = useSelector(
		(state: AppState) => state.dashboardCompanies
	);
	const preloadSrcList: string[] = [companies];
	const { imagesPreloaded } = useImagePreloader(preloadSrcList);
	const [showPopup, setShowPopup] = useState({ show: false, companyId: '' });
	const dispatch = useDispatch();
	const [phoneValue, setPhoneValue] = useState<any>('');
	const [postNewCompany, setPostNewCompany] = useState(false);
	const [postNewCompanyError, setPostNewCompanyError] = useState({
		show: false,
		errMsg: '',
	});

	const addressRef = useRef<HTMLInputElement>(null);
	const companyNameRef = useRef<HTMLInputElement>(null);
	const websiteRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);

	const handlePostCompany = () => {
		const phoneNumber = (
			document.getElementById('phone-number') as HTMLInputElement
		).value;
		if (
			addressRef.current !== null &&
			addressRef.current.value !== '' &&
			phoneNumber.length === 15 &&
			emailRef.current !== null &&
			emailRef.current.value !== '' &&
			companyNameRef.current !== null &&
			companyNameRef.current.value !== '' &&
			websiteRef.current !== null &&
			websiteRef.current.value !== ''
		) {
			const regex =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (regex.test(emailRef.current.value) === false) {
				setPostNewCompanyError({
					show: true,
					errMsg: 'Adresa de email nu este valida',
				});
			} else {
				setPostNewCompanyError({
					show: false,
					errMsg: '',
				});
				dispatch(
					postDashboardCompany({
						address: addressRef.current.value,
						phone: phoneNumber.replace(/ /g, ''),
						email: emailRef.current.value,
						name: companyNameRef.current.value,
						website: websiteRef.current.value,
						isVisible: true,
					})
				);
			}
		} else {
			if (phoneNumber.length < 15) {
				setPostNewCompanyError({
					show: true,
					errMsg: 'Numarul de telefon nu este valid',
				});
			} else
				setPostNewCompanyError({
					show: true,
					errMsg: 'Va rugam completati toate campurile',
				});
		}
	};

	const handleDeleteCompany = () => {
		dispatch(deleteDashboardCompany(showPopup.companyId));
		setShowPopup({
			show: false,
			companyId: '',
		});
	};

	useEffect(() => {
		dispatch(getDashboardCompanies());
	}, []);

	useEffect(() => {
		if (
			dashboardCompanies.postPending === false ||
			dashboardCompanies.err === ''
		) {
			setPostNewCompany(false);
			dispatch(getDashboardCompanies());
		}
		if (dashboardCompanies.err !== '') {
			setPostNewCompanyError({
				show: true,
				errMsg: dashboardCompanies.err,
			});
		}
	}, [dashboardCompanies.postPending]);

	useEffect(() => {
		dashboardCompanies.deletePending === false &&
			dispatch(getDashboardCompanies());
	}, [dashboardCompanies.deletePending]);

	return (
		<div className="dashboard-companies">
			{showPopup.show && (
				<div className="confirmation-popup">
					<div className="confirmation-container">
						<h2>Sunteti sigur ca vreti sa stergeti compania ?</h2>
						<div className="confirm-buttons">
							<button
								className="btn btn-primary mr-5"
								onClick={() => handleDeleteCompany()}
							>
								Da
							</button>
							<button
								className="btn btn-danger"
								onClick={() =>
									setShowPopup({
										show: false,
										companyId: '',
									})
								}
							>
								Nu
							</button>
						</div>
					</div>
				</div>
			)}

			<div className="dashboard-companies-header">
				<h1>Companii</h1>
			</div>
			<img
				src={companies}
				alt="dashboard-companies"
				id="dashboard-company-img"
			/>

			{dashboardCompanies.pending ? (
				<PageLoading />
			) : !imagesPreloaded ? (
				<PageLoading />
			) : null}
			{postNewCompany && (
				<div className="post-new-company">
					<h3>Introduceti o noua companie</h3>
					<div className="post-new-company-content">
						<button
							type="button"
							className="btn-close btn-close close-post-company"
							aria-label="Close"
							onClick={() => setPostNewCompany(false)}
						></button>
						<Form.Group className="mb-3">
							<Form.Label>Adresa</Form.Label>
							<Form.Control
								type="text"
								placeholder="Adresa..."
								ref={addressRef}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Numar de telefon</Form.Label>
							<PhoneInput
								international
								country="RO"
								defaultCountry="RO"
								value={phoneValue}
								onChange={(phone) => setPhoneValue(phone)}
								maxLength={15}
								id="phone-number"
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="text"
								placeholder="Email-ul companiei..."
								ref={emailRef}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Nume Companie</Form.Label>
							<Form.Control
								type="text"
								placeholder="Numele companiei..."
								ref={companyNameRef}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Website</Form.Label>
							<Form.Control
								type="text"
								placeholder="Website-ul companiei..."
								ref={websiteRef}
							/>
						</Form.Group>
						<button
							className="btn btn-primary"
							onClick={handlePostCompany}
						>
							Adauga compania
						</button>
					</div>
					{postNewCompanyError.show && (
						<div className="alert alert-danger" role="alert">
							{postNewCompanyError.errMsg !== '' &&
								postNewCompanyError.errMsg}
						</div>
					)}
				</div>
			)}
			{!postNewCompany && (
				<>
					<div
						className="add-company"
						onClick={() => setPostNewCompany(true)}
					>
						<h6>Adauga o noua companie </h6>
						<img src={addCompany} alt="add-company" />
					</div>
					<div className="card-deck">
						{dashboardCompanies.companies.map((c: any) => (
							<div className="card" key={c.companyId}>
								<a href={`/dashboard/companii/${c.companyId}`}>
									<div className="card-body mt-1">
										<h2 className="card-title">{c.name}</h2>
										<p className="card-details">
											Adresa: {c.address}
										</p>
										<p className="card-details">
											E-mail: {c.email}
										</p>
										<p className="card-details">
											Telefon: {c.phone}
										</p>
										<p className="card-details">
											Website: {c.website}
										</p>
									</div>
								</a>
								<button
									className="btn btn-danger delete-company"
									onClick={() => {
										setShowPopup({
											show: true,
											companyId: c.companyId,
										});
									}}
								>
									🗑️ Sterge compania
								</button>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export default DashboardCompanies;
