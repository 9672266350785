import { Row, Col } from 'react-bootstrap';
import '../../Common/styles/genericPageStyles/style.css';
import grupTinta from '../../../assets/images/generic-page-images/grup-tinta.jpg';

const TargetGroup = () => {
	return (
		<div className="generic-page-container ">
			<Row className="generic-page-background-image">
				<img
					src={grupTinta}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header">
					<h2>Grup tinta</h2>
				</div>
			</Row>
			<Row className="generic-page-context-container">
				<Col className="col-10 offset-1  generic-page-context-content">
					<br />
					<p>
						Grupul tinta in cadrul proiectului „TechJobs - Stagii de
						practicǎ pentru studenții Universitǎții Tehnice "Gh
						Asachi" din Iași ȋntr-un mediu de lucru real”cod MySmis
						133398 - nr. contract 10574/23.09.2020 este format din
						321 studenti care urmeaza cursurile Universitatii
						Tehnice „Gh Asachi” din Iasi, <br />
						<u>
							Facultatea Constructii de Masini si Management
							Industrial
						</u>{' '}
						cu specializările:
						<ul className="mt-2">
							<li>
								Tehnologia Construcţiilor de Maşini, Sisteme de
								Producţie Digitale
							</li>
							<li>Ingineria Sudării,</li>
							<li>Mecanică Fină şi Nanotehnologii,</li>
							<li>Maşini şi Sisteme Hidraulice şi Pneumatice,</li>
							<li>Inginerie Economică în Domeniul Mecanic</li>
						</ul>
					</p>
					<p>
						<u>
							Facultatea de Mecanică, Facultatea de Inginerie
							Electrică, Energetică și Informatică aplicată,
						</u>{' '}
						cu specializările:
						<ul className="mt-2">
							<li>Electronică aplicată,</li>
							<li>Tehnologii și sisteme de telecomunicații,</li>
							<li>
								Microelectronică, optoelectronică și
								nanotehnologie,
							</li>
							<li>
								Sisteme avansate de electornică aplicată,
								Radiocomunicații digitale, Tehnici modern de
								prelucrare a semnalelor,
							</li>
							<li>
								Tehnologia informației pentru telecomunicații,
								Rețele de comunicare, Sisteme electronice
								inteligente și informatică industrial,
							</li>
							<li>Automotive electronic control system;</li>
						</ul>
					</p>
					<p>
						<u>Facultatea De Stiinta Si Ingineria Materialelor,</u>{' '}
						cu specializările:
						<ul className="mt-2">
							<li>Știința materialelor, </li>
							<li>Ingineria procesării materialelor, </li>
							<li>Echipamente pentru procese industriale, </li>
							<li>Ingineria securității în industrie,</li>
							<li>
								{' '}
								Materiale avansate și tehnici de analiză
								experimentală,{' '}
							</li>
							<li>
								Tehnici avansate în ingineria procesării
								materialelor,{' '}
							</li>
							<li>
								Sisteme industriale pentru tehnologii Moderne,{' '}
							</li>
							<li>
								Ingineria securității și sănătății în muncă,{' '}
							</li>
						</ul>
					</p>
					<p>
						<u>Facultatea de Mecanică,</u> cu specializările:
						<ul className="mt-2">
							<li>Ingineria Autovehiculelor, </li>
							<li>
								Inginerie Mecanică, Mecatronică şi Robotică{' '}
							</li>
						</ul>
					</p>
					<p>
						<u>
							Facultatea de Inginerie Electrică, Energetică și
							Informatică aplicată,
						</u>{' '}
						cu specializările:
						<ul className="mt-2">
							<li>Inginerie Electrică, </li>
							<li>Inginerie Energetică, </li>
							<li>Inginerie şi Management. </li>
						</ul>
					</p>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default TargetGroup;
