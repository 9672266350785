import axios from 'axios';
import { API_URLS } from '../../../../helpers/http';
import Cookies from 'js-cookie';

import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
	getOwnAttendance,
	getOwnAttendanceSuccess,
	getOwnAttendanceError,
} from '../slices/studentCommonSlice';

function* getOwnAttendanceSaga() {
	const userToken = JSON.parse(Cookies.get('token')!).token;
	try {
		const response: {} = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.OWN_ATTENDANCE}`,
			{
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			}
		);
		yield put(getOwnAttendanceSuccess(response));
	} catch (err: any) {
		yield put(getOwnAttendanceError({ err: err.reponse.data }));
	}
}

function* studentCommonSaga() {
	yield all([takeLatest(getOwnAttendance, getOwnAttendanceSaga)]);
}

export default studentCommonSaga;
