import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_URLS } from '../../../../helpers/http';
import {
	getInternSessions,
	getInternSessionsSuccess,
	getInternSessionsError,
	deleteInternSession,
	deleteInternSessionError,
	deleteInternSessionSuccess,
	postInternSession,
	postInternSessionSuccess,
	postInternSessionError,
} from '../slices/internSessionSlice';
import { InternSession, PostInternSession } from '../types';

function* getInternSessionSaga() {
	try {
		const response: InternSession = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIPSESSION}`
		);
		yield put(getInternSessionsSuccess({ ...response }));
	} catch (err: any) {
		yield put(getInternSessionsError({ err: err.response }));
	}
}

function* postInternSessionSaga(action: PayloadAction<PostInternSession>) {
	const { title, startDate, endDate } = action.payload;
	try {
		const response: PostInternSession = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIPSESSION}`,
			{
				title,
				startDate,
				endDate,
			}
		);

		yield put(postInternSessionSuccess({ response }));
	} catch (err: any) {
		yield put(postInternSessionError({ err: err.reponse }));
	}
}

function* deleteInternSessionSaga(action: PayloadAction) {
	try {
		const id = action.payload;
		const response: InternSession = yield call(
			axios.delete,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIPSESSION}/${id}`
		);
		yield put(deleteInternSessionSuccess({ response }));
	} catch (err: any) {
		yield put(deleteInternSessionError({ err: err.response }));
	}
}

function* internSessionSaga() {
	yield all([
		takeLatest(getInternSessions, getInternSessionSaga),
		takeLatest(postInternSession, postInternSessionSaga),
		takeLatest(deleteInternSession, deleteInternSessionSaga),
	]);
}

export default internSessionSaga;
