import { createSlice } from '@reduxjs/toolkit';
import { ApplyForInternship } from '../types';

const initialState: ApplyForInternship = {
	files: [],
	studentDetails: {
		studentId: '',
	},
	err: '',
};

const studentInternshipSlice = createSlice({
	name: 'Student Internship',
	initialState,
	reducers: {
		applyToInternship: (state, action) => {
			return {
				...state,
				applyPending: true,
				err: '',
			};
		},
		applyToInternshipSuccess: (state, action) => {
			return {
				...state,
				applyPending: false,
				err: '',
			};
		},
		applyToInternshipError: (state, action) => {
			return {
				...state,
				applyPending: false,
				err: action.payload.err,
			};
		},
	},
});

export const {
	applyToInternship,
	applyToInternshipSuccess,
	applyToInternshipError,
} = studentInternshipSlice.actions;

export default studentInternshipSlice.reducer;
