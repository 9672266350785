import { ListGroup, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './style/style.css';
import '../../Common/styles/genericPageStyles/style.css';

import documenteUtile from '../../../assets/images/generic-page-images/documente-utile.jpg';

const UsefulDocumentsWorkingMethodology = () => {
	return (
		<div className="generic-page-container ">
			<Row className="generic-page-background-image">
				<img
					src={documenteUtile}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header">
					<h2 className="useful-header">
						Documente utile - Metodologie derulare stagii de
						practica
					</h2>
				</div>
			</Row>
			<Row className="generic-page-context-container selection-documents-container">
				<Col className="col-10 offset-1 mt-5 generic-page-context-content selection-documents-context">
					<br />
					<ListGroup variant="flush">
						<ListGroup.Item>
							<Link
								to="/static/files/2022/Metodologie_derulare_stagii_de_practica/Metodologie_derulare _stagii_de_practica_revizie_februarie_2022_ID133398.pdf"
								target="_blank"
							>
								📁 Metodologie derulare stagii de
								practica_revizie februarie 2022_ID133398
							</Link>
						</ListGroup.Item>
						<ListGroup.Item>
							<Link
								to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1_CONVENTIE_privind_stagiul_de_practica_al_studentilor_ID133398.pdf"
								target="_blank"
							>
								📁 ANEXA 1_ CONVENȚIE privind stagiul de
								practică al studenților_ID133398
							</Link>
						</ListGroup.Item>
						<ListGroup.Item>
							<Link
								to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1.1_ACORD_participare_stagiu_de_practica_ID133398.pdf"
								target="_blank"
							>
								📁 ANEXA 1.1_ACORD participare stagiu de
								practica_ID133398
							</Link>
						</ListGroup.Item>
						<ListGroup.Item>
							<Link
								to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1.2_ACORD_privind_prelucrarea_datelor_cu_caracter_personal_ID133398.pdf"
								target="_blank"
							>
								📁 ANEXA 1.2_ ACORD privind prelucrarea datelor
								cu caracter personal_ID133398
							</Link>
						</ListGroup.Item>
						<ListGroup.Item>
							<Link
								to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_4_Caiet_de_practica_ID133398.pdf"
								target="_blank"
							>
								📁 ANEXA 4_Caiet de practica_ID133398
							</Link>
						</ListGroup.Item>
						<ListGroup.Item>
							<Link
								to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_8_Adeverinta_efectuare_stagiu_practica_ID133398.pdf"
								target="_blank"
							>
								📁ANEXA 8_Adeverinta efectuare stagiu
								practica_ID133398
							</Link>
						</ListGroup.Item>
					</ListGroup>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default UsefulDocumentsWorkingMethodology;
