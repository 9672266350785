import { Button } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style/style.css';

const UsefulDocuments = () => {
	return (
		<div className="generic-page-container  ">
			<div className="generic-page-content text-justify">
				<Row>
					<Col className="col-10 offset-1 mt-5">
						<h2 className="text-center mt-2">Documente utile</h2>
						<br />
						<p>
							<h4>Metodologie selectie Grup Tinta </h4>
							<ul>
								<li>
									<Link
										to="/static/files/2022/Metodologie_selectie_Grup_Tinta/Metodologie_selectie_Grup_Tinta.pdf"
										target="_blank"
									>
										Metodologie selectie Grup Tinta
									</Link>
								</li>
								<li>
									<Link
										to="/static/files/2022/Metodologie_selectie_Grup_Tinta/Anexe_Metodologie_selectie_Grup_Tinta.pdf"
										target="_blank"
									>
										Anexe Metodologie selectie Grup Tinta
									</Link>
								</li>
							</ul>
						</p>
						<p>
							<h4>Metodologie derulare stagii de practica </h4>
							<ul>
								<li>
									<Link
										to="/static/files/2022/Metodologie_derulare_stagii_de_practica/Metodologie_derulare _stagii_de_practica_revizie_februarie_2022_ID133398.pdf"
										target="_blank"
									>
										Metodologie derulare stagii de
										practica_revizie februarie 2022_ID133398
									</Link>
								</li>
								<li>
									<Link
										to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1_CONVENTIE_privind_stagiul_de_practica_al_studentilor_ID133398.pdf"
										target="_blank"
									>
										ANEXA 1_ CONVENȚIE privind stagiul de
										practică al studenților_ID133398
									</Link>
								</li>
								<li>
									<Link
										to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1.1_ACORD_participare_stagiu_de_practica_ID133398.pdf"
										target="_blank"
									>
										ANEXA 1.1_ACORD participare stagiu de
										practica_ID133398
									</Link>
								</li>
								<li>
									<Link
										to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1.2_ACORD_privind_prelucrarea_datelor_cu_caracter_personal_ID133398.pdf"
										target="_blank"
									>
										ANEXA 1.2_ ACORD privind prelucrarea
										datelor cu caracter personal_ID133398
									</Link>
								</li>
								<li>
									<Link
										to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_4_Caiet_de_practica_ID133398.pdf"
										target="_blank"
									>
										ANEXA 4_Caiet de practica_ID133398
									</Link>
								</li>
								<li>
									<Link
										to="/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_8_Adeverinta_efectuare_stagiu_practica_ID133398.pdf"
										target="_blank"
									>
										ANEXA 8_Adeverinta efectuare stagiu
										practica_ID133398
									</Link>
								</li>
							</ul>
						</p>
						<br />
						<br />
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default UsefulDocuments;
