import { all, fork } from 'redux-saga/effects';
import adminsSaga from '../Pages/AdminDPages/Admin/sagas/adminsSaga';
import dashboardAdminSaga from '../Pages/AdminDPages/DashboardAdmin/sagas/dashboardAdminSaga';
import dashboardCompaniesSaga from '../Pages/AdminDPages/DashboardCompanies/sagas/dashboardCompaniesSaga';
import dashboardCompanySaga from '../Pages/AdminDPages/DashboardCompany/sagas/dashboardCompanySaga';
import dashboardInternSessionSaga from '../Pages/AdminDPages/DashboardInternSession/sagas/dashboardInternSessionSaga';
import internshipSaga from '../Pages/Common/sagas/internshipSaga';

import internshipsSaga from '../Pages/Common/sagas/internshipsSaga';
import internSessionSaga from '../Pages/AdminDPages/InternshipSession/sagas/internSessionSaga';
import profileSaga from '../Pages/CommonDPages/Profile/sagas/profileSaga';
import sidebarSaga from '../Pages/Common/sagas/sidebarSaga';
import studentsSaga from '../Pages/AdminDPages/Students/sagas/studentsSaga';
import { loginSaga } from '../Pages/LoginPage/sagas/loginSaga';
import studentInternshipSaga from '../Pages/StudentDPages/DashboardInternship/sagas/studentInternshipSaga';
import studentCommonSaga from '../Pages/StudentDPages/Common/sagas/studentCommonSaga';

export function* rootSaga() {
	yield all([
		fork(loginSaga),
		fork(profileSaga),
		fork(sidebarSaga),
		fork(internshipsSaga),
		fork(internshipSaga),
		fork(dashboardCompaniesSaga),
		fork(dashboardCompanySaga),
		fork(internSessionSaga),
		fork(dashboardInternSessionSaga),
		fork(adminsSaga),
		fork(dashboardAdminSaga),
		fork(studentsSaga),
		fork(studentInternshipSaga),
		fork(studentCommonSaga),
	]);
}
