import { ListGroup, ListGroupItem, Badge } from 'react-bootstrap';
import { economics_partners, students_enrolled } from '../../../data/articles';
import './style/style.css';

const Articles = () => {
	return (
		<div className="partners-container">
			<div className="text-center articles d-flex flex-column justify-content-center align-items-center">
				<h1>Articole</h1>
				<div className="partners-list-container">
					<h2>
						Lista partenerilor economici cu care avem semnate
						acorduri de colaborare
					</h2>
					<p>
						Adaugat de <b>admin</b>
					</p>
				</div>
				<p className="partners-list-p">
					Lista partenerilor economici cu care avem semnate acorduri
					de colaborare
				</p>
				<div
					className="partners-list d-flex text-start
				flex-column"
				>
					<ListGroup as="ol" numbered className="partner-list-header">
						<p> # Nume agent economic</p>
						{economics_partners.map((partner, key) => {
							return (
								<ListGroupItem as="li" key={key}>
									{partner.name}
								</ListGroupItem>
							);
						})}
					</ListGroup>
				</div>
				<p className="partners-list-p students-list-p">
					Repartizarea studentilor la practică
				</p>
				<div
					className="partners-list d-flex text-start
				flex-column"
				>
					<ListGroup
						as="ol"
						numbered
						className="partner-list-header students-list-header"
					>
						<p> # Repartizarea studentilor la practică</p>
						{students_enrolled.map((students, key) => {
							return (
								<ListGroup.Item
									as="li"
									className="d-flex justify-content-between align-items-start"
								>
									<div className="ms-2 me-auto">
										{students.name}
									</div>
									<Badge pill>{students.students}</Badge>
								</ListGroup.Item>
							);
						})}
					</ListGroup>
				</div>
			</div>
		</div>
	);
};

export default Articles;
