import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_URLS } from '../../../../helpers/http';
import {
	checkPassword,
	checkPasswordSuccess,
	checkPasswordError,
	postPassword,
	postPasswordSuccess,
	postPasswordError,
} from '../slices/profileSlice';

import { PostPassword } from '../types';

import Cookies from 'js-cookie';

import axios, { AxiosResponse } from 'axios';

function* checkPasswordSaga(action: ReturnType<typeof checkPassword>) {
	const userToken = JSON.parse(Cookies.get('token')!).token;

	const password = action.payload;
	try {
		const checkPassword: AxiosResponse<PostPassword> = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.CHECKPASSWORD}`,
			{ password },
			{
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			}
		);
		if (checkPassword.statusText === 'OK') {
			yield put(checkPasswordSuccess({ infoText: 'OK', err: '' }));
		}
	} catch (err: any) {
		yield put(checkPasswordError({ err: err.response.data, infoText: '' }));
	}
}

function* postPasswordSaga(action: ReturnType<typeof checkPassword>) {
	const userToken = JSON.parse(Cookies.get('token')!).token;

	const password = action.payload;
	try {
		const response: AxiosResponse<PostPassword> = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.CHANGEPASSWORD}`,
			{ password },
			{
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			}
		);
		yield put(postPasswordSuccess({ passwordChanged: 'OK', err: '' }));
	} catch (err: any) {
		yield put(
			postPasswordError({
				infoText: '',
				passwordErr: err.response.statusText,
			})
		);
	}
}

function* profileSaga() {
	yield all([
		takeLatest(checkPassword, checkPasswordSaga),
		takeLatest(postPassword, postPasswordSaga),
	]);
}

export default profileSaga;
