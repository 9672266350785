const baseURL = 'https://api-techjobs-dev.marchi.ro/api';

export const API_URLS = {
	BASE_URL: baseURL,
	CHECKPASSWORD: '/account/checkpassword',
	CHANGEPASSWORD: '/account/changepassword',
	LOGIN: '/Account/login',
	COMPANY: '/company',
	INTERNSHIP: '/internship',
	INTERNSHIPSESSION: '/internshipSession',
	STUDENT: '/student',
	ADMIN: '/administrator',
	PROFILE: '/profile',
	ADMIND_COMPANIES: '/company?includeHidden=true',
	ADMIND_INTERNSHIP: '/internship',
	OWN_ATTENDANCE: '/internship/ownattendance',
	APPLICATION: '/application',
	USER_FILES: '/userFile',
};
