import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { API_URLS } from '../../../helpers/http';
import {
	getInternship,
	getInternshipFailure,
	getInternshipSucces,
	putInternship,
	putInternshipSuccess,
	putInternshipError,
	getInternshipApplicantsError,
	getInternshipApplicantsSuccess,
	getInternshipApplicants,
	getInternshipAcceptedStudentsSuccess,
	getInternshipAcceptedStudentsError,
	getInternshipAcceptedStudents,
	addStudentToInternshipSuccess,
	addStudentToInternshipError,
	addStudentToInternship,
	deleteStudentToInternshipSuccess,
	deleteStudentToInternshipError,
	deleteStudentToInternship,
	deleteApplicantSuccess,
	deleteApplicantError,
	deleteApplicant,
} from '../slices/internshipSlice';
import { Internship, InternshipId, EditInternshipInterface } from '../types';
import Cookies from 'js-cookie';

function* getInternshipSaga(action: PayloadAction<InternshipId>) {
	const id = action.payload.id;
	try {
		const response: Internship = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.ADMIND_INTERNSHIP}/${id}`
		);
		yield put(getInternshipSucces({ ...response }));
	} catch (err: any) {
		yield put(getInternshipFailure({ ...err }));
	}
}

function* getInternshipApplicantsSaga(action: PayloadAction<{ id: string }>) {
	const userToken = JSON.parse(Cookies.get('token')!).token;
	const id = action.payload.id;
	try {
		const response: Internship = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIP}/${id}${API_URLS.APPLICATION}`,
			{
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			}
		);
		yield put(getInternshipApplicantsSuccess({ response }));
	} catch (err: any) {
		yield put(getInternshipApplicantsError({ err: err.response.data }));
	}
}

function* getInternshipAcceptedStudentsSaga(
	action: PayloadAction<{ id: string }>
) {
	const userToken = JSON.parse(Cookies.get('token')!).token;
	const id = action.payload.id;

	try {
		const response: Internship = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIP}/${id}/student`,
			{
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			}
		);
		yield put(getInternshipAcceptedStudentsSuccess({ response }));
	} catch (err: any) {
		yield put(
			getInternshipAcceptedStudentsError({ err: err.response.data })
		);
	}
}

function* putInternshipSaga(action: PayloadAction<EditInternshipInterface>) {
	const {
		name,
		description,
		startDate,
		endDate,
		internshipId,
		companyId,
		internshipSessionId,
		internshipStatusId,
	} = action.payload;
	try {
		const response: EditInternshipInterface = yield call(
			axios.put,
			`${API_URLS.BASE_URL}${API_URLS.ADMIND_INTERNSHIP}/${internshipId}`,
			{
				name,
				description,
				startDate,
				endDate,
				companyId,
				internshipSessionId,
				internshipStatusId,
			}
		);
		yield put(putInternshipSuccess({ ...response }));
	} catch (err: any) {
		yield put(putInternshipError({ error: err.response.statusText }));
	}
}

function* addStudentToInternshipSaga(
	action: PayloadAction<{ id: string; studentId: string }>
) {
	const { id, studentId } = action.payload;

	try {
		const response: Internship = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIP}/${id}/student/${studentId}`
		);
		yield put(addStudentToInternshipSuccess({ response }));
	} catch (err: any) {
		yield put(addStudentToInternshipError({ err: err.response.data }));
	}
}

function* deleteApplicantSaga(
	action: PayloadAction<{ id: string; studentId: string }>
) {
	const { id, studentId } = action.payload;
	const userToken = JSON.parse(Cookies.get('token')!).token;

	try {
		const response: Internship = yield call(
			axios.delete,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIP}/${id}/student/${studentId}/application`,
			{
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			}
		);
		yield put(deleteApplicantSuccess({ response }));
	} catch (err: any) {
		yield put(deleteApplicantError({ err: err.response.data }));
	}
}

function* deleteStudentToInternshipSaga(
	action: PayloadAction<{ id: string; studentId: string }>
) {
	const { id, studentId } = action.payload;

	try {
		const response: Internship = yield call(
			axios.delete,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIP}/${id}/student/${studentId}`
		);
		yield put(deleteStudentToInternshipSuccess({ response }));
	} catch (err: any) {
		yield put(deleteStudentToInternshipError({ err: err.response.data }));
	}
}

function* internshipSaga() {
	yield all([
		takeLatest(getInternship, getInternshipSaga),
		takeLatest(getInternshipApplicants, getInternshipApplicantsSaga),
		takeLatest(
			getInternshipAcceptedStudents,
			getInternshipAcceptedStudentsSaga
		),
		takeLatest(addStudentToInternship, addStudentToInternshipSaga),
		takeLatest(deleteStudentToInternship, deleteStudentToInternshipSaga),
		takeLatest(deleteApplicant, deleteApplicantSaga),
		takeLatest(putInternship, putInternshipSaga),
	]);
}

export default internshipSaga;
