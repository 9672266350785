import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useImagePreloader from '../../../helpers/useImagePreloader';

import PageLoading from '../../../Components/PageLoading';
import { AppState } from '../../../store/rootReducer';
import {
	deleteInternSession,
	getInternSessions,
	postInternSession,
} from './slices/internSessionSlice';

import DatePicker from 'react-datepicker';

import internSessiongBgImg from '../../../assets/images/Dashboard/bg/stagii.jpg';

import moment from 'moment';

import calendar from '../../../assets/images/Dashboard/dashboard-icons/calendar.png';

import './style/style.css';

const InternshipSession = () => {
	const preloadSrcList: string[] = [internSessiongBgImg];
	const { imagesPreloaded } = useImagePreloader(preloadSrcList);

	const dispatch = useDispatch();
	const titleRef = useRef<HTMLInputElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const [showEditSession, setShowEditSession] = useState(false);
	const [internSessionDate, setInternSessionDate] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	const [showPopup, setShowPopup] = useState({ show: false, sessionId: '' });
	const internSession = useSelector(
		(state: AppState) => state.internSessions
	);

	const handlePostSession = () => {
		if (titleRef.current !== null && titleRef.current.value !== '') {
			dispatch(
				postInternSession({
					title: titleRef.current.value,
					startDate: moment(internSessionDate.startDate)
						.toISOString()
						.split('.')[0],
					endDate: moment(internSessionDate.endDate)
						.toISOString()
						.split('.')[0],
				})
			);
		} else {
		}
	};

	useEffect(() => {
		if (internSession.sessions.length > 2) {
			if (contentRef.current !== null) {
				contentRef.current.style.height = '100%';
			}
		} else {
			if (contentRef.current !== null) {
				contentRef.current.style.height = '100vh';
			}
		}
	}, [internSession.sessions]);

	const handleDeleteSession = () => {
		dispatch(deleteInternSession(showPopup.sessionId));
		setShowPopup({
			show: false,
			sessionId: '',
		});
	};

	useEffect(() => {
		dispatch(getInternSessions());
	}, []);

	useEffect(() => {
		if (internSession.postPending === false) {
			setShowEditSession(false);
			dispatch(getInternSessions());
		}
	}, [internSession.postPending]);

	useEffect(() => {
		internSession.deletePending === false && dispatch(getInternSessions());
	}, [internSession.deletePending]);

	return (
		<>
			{showPopup.show && (
				<div className="confirmation-popup">
					<div className="confirmation-container">
						<h2>
							Sunteti sigur ca vreti sa stergeti sesiunea de
							stagiu?
						</h2>
						<div className="confirm-buttons">
							<button
								className="btn btn-primary mr-5"
								onClick={() => handleDeleteSession()}
							>
								Da
							</button>
							<button
								className="btn btn-danger"
								onClick={() =>
									setShowPopup({
										show: false,
										sessionId: '',
									})
								}
							>
								Nu
							</button>
						</div>
					</div>
				</div>
			)}
			{internSession.pending && !imagesPreloaded && <PageLoading />}
			<div className="intern-session-id-container" ref={contentRef}>
				<div className="intern-session-background-img"></div>
				{!showEditSession && (
					<>
						<div
							className="add-new-session"
							onClick={() => setShowEditSession(true)}
						>
							<p>Adauga o noua sesiune de stagiu 📅</p>
						</div>
						{internSession.sessions.length > 0 &&
							internSession.sessions.map((session: any) => {
								return (
									<div
										id="intern-session-id-content"
										key={session.internshipSessionId}
									>
										<div
											className="delete-session"
											onClick={() =>
												setShowPopup({
													show: true,
													sessionId:
														session.internshipSessionId,
												})
											}
										>
											❌
											<span className="delete-session-message">
												Sterge sesiunea
											</span>
										</div>
										<>
											<a
												href={`/dashboard/sesiunea-de-stagii/${session.internshipSessionId}`}
												className="text-decoration-none"
											>
												{internSession.err !== '' && (
													<div
														className="alert alert-danger"
														role="alert"
													>
														{internSession.err}
													</div>
												)}

												<h1>{session.title}</h1>

												<div className="intern-session-id-details">
													<img
														src={calendar}
														alt="calendar"
													/>
													<h6>
														Start:
														{new Date(
															session.startDate
														).toLocaleDateString()}
													</h6>
												</div>
												<div className="intern-session-id-details">
													<img
														src={calendar}
														alt="calendar"
													/>
													<h6>
														Sfarsit:
														{new Date(
															session.endDate
														).toLocaleDateString()}
													</h6>
												</div>
											</a>
										</>
									</div>
								);
							})}
					</>
				)}
				{showEditSession && (
					<div className="add-new-intern-session-container">
						<h2>Adaugati o noua sesiune de stagiu</h2>
						<div className="add-new-intern-session">
							<button
								type="button"
								className="btn-close"
								aria-label="Close"
								onClick={() => {
									setShowEditSession(false);
								}}
							></button>
							<Form.Group className="mb-3">
								<Form.Label>Titlul stagiului</Form.Label>
								<Form.Control
									type="text"
									placeholder="Titlul stagiului..."
									ref={titleRef}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>
									Start perioada de stagiu
								</Form.Label>
								<DatePicker
									className="form-control"
									selected={internSessionDate.startDate}
									onChange={(selectedDate: any) => {
										setInternSessionDate((state) => {
											return {
												...state,
												startDate: selectedDate,
											};
										});
									}}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>
									Sfarsit perioada de stagiu
								</Form.Label>
								<DatePicker
									className="form-control"
									selected={internSessionDate.endDate}
									onChange={(selectedDate: any) => {
										setInternSessionDate((state) => {
											return {
												...state,
												endDate: selectedDate,
											};
										});
									}}
								/>
							</Form.Group>
							<button
								type="button"
								className="btn btn-primary text-light"
								onClick={() => handlePostSession()}
							>
								Adauga sesiunea de stagiu
							</button>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default InternshipSession;
