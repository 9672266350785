import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_URLS } from '../../../../helpers/http';
import {
	DashboardCompanies,
	DeleteDashboardCompany,
	PostDashboardCompany,
} from '../types';
import {
	getDashboardCompanies,
	getDashboardCompaniesSuccess,
	getDashboardCompaniesFailure,
	postDashboardCompany,
	postDashboardCompanySuccess,
	postDashboardCompanyError,
	deleteDashboardCompany,
	deleteDashboardCompanySuccess,
	deleteDashboardCompanyError,
} from '../slices/dashboardCompaniesSlice';
import { PayloadAction } from '@reduxjs/toolkit';

function* getDashboardCompaniesSaga() {
	try {
		const response: DashboardCompanies = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.ADMIND_COMPANIES}`
		);
		yield put(getDashboardCompaniesSuccess({ companies: response }));
	} catch (err: any) {
		yield put(getDashboardCompaniesFailure({ err }));
	}
}

function* postDashboardCompanySaga(
	action: PayloadAction<PostDashboardCompany>
) {
	const { address, phone, email, name, website, isVisible } = action.payload;
	try {
		const response: PostDashboardCompany = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.COMPANY}`,
			{
				address,
				phone,
				email,
				name,
				website,
				isVisible,
			}
		);
		yield put(postDashboardCompanySuccess({ ...response }));
	} catch (err: any) {
		yield put(postDashboardCompanyError({ err: err.response }));
	}
}

function* deleteDashboardCompanySaga(
	action: PayloadAction<DeleteDashboardCompany>
) {
	try {
		const companyID = action.payload;
		const response: DashboardCompanies = yield call(
			axios.delete,
			`${API_URLS.BASE_URL}${API_URLS.COMPANY}/${companyID}`
		);
		yield put(deleteDashboardCompanySuccess({ company: response }));
	} catch (err: any) {
		yield put(deleteDashboardCompanyError({ err: err.response }));
	}
}

function* dashboardCompaniesSaga() {
	yield all([
		takeLatest(getDashboardCompanies, getDashboardCompaniesSaga),
		takeLatest(postDashboardCompany, postDashboardCompanySaga),
		takeLatest(deleteDashboardCompany, deleteDashboardCompanySaga),
	]);
}

export default dashboardCompaniesSaga;
