import { createSlice } from '@reduxjs/toolkit';
import { DashboardCompanyId } from '../types';

const initialState: DashboardCompanyId = {
	companyId: null,
	address: '',
	email: '',
	name: '',
	phone: '',
	website: '',
	pending: false,
	err: '',
	putPending: true,
};

export const dashboardCompanySlice = createSlice({
	name: 'DashboardCompany',
	initialState,
	reducers: {
		getDashboardCompany: (state, action) => {
			return { ...state, pending: true };
		},
		getDashboardCompanySuccess: (state, action) => {
			return {
				...state,
				pending: false,
				...action.payload.data,
			};
		},
		getDashboardCompanyError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload,
			};
		},
		putDashboardCompany: (state, action) => {
			return {
				...state,
				pending: true,
			};
		},
		putDashboardCompanySuccess: (state, action) => {
			return {
				...state,
				pending: false,
				putPending: false,
			};
		},
		putDashboardCompanyError: (state, action) => {
			return {
				...state,
				pending: false,
				putPending: false,
				err: action.payload.err.statusText,
			};
		},
	},
});

export const {
	getDashboardCompany,
	getDashboardCompanyError,
	getDashboardCompanySuccess,
	putDashboardCompany,
	putDashboardCompanySuccess,
	putDashboardCompanyError,
} = dashboardCompanySlice.actions;

export default dashboardCompanySlice.reducer;
