import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	admins: [],
	pending: false,
	deletePending: true,
	postPending: true,
	err: '',
};

const adminsSlice = createSlice({
	name: 'Admins',
	initialState,
	reducers: {
		getAdmins: (state) => {
			return {
				...state,
				pending: true,
			};
		},
		getAdminsSuccess: (state, action) => {
			return {
				...state,
				admins: action.payload.admins.data,
				pending: false,
			};
		},
		getAdminsError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload.err,
			};
		},
		postAdmin: (state, action) => {
			return {
				...state,
				pending: true,
				postPending: true,
			};
		},
		postAdminSuccess: (state, action) => {
			return {
				...state,
				pending: false,
				postPending: false,
				err: '',
			};
		},
		postAdminError: (state, action) => {
			return {
				...state,
				pending: false,
				postPending: false,
				err: action.payload.err.data,
			};
		},
		deleteAdmin: (state, action) => {
			return {
				...state,
				pending: true,
				deletePending: true,
			};
		},
		deleteAdminSuccess: (state, action) => {
			return {
				...state,
				pending: false,
				deletePending: false,
			};
		},
		deleteAdminError: (state, action) => {
			return {
				...state,
				pending: false,
				deletePending: false,
				err: action.payload.err.data,
			};
		},
	},
});

export const {
	getAdmins,
	getAdminsSuccess,
	getAdminsError,
	postAdmin,
	postAdminSuccess,
	postAdminError,
	deleteAdmin,
	deleteAdminSuccess,
	deleteAdminError,
} = adminsSlice.actions;

export default adminsSlice.reducer;
