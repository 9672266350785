import { combineReducers } from '@reduxjs/toolkit';
import userSlice from '../Pages/LoginPage/slices/userSlice';
import internshipsSlice from '../Pages/Common/slices/internshipsSlice';
import internshipSlice from '../Pages/Common/slices/internshipSlice';
import dashboardCompaniesSlice from '../Pages/AdminDPages/DashboardCompanies/slices/dashboardCompaniesSlice';
import sidebarSlice from '../Pages/Common/slices/sidebarSlice';
import profileSlice from '../Pages/CommonDPages/Profile/slices/profileSlice';
import dashboardCompanySlice from '../Pages/AdminDPages/DashboardCompany/slices/dashboardCompanySlice';
import internSessionSlice from '../Pages/AdminDPages/InternshipSession/slices/internSessionSlice';
import dashboardInternSessionSlice from '../Pages/AdminDPages/DashboardInternSession/slices/dashboardInternSessionSlice';
import adminsSlice from '../Pages/AdminDPages/Admin/slices/adminsSlice';
import studentsSlice from '../Pages/AdminDPages/Students/slices/studentsSlice';
import dashboardAdminSlice from '../Pages/AdminDPages/DashboardAdmin/slices/dashboardAdminSlice';
import studentInternshipSlice from '../Pages/StudentDPages/DashboardInternship/slices/studentInternshipSlice';
import studentCommonSlice from '../Pages/StudentDPages/Common/slices/studentCommonSlice';

const rootReducer = combineReducers({
	login: userSlice,
	profile: profileSlice,
	sidebarMenu: sidebarSlice,
	internships: internshipsSlice,
	internshipId: internshipSlice,
	dashboardCompanies: dashboardCompaniesSlice,
	dashboardCompany: dashboardCompanySlice,
	internSessions: internSessionSlice,
	dashboardInternSession: dashboardInternSessionSlice,
	admins: adminsSlice,
	dashboardAdmin: dashboardAdminSlice,
	dashboardAdminStudents: studentsSlice,
	dashboardStudentInternship: studentInternshipSlice,
	commonStudentDetails: studentCommonSlice,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
