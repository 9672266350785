import { createSlice } from '@reduxjs/toolkit';
import { DashboardIdSession } from '../types';

const initialState: DashboardIdSession = {
	title: '',
	startDate: '',
	endDate: '',
	pending: false,
	putPending: true,
};

const dashboardInternSessionSlice = createSlice({
	name: 'Dashboard Intern Session',
	initialState,
	reducers: {
		getDashboardInternSessionId: (state, action) => {
			return {
				...state,
				pending: true,
			};
		},
		getDashboardInternSessionIdSuccess: (state, action) => {
			return {
				...state,
				pending: false,
				...action.payload.response.data,
			};
		},
		getDashboardInternSessionIdError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload.err,
			};
		},
		putDashboardInternSessionId: (state, action) => {
			return {
				...state,
				pending: true,
				putPending: true,
			};
		},
		putDashboardInternSessionsIdSuccess: (state, action) => {
			return {
				...state,
				pending: false,
				putPending: false,
			};
		},
		putDashboardInternSessionsIdError: (state, action) => {
			return {
				...state,
				pending: false,
				putPending: action.payload.err,
			};
		},
	},
});

export const {
	getDashboardInternSessionId,
	getDashboardInternSessionIdSuccess,
	getDashboardInternSessionIdError,
	putDashboardInternSessionId,
	putDashboardInternSessionsIdSuccess,
	putDashboardInternSessionsIdError,
} = dashboardInternSessionSlice.actions;

export default dashboardInternSessionSlice.reducer;
