import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginParams, UserInitialState } from '../types';

const initialState: UserInitialState = {
	username: '',
	role: '',
	originalUsername: null,
	accessToken: '',
	refreshToken: '',
	expires: '',
	pending: false,
	error: '',
};

export const userSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		postLoginDetails: (state, action: PayloadAction<LoginParams>) => {
			return {
				...state,
				...action.payload,
				pending: true,
				error: '',
			};
		},
		postLoginSuccess: (state, action) => {
			return { ...state, ...action.payload, pending: false, error: '' };
		},
		postLoginError: (state, action) => {
			return {
				...state,
				error: action.payload.error.response.data,
				pending: false,
			};
		},
	},
});

export const { postLoginDetails, postLoginSuccess, postLoginError } =
	userSlice.actions;

export default userSlice.reducer;
