import PressRelease from '../../Pages/PublicPages/PressRelease';

import pressImage1Page1 from '../../assets/images/press/2022/1/Comunicat_selectie_GT_Page_1.png';
import pressImage1Page2 from '../../assets/images/press/2022/1/Comunicat_selectie_GT_Page_2.png';
import pressImage2Page1 from '../../assets/images/press/2022/2/Comunicat_activitati_proiect_AA2_Page_1.png';
import pressImage2Page2 from '../../assets/images/press/2022/2/Comunicat_activitati_proiect_AA2_Page_2.png';
import pressImage3Page1 from '../../assets/images/press/2022/3/Comunicat_Activitatea_de_consiliere_Page_1.png';
import pressImage3Page2 from '../../assets/images/press/2022/3/Comunicat_Activitatea_de_consiliere_Page_2.png';
import pressImage4Page1 from '../../assets/images/press/2022/4/Comunicat_practica_Page_1.png';
import pressImage4Page2 from '../../assets/images/press/2022/4/Comunicat_practica_Page_2.png';
import pressImage5Page1 from '../../assets/images/press/2022/5/Comunicat_regulament_concurs_Page_1.png';
import pressImage5Page2 from '../../assets/images/press/2022/5/Comunicat_regulament_concurs_Page_2.png';
import pressImage6Page1 from '../../assets/images/press/2022/6/Anunt_ateliere.png';
import pressImage7Page1 from '../../assets/images/press/2022/7/Invitatie_ateliere.png';
import pressImage8Page1 from '../../assets/images/press/2022/8/Anunt_concursuri_Page_1.png';
import pressImage8Page2 from '../../assets/images/press/2022/8/Anunt_concursuri_Page_2.png';
import pressImage9Page1 from '../../assets/images/press/2022/9/Anunt_stagii_de_practica.png';
import pressImage10Page1 from '../../assets/images/press/2022/10/Stagiu_de_practica_conteaza_in_CV.png';
import pressImage11Page1 from '../../assets/images/press/2022/11/Apel_Casa_Auto.png';
import pressImage12Page1 from '../../assets/images/press/2022/12/Anunt_final_ateliere.png';
import pressImage13Page1 from '../../assets/images/press/2022/13/Anunt_practica.png';
import pressImage14Page1 from '../../assets/images/press/2022/14/Hai_la_practica.png';
import pressImage15Page1 from '../../assets/images/press/2022/15/Ultimele_sesiuni_de_consiliere.png';
import pressImage16Page1 from '../../assets/images/press/2022/16/Stire_job_practica.png';
import pressImage17Page1 from '../../assets/images/press/2022/17/De_ce_sa_participi_la_practica_Page_1.png';
import pressImage17Page2 from '../../assets/images/press/2022/17/De_ce_sa_participi_la_practica_Page_2.png';
import pressImage18Page1 from '../../assets/images/press/2022/18/A_inceput_practica_Page_1.png';
import pressImage18Page2 from '../../assets/images/press/2022/18/A_inceput_practica_Page_2.png';
import pressImage19Page1 from '../../assets/images/press/2022/19/Ultimele_sesiuni_de_consiliere.png';
import pressImage20Page1 from '../../assets/images/press/2022/20/Anunt_ateliere.png';
import pressImage21Page1 from '../../assets/images/press/2022/21/organizare_concursuri_iunie_Page_1.png';
import pressImage21Page2 from '../../assets/images/press/2022/21/organizare_concursuri_iunie_Page_2.png';
import pressImage22Page1 from '../../assets/images/press/2022/22/Anunt_sectiunea_student_in_aplicatie.png';
import pressImage23Page1 from '../../assets/images/press/2022/23/Anunt_ateliere_diplome.png';
import pressImage24Page1 from '../../assets/images/press/2022/24/Multumim_studentilor.png';
import pressImage25Page1 from '../../assets/images/press/2022/25/Multumim_partenerilor_de_practica.png';
import pressImage26Page1 from '../../assets/images/press/2022/26/Anunt_practica_Casa_Auto_Page_1.png';
import pressImage26Page2 from '../../assets/images/press/2022/26/Anunt_practica_Casa_Auto_Page_2.png';
import pressImage27Page1 from '../../assets/images/press/2022/27/Viitori_ingineri.png';
import pressImage28Page1 from '../../assets/images/press/2022/28/Anunt_concurs_SA5.1.png';
import pressImage29Page1 from '../../assets/images/press/2022/29/Anunt_rezultate_concurs.png';
import pressImage30Page1 from '../../assets/images/press/2022/30/Lista_finala_premiere_Page_1.png';
import pressImage30Page2 from '../../assets/images/press/2022/30/Lista_finala_premiere_Page_2.png';
import pressImage30Page3 from '../../assets/images/press/2022/30/Lista_finala_premiere_Page_3.png';
import pressImage31Page1 from '../../assets/images/press/2022/31/1_CV-ul_care_atrage_atentia.png';
import pressImage32Page1 from '../../assets/images/press/2022/32/2_Cele_mai_bune_10_site-uri_de_cautare_a_locurilor_de_munca_din_2022.png';
import pressImage33Page1 from '../../assets/images/press/2022/33/3_Formatul_CV-ului.png';
import pressImage34Page1 from '../../assets/images/press/2022/34/4_Adresa_de_e-mail_profesionala.png';
import pressImage35Page1 from '../../assets/images/press/2022/35/5_Interviul_video_pentru_angajare.png';
import pressImage36Page1 from '../../assets/images/press/2022/36/6_Emailul_de_angajare.png';
import pressImage37Page1 from '../../assets/images/press/2022/37/7_Corespondenta_electronica_pentru_angajare.png';
import pressImage38Page1 from '../../assets/images/press/2022/38/8_Interviul_de_angajare_intrebari_incomode_si_recomandari.png';
import pressImage39Page1 from '../../assets/images/press/2022/39/9_Importanta_abilitatilor_de_relationare.png';
import pressImage40Page1 from '../../assets/images/press/2022/40/10_Cum_va_puteti_dezvolta_propriile_competente_interpersonale.png';
import pressImage41Page1 from '../../assets/images/press/2022/41/Anunt_program_vizite_Italia.png';
import pressImage42Page1 from '../../assets/images/press/2022/42/1_Primul_pas_important_catre_o_cariera_de_succes.png';
import pressImage43Page1 from '../../assets/images/press/2022/43/2_Munca_la_distanta_(telemunca)_si_job_urile_hibride.png';
import pressImage44Page1 from '../../assets/images/press/2022/44/3_Managementul_timpului.png';
import pressImage45Page1 from '../../assets/images/press/2022/45/4_Tipuri_de_competente_interpersonale.png';
import pressImage46Page1 from '../../assets/images/press/2022/46/5_Relationarea_cu_oamenii_dificili_la_locul_de_munca.png';
import pressImage47Page1 from '../../assets/images/press/2022/47/6_Abilitati_de_gestionare_a_timpului.png';
import pressImage48Page1 from '../../assets/images/press/2022/48/7_Munca_la_distanta_si_dezvoltarea_profesionala.png';
import pressImage49Page1 from '../../assets/images/press/2022/49/8_Cum_să_fiti_mai_productivi_la_locul_de_munca.png';
import pressImage50Page1 from '../../assets/images/press/2022/50/1.Arpor_Page_1.png';
import pressImage50Page2 from '../../assets/images/press/2022/50/1.Arpor_Page_2.png';
import pressImage50Page3 from '../../assets/images/press/2022/50/1.Arpor_Page_3.png';
import pressImage51Page1 from '../../assets/images/press/2022/51/2.Compania_FE.VI._Frutta_S.r.l_Page_1.png';
import pressImage51Page2 from '../../assets/images/press/2022/51/2.Compania_FE.VI._Frutta_S.r.l_Page_2.png';
import pressImage52Page1 from '../../assets/images/press/2022/52/3.Compania_Taverna_Page_1.png';
import pressImage52Page2 from '../../assets/images/press/2022/52/3.Compania_Taverna_Page_2.png';
import pressImage52Page3 from '../../assets/images/press/2022/52/3.Compania_Taverna_Page_3.png';
import pressImage53Page1 from '../../assets/images/press/2022/53/4.GIARDINI_D_ORIENTE_Page_1.png';
import pressImage53Page2 from '../../assets/images/press/2022/53/4.GIARDINI_D_ORIENTE_Page_2.png';
import pressImage54Page1 from '../../assets/images/press/2022/54/5.Metapontum_Agrobios_Page_1.png';
import pressImage54Page2 from '../../assets/images/press/2022/54/5.Metapontum_Agrobios_Page_2.png';
import pressImage54Page3 from '../../assets/images/press/2022/54/5.Metapontum_Agrobios_Page_3.png';
import pressImage55Page1 from '../../assets/images/press/2022/55/6.Alsia_Ricerca_Page_1.png';
import pressImage55Page2 from '../../assets/images/press/2022/55/6.Alsia_Ricerca_Page_2.png';
import pressImage55Page3 from '../../assets/images/press/2022/55/6.Alsia_Ricerca_Page_3.png';
import pressImage56Page1 from '../../assets/images/press/2022/56/1.Primaria_Bari_Page_1.png';
import pressImage56Page2 from '../../assets/images/press/2022/56/1.Primaria_Bari_Page_2.png';
import pressImage56Page3 from '../../assets/images/press/2022/56/1.Primaria_Bari_Page_3.png';
import pressImage57Page1 from '../../assets/images/press/2022/57/2.Universitatea_Bari_Page_1.png';
import pressImage57Page2 from '../../assets/images/press/2022/57/2.Universitatea_Bari_Page_2.png';
import pressImage58Page1 from '../../assets/images/press/2022/58/3.Politehnica_din_Bari_Page_1.png';
import pressImage58Page2 from '../../assets/images/press/2022/58/3.Politehnica_din_Bari_Page_2.png';
import pressImage58Page3 from '../../assets/images/press/2022/58/3.Politehnica_din_Bari_Page_3.png';
import pressImage59Page1 from '../../assets/images/press/2022/59/4.EREDI_MARINELLI_Page_1.png';
import pressImage59Page2 from '../../assets/images/press/2022/59/4.EREDI_MARINELLI_Page_2.png';
import pressImage60Page1 from '../../assets/images/press/2022/60/5.FABLAB_POLIBA_Page_1.png';
import pressImage60Page2 from '../../assets/images/press/2022/60/5.FABLAB_POLIBA_Page_2.png';
import pressImage61Page1 from '../../assets/images/press/2022/61/6.SITEC_SRL_Page_1.png';
import pressImage61Page2 from '../../assets/images/press/2022/61/6.SITEC_SRL_Page_2.png';
import pressImage61Page3 from '../../assets/images/press/2022/61/6.SITEC_SRL_Page_3.png';
import pressImage62Page1 from '../../assets/images/press/2022/62/7.TECNOPOLIS_PST_Page_1.png';
import pressImage62Page2 from '../../assets/images/press/2022/62/7.TECNOPOLIS_PST_Page_2.png';
import pressImage62Page3 from '../../assets/images/press/2022/62/7.TECNOPOLIS_PST_Page_3.png';
import pressImage63Page1 from '../../assets/images/press/2022/63/8.MTM_Project_Page_1.png';
import pressImage63Page2 from '../../assets/images/press/2022/63/8.MTM_Project_Page_2.png';
import pressImage63Page3 from '../../assets/images/press/2022/63/8.MTM_Project_Page_3.png';
import pressImage63Page4 from '../../assets/images/press/2022/63/8.MTM_Project_Page_4.png';
import pressImage64Page1 from '../../assets/images/press/2022/64/9.InResLab_Page_1.png';
import pressImage64Page2 from '../../assets/images/press/2022/64/9.InResLab_Page_2.png';
import pressImage64Page3 from '../../assets/images/press/2022/64/9.InResLab_Page_3.png';
import pressImage65Page1 from '../../assets/images/press/2022/65/10.MIMI_S.R.L_Page_1.png';
import pressImage65Page2 from '../../assets/images/press/2022/65/10.MIMI_S.R.L_Page_2.png';
import pressImage66Page1 from '../../assets/images/press/2022/66/11.DITRO_DESIGNIA_Page_1.png';
import pressImage66Page2 from '../../assets/images/press/2022/66/11.DITRO_DESIGNIA_Page_2.png';
import pressImage66Page3 from '../../assets/images/press/2022/66/11.DITRO_DESIGNIA_Page_3.png';
import pressImage67Page1 from '../../assets/images/press/2022/67/12.VITONE_ECO_Page_1.png';
import pressImage67Page2 from '../../assets/images/press/2022/67/12.VITONE_ECO_Page_2.png';
import pressImage67Page3 from '../../assets/images/press/2022/67/12.VITONE_ECO_Page_3.png';
import pressImage67Page4 from '../../assets/images/press/2022/67/12.VITONE_ECO_Page_4.png';
import pressImage67Page5 from '../../assets/images/press/2022/67/12.VITONE_ECO_Page_5.png';
import pressImage68Page1 from '../../assets/images/press/2022/68/1.Invitatie conferinta finala.png';
import pressImage69Page1 from '../../assets/images/press/2022/69/2.Anunt sfarsit conferinta finala.png';
import pressImage70Page1 from '../../assets/images/press/2022/70/3.Comunicat final proiect_Page_1.png';
import pressImage70Page2 from '../../assets/images/press/2022/70/3.Comunicat final proiect_Page_2.png';
import pressImage70Page3 from '../../assets/images/press/2022/70/3.Comunicat final proiect_Page_3.png';


const SpecificPressRelease = ({
	pressRelease,
}: {
	pressRelease: { url: string; title: string };
}) => {
	const pressData: any = {
		title: '',
		documents: [],
		links: [],
		pressHtml: '',
	};
	switch (pressRelease.url) {
		case 'anunturi/2022/comunicat-selectie-gt':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage1Page1, pressImage1Page2];
			pressData.links = [
				'/static/files/2022/Metodologie_selectie_Grup_Tinta/Anexe_Metodologie_selectie_Grup_Tinta.pdf',
				'/static/files/2022/Metodologie_selectie_Grup_Tinta/Metodologie_selectie_Grup_Tinta.pdf',
			];
			break;
		case 'anunturi/2022/comunicat-activitati-proiect-aa2': {
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage2Page1, pressImage2Page2];
			pressData.links = [
				'/static/files/2022/Metodologie_derulare_stagii_de_practica/Metodologie_derulare _stagii_de_practica_revizie_februarie_2022_ID133398.pdf',
				'/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1_CONVENTIE_privind_stagiul_de_practica_al_studentilor_ID133398.pdf',
				'/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1.1_ACORD_participare_stagiu_de_practica_ID133398.pdf',
				'/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_1.2_ACORD_privind_prelucrarea_datelor_cu_caracter_personal_ID133398.pdf',
				'/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_4_Caiet_de_practica_ID133398.pdf',
				'/static/files/2022/Metodologie_derulare_stagii_de_practica/ANEXA_8_Adeverinta_efectuare_stagiu_practica_ID133398.pdf',
			];
			break;
		}
		case 'anunturi/2022/comunicat-activitatea-de-consiliere':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage3Page1, pressImage3Page2];
			pressData.links = [
				'/static/files/2022/Metodologie_consiliere_si_orientare_profesionala_ID133398.pdf',
			];
			break;
		case 'anunturi/2022/comunicat-stagii-practica':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage4Page1, pressImage4Page2];
			pressData.links = [
				'/static/files/2022/Regulament_concurs_SA5.1_ID133398.pdf',
			];
			break;
		case 'anunturi/2022/regulament-concurs':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage5Page1, pressImage5Page2];
			break;
		case 'anunturi/2022/anunt-ateliere':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage6Page1];
			break;
		case 'anunturi/2022/invitatie-ateliere':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage7Page1];
			pressData.pressHtml =
				"<p>Detalii conectare Zoom meeting: <br/><br/><a href='https://us04web.zoom.us/j/7237527492?pwd=bDJpSnp3emxlTUl3ckxhM042TTJ5Zz09' target='_blank'>https://us04web.zoom.us/j/7237527492?pwd=bDJpSnp3emxlTUl3ckxhM042TTJ5Zz09</a><br/>Meeting ID: 723 752 7492<br/>Passcode: 4pQzZ7</p>";
			break;
		case 'anunturi/2022/anunt-concursuri':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage8Page1, pressImage8Page2];
			break;
		case 'anunturi/2022/anunt-stagii-de-practica':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage9Page1];
			break;
		case 'anunturi/2022/stagiu-de-practica-conteaza-in-cv':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage10Page1];
			break;
		case 'anunturi/2022/apel-stagiu-casa-auto':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage11Page1];
			break;
		case 'anunturi/2022/anunt-final-ateliere':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage12Page1];
			break;
		case 'anunturi/2022/te-asteptam-la-noi-la-practica':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage13Page1];
			break;
		case 'anunturi/2022/hai-la-practica':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage14Page1];
			break;
		case 'anunturi/2022/ultimele-sesiuni-de-consiliere':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage15Page1];
			break;
		case 'anunturi/2022/stire-job-practica':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage16Page1];
			break;
		case 'anunturi/2022/de-ce-sa-participi-la-practica':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage17Page1, pressImage17Page2];
			break;
		case 'anunturi/2022/a-inceput-practica':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage18Page1, pressImage18Page2];
			break;
		case 'anunturi/2022/anunt-ateliere-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage20Page1];
			pressData.pressHtml =
				"<p>Detalii conectare Zoom meeting: <br/><br/><a href='https://us04web.zoom.us/j/7237527492?pwd=bDJpSnp3emxlTUl3ckxhM042TTJ5Zz09' target='_blank'>https://us04web.zoom.us/j/7237527492?pwd=bDJpSnp3emxlTUl3ckxhM042TTJ5Zz09</a><br/>Meeting ID: 723 752 7492<br/>Passcode: 4pQzZ7</p>";
			break;
		case 'anunturi/2022/ultimele-sesiuni-de-consiliere-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage19Page1];
			break;
		case 'anunturi/2022/organizare-concursuri-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage21Page1,pressImage21Page2];
			break;
		case 'anunturi/2022/anunt-sectiune-studenti-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage22Page1];
			break;
		case 'anunturi/2022/anunt-ateliere-diplome-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage23Page1];
			break;
		case 'anunturi/2022/multumim-studentilor-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage24Page1];
			break;
		case 'anunturi/2022/multumim-partenerilor-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage25Page1];
			break;
		case 'anunturi/2022/multumim-casa-auto-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage26Page1, pressImage26Page2];
			break;
		case 'anunturi/2022/stagii-de-practica-ingineri-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage27Page1];
			break;
		case 'anunturi/2022/anunt-concurs-premiere-iulie':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage28Page1];
			break;
		case 'anunturi/2022/anunt-rezultate-concurs-august':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage29Page1];
			break;
		case 'anunturi/2022/anunt-lista-premiere-august':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage30Page1, pressImage30Page2, pressImage30Page3];
			break;
		case 'anunturi/2022/anunt-cv-ul-care-atrage-atentia':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage31Page1];
			break;
		case 'anunturi/2022/anunt-cele-mai-bune-siteuri-job':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage32Page1];
			pressData.pressHtml =
				`<p style='margin-left:10%'>Link-uri utile: <br/><br/>
					<a href='https://ro.indeed.com/' target='_blank'>https://ro.indeed.com/</a><br/>
					<a href='https://www.monster.com/' target='_blank'>https://www.monster.com/</a><br/>
					<a href='https://www.glassdoor.com/index.htm' target='_blank'>https://www.glassdoor.com/index.htm</a><br/>
					<a href='https://www.flexjobs.com/' target='_blank'>https://www.flexjobs.com/</a><br/>
					<a href='https://www.theladders.com/' target='_blank'>https://www.theladders.com/</a><br/>
					<a href='https://angel.co/' target='_blank'>https://angel.co/</a><br/>
					<a href='https://www.linkedin.com/' target='_blank'>https://www.linkedin.com/</a><br/>
					<a href='https://getwork.com/' target='_blank'>https://getwork.com/</a><br/>
					<a href='https://scouted.io/' target='_blank'>https://scouted.io/</a><br/>
					<a href='https://www.snagajob.com/' target='_blank'>https://www.snagajob.com/</a><br/>
				</p>`;
			break;
		case 'anunturi/2022/anunt-formatul-cv-ului':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage33Page1];
			break;
		case 'anunturi/2022/anunt-adreasa-profesionala':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage34Page1];
			break;
		case 'anunturi/2022/interviul-video-pentru-angajare':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage35Page1];
			break;
		case 'anunturi/2022/anunt-emailul-de-angajare':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage36Page1];
			break;
		case 'anunturi/2022/anunt-corespondenta-electronica-pentru-angajare':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage37Page1];
			break;
		case 'anunturi/2022/anunt-interviul_de_angajare_intrebari_incomode':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage38Page1];
			break;
		case 'anunturi/2022/anunt-inportanta_soft_skills':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage39Page1];
			break;
		case 'anunturi/2022/anunt-cum-va-puteti-dezvolta-competentele':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage40Page1];
			break;
		case 'anunturi/2022/anunt-vizite-externe':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage41Page1];			
			break;
		case 'anunturi/2022/tips-primul-pas-in-cariera':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage42Page1];			
			break;
		case 'anunturi/2022/tips-munca-la-distanta-si-joburile-hibride':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage43Page1];			
			break;
		case 'anunturi/2022/tips-managementul-timpului':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage44Page1];			
			break;
		case 'anunturi/2022/tips-tipuri-de-competente-interpersonale':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage45Page1];			
			break;
		case 'anunturi/2022/tips-relationarea-cu-oamenii-dificili':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage46Page1];			
			break;
		case 'anunturi/2022/tips-abilitati-de-gestionare-a-timpului':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage47Page1];			
			break;
		case 'anunturi/2022/tips-munca-la-distanta-si-dezvoltarea-personala':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage48Page1];			
			break;
		case 'anunturi/2022/tips-cum-sa-fim-mai-productivi':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage49Page1];			
			break;
		case 'anunturi/2022/visits-arpor':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage50Page1, pressImage50Page2, pressImage50Page3];			
			break;
		case 'anunturi/2022/visits-fevi':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage51Page1, pressImage51Page2];			
			break;
		case 'anunturi/2022/visits-taverna':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage52Page1, pressImage52Page2, pressImage52Page3];			
			break;
		case 'anunturi/2022/visits-giardini-d-oriente':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage53Page1, pressImage53Page2];			
			break;
		case 'anunturi/2022/visits-metapontum-agrobios':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage54Page1, pressImage54Page2, pressImage54Page3];			
			break;
		case 'anunturi/2022/visits-alsia-ricerca':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage55Page1, pressImage55Page2, pressImage55Page3];			
			break;
		case 'anunturi/2022/visits-primaria-bari':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage56Page1, pressImage56Page2, pressImage56Page3];			
			break;
		case 'anunturi/2022/visits-universitatea-bari':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage57Page1, pressImage57Page2];			
			break;
		case 'anunturi/2022/visits-politehnica-bari':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage58Page1, pressImage58Page2, pressImage58Page3];			
			break;
		case 'anunturi/2022/visits-eredi-marinelli':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage59Page1, pressImage59Page2];			
			break;
		case 'anunturi/2022/visits-fablab-poliba':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage60Page1, pressImage60Page2];			
			break;
		case 'anunturi/2022/visits-sitec':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage61Page1, pressImage61Page2, pressImage61Page3];			
			break;
		case 'anunturi/2022/visits-tecnopolis':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage62Page1, pressImage62Page2, pressImage62Page3];			
			break;
		case 'anunturi/2022/visits-mtm-project':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage63Page1, pressImage63Page2, pressImage63Page3, pressImage63Page4];			
			break;
		case 'anunturi/2022/visits-inreslab':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage64Page1, pressImage64Page2, pressImage64Page3];			
			break;
		case 'anunturi/2022/visits-mimi':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage65Page1, pressImage65Page2];			
			break;
		case 'anunturi/2022/visits-ditro-designia':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage66Page1, pressImage66Page2, pressImage66Page3];			
			break;
		case 'anunturi/2022/visits-vitone-eco':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage67Page1, pressImage67Page2, pressImage67Page3, pressImage67Page4, pressImage67Page5];			
			break;
		case 'anunturi/2022/anunt-sept-invitatie-conferinta-finala':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage68Page1];			
			break;
		case 'anunturi/2022/anunt-sept-sfarsit-conferinta-finala':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage69Page1];			
			break;
		case 'anunturi/2022/anunt-sept-comunicat-final-proiect':
			pressData.title = pressRelease.title;
			pressData.documents = [pressImage70Page1,pressImage70Page2,pressImage70Page3];			
			break;
		
		
		default:
			break;
	}
	return <PressRelease data={pressData} />;
};

export default SpecificPressRelease;
