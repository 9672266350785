import axios, { AxiosResponse } from 'axios';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { API_URLS } from '../../../helpers/http';
import {
	getProfile,
	getProfileSuccess,
	getProfileError,
	getProfileFile,
	getProfileFileError,
	getProfileFileSuccess,
} from '../slices/sidebarSlice';
import { UserState } from '../types';

import Cookies from 'js-cookie';
import { PayloadAction } from '@reduxjs/toolkit';

function* getProfileSaga() {
	const userToken = JSON.parse(Cookies.get('token')!).token;
	try {
		const response: AxiosResponse<UserState> = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.PROFILE}`,
			{
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			}
		);

		const avatar: AxiosResponse = yield call(
			axios.get,
			`https://avatars.dicebear.com/api/identicon/${response.data.firstName}${response.data.lastName}.svg`
		);
		yield put(
			getProfileSuccess({
				profile: response,
				avatar: avatar,
			})
		);
	} catch (err: any) {
		yield put(
			getProfileError({
				err: err.response,
			})
		);
	}
}

function* getProfileFileSaga(action: PayloadAction) {
	const userToken = JSON.parse(Cookies.get('token')!).token;
	const filePath = action.payload;
	try {
		const response: AxiosResponse<UserState> = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.USER_FILES}/${filePath}`,
			{
				responseType: 'arraybuffer',
				headers: {
					Authorization: `Bearer ${userToken}`,
					'Content-Type': 'application/json',
				},
			}
		);
		yield put(getProfileFileSuccess({ response }));
	} catch (err: any) {
		yield put(getProfileFileError({ err: err.response }));
	}
}

function* sidebarSaga() {
	yield all([
		takeLatest(getProfile, getProfileSaga),
		takeLatest(getProfileFile, getProfileFileSaga),
	]);
}

export default sidebarSaga;
