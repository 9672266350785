import logo from './logo-blue.png';
import logoeu from './logos/logo-eu.png';
import logogov from './logos/logo-guvernul-ro.png';
import logois from './logos/logo-is.png';
import logocmmi from './logos/logo-cmmi.png';
import logotui1 from './logos/logo-tuiasi-1.png';
import logotui2 from './logos/logo-tuiasi-2.png';

import arrow from './arrow.png';
import checkbox from './checkbox.png';
import hero_1 from './hero-1.png';
import hero_2 from './hero-2.png';

import iconAddress from './icons/address.png';
import iconEmail from './icons/email.png';

import bg_company from './Dashboard/bg/bg_company.png';
import bg_company_sm from './Dashboard/bg/bg_company_sm.png';
import bg_map from './Dashboard/bg/bg_map.png';
import bg_register from './Dashboard/bg/bg_register.png';
import bg_generic from './Dashboard/bg/bg_generic.png';


const images = {
	logo,
	logoeu,
	logogov,
	logois,
	logocmmi,
	logotui1,
	logotui2,
	hero_1,
	hero_2,
	iconAddress,
	iconEmail,
	bg_company,
	bg_company_sm,
	bg_map,
	bg_register,
	arrow,
	checkbox,
	bg_generic

};

export default images;

