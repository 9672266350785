import { useRef, useState } from 'react';

import images from '../../../assets/images/images';
import { Container, Row, Col, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';

import './style/style.css';

const ContactPage = () => {
	const [contactUsError, setContactUsError] = useState({
		show: false,
		errMsg: '',
	});

	const firstNameRef = useRef<HTMLInputElement>(null);
	const lastNameRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const handleContactUs = () => {
		const phoneNumber = (
			document.getElementById('contact-us-phone-number') as HTMLInputElement
		).value;
		if (
			firstNameRef.current !== null &&
			firstNameRef.current.value !== '' &&
			lastNameRef.current !== null &&
			lastNameRef.current.value !== '' &&
			emailRef.current !== null &&
			emailRef.current.value !== ''
		) {
			const regex =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (regex.test(emailRef.current.value) === false) {
				setContactUsError({
					show: true,
					errMsg: 'Adresa de email nu este valida',
				});
			} else if (phoneNumber.length > 3 && phoneNumber.length < 15) {
				setContactUsError({
					show: true,
					errMsg: 'Numarul de telefonul nu este valid',
				});
			} else if (
				textAreaRef.current !== null &&
				textAreaRef.current.value !== '' &&
				textAreaRef.current.value.length < 10
			) {
				setContactUsError({
					show: true,
					errMsg: 'Mesajul dvs. este prea scurt',
				});
			} else
				setContactUsError({
					show: false,
					errMsg: '',
				});
		} else {
			setContactUsError({
				show: true,
				errMsg: 'Va rugam completati toate campurile',
			});
		}
	};

	return (
		<div className="contact-page-container">
			<Container fluid>
				<Row className="background-image">
					<img
						src={images.bg_company_sm}
						className="p-0"
						alt="contact-page-background"
					/>
					<div className="contact-page-content">
						<h2>Contact</h2>
					</div>
				</Row>
				<Row>
					<div className="contact-us-form">
						<div className="contact-us-content-inputs">
							<h3 className="lets-start">Contactează-ne!</h3>
							<Form.Group className="mb-3">
								<Form.Label>Nume</Form.Label>
								<Form.Control
									type="text"
									name="first-name"
									placeholder="Nume..."
									ref={firstNameRef}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Prenume</Form.Label>
								<Form.Control
									type="text"
									name="last-name"
									placeholder="Prenume..."
									ref={lastNameRef}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="text"
									name="email"
									placeholder="Email..."
									ref={emailRef}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Telefon (Optional)</Form.Label>
								<PhoneInput
									international
									country="RO"
									defaultCountry="RO"
									maxLength={15}
									onChange={() => console.log('')}
									id="contact-us-phone-number"
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Mesajul tău</Form.Label>
								<Form.Control as="textarea" rows={5} ref={textAreaRef} />
							</Form.Group>

							<button
								onClick={handleContactUs}
								className="btn btn-primary mt-3 mb-3"
							>
								Finalizare
							</button>
							{contactUsError.show && (
								<div className="alert alert-danger" role="alert">
									{contactUsError.errMsg !== '' && contactUsError.errMsg}
								</div>
							)}
						</div>
					</div>
				</Row>
				<Row className="contact-page-contact">
					<Col className="d-flex justify-content-center align-items-center">
						<img src={images.iconAddress} alt="contact-page-icon" />
						<div>
							<h3>Adresă</h3>
							<p>Bd. Dimitrie Mangeron, nr. 67, 700050 Iaşi, România</p>
						</div>
					</Col>
					<Col className="d-flex justify-content-center align-items-center">
						<img src={images.iconEmail} alt="contact-page-icon" />
						<div>
							<h3>Email</h3>
							<p>
								<a href="mailto:proiect133398@gmail.com">
									{' '}
									proiect133398@gmail.com
								</a>
							</p>
						</div>
					</Col>
				</Row>
				<Row className="background-image">
					<img
						src={images.bg_map}
						className="p-0"
						alt="contact-page-background"
					/>
				</Row>
				<Row className="register-container">
					<Col
						xs={8}
						className="d-flex flex-column justify-content-center align-items-end "
					>
						<h2>Descoperă stagiul de practică care ți se potrivește</h2>
						{/* <p>Aplică la una din cele peste 200 de companii</p> */}
					</Col>
					<Col className="d-flex justify-content-start align-items-center">
						<a
							href="/"
							className="btn btn-outline-light btn-lg register-btn"
							role="button"
							aria-pressed="true"
						>
							Înregistrează-te
						</a>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ContactPage;
