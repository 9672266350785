import { Row, Col } from 'react-bootstrap';
import '../../Common/styles/genericPageStyles/style.css';
import justificare from '../../../assets/images/generic-page-images/justificare.jpg';

const ProjectJustification = () => {
	return (
		<div className="generic-page-container ">
			<Row className="generic-page-background-image">
				<img
					src={justificare}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header">
					<h2>Justificare</h2>
				</div>
			</Row>
			<Row className="generic-page-context-container">
				<Col className="col-10 offset-1  generic-page-context-content">
					<br />
					<br />
					<p>
						Universitatea Tehnică „Gheorghe Asachi”, Facultatea
						Construcții de Mașini și Management Industrial,
						Facultatea de Electronică, Telecomunicații și Tehnologia
						Informației, Facultatea de Știintă și Ingineria
						Materialelor, Facultatea de Mecanică, Facultatea de
						Inginerie Electrică, Energetică și Informatică aplicată,
						ale caror specializări care se regasesc în sectoarele
						economice cu potențial competitiv identificate conform
						SNC și domeniile de specializare inteligentă conform
						SNCDI au contact cu mediul de afaceri din sectoarele de
						afaceri cu potențial competitiv conform SNC, dar piața
						muncii este în continuă schimbare și multe companii noi
						și-au deschis punct de lucru la Iași.
					</p>
					<p>
						Studenții nu cunosc modul de lucru din țări ale Uniunii
						Europene. În lipsa deprinderii unei calificări, tânărul
						absolvent are şanse foarte mici să se angajeze sau
						primeşte un salariu mic care îl demotivează. Prin
						proiect se va stabili o modalitate comună de derulare
						stagiilor de practică şi tutorii de practică vor fi
						instruiţi şi monitorizaţi ca să o aplice. Se
						implementează astfel un set de reguli clare, cu
						obiective specifice şi standarde de calitate unitare. De
						asemenea, prin proiect se vor dezvolta noi parteneriate
						de practică cu angajatori noi pe piața muncii din Iași.
						Proiectul va utiliza noile tehnologii în atragerea
						partenerilor de practică prin dezvoltarea unei aplicatii
						online care va asigura un sistem coordonat de informare
						în ambele sensuri: de la universitate, respectiv
						student, către angajator și invers.
					</p>
					<p>
						Proiectul va dezvolta programe de internship /
						traineeship ca alternativa inovatoare și de completare a
						stagiilor de practică într-un mediu de lucru real.
					</p>
					<p>
						Proiectul va oferi unui număr de 36 de studenti din
						grupul țintă oportunitatea de a realiza stagii de
						practică în tari ale Uniunii Europene unde s-au
						inregistrat progrese vizibile în domeniile lor de
						specializare.
					</p>
					<p>
						Proiectul va avea efecte pozitive pe termen lung pentru
						grupul țintă, atât din punct de vedere cantitativ cât şi
						calitativ.
					</p>
					<p>
						Efect cantitativ pe termen lung:
						<ul className="mt-2">
							<li>
								Sistemul de informare online care aduce
								față-în-față angajatorii și TU Iasi va dezvolta
								și consolida parteneriatul de practică în
								vederea derularii stagiilor de practica pentru
								studenții TU Iasi, într-un mediu de munca real,
							</li>
							<li>
								Serviciile de consiliere şi orientare
								profesionala furnizate celor 321 de studenti vor
								oferi o perspectivă clară grupului țintă în
								privința viitorului lor și pot hotarâ
								continuarea studiilor și dobandirea de noi
								competențe profesionale asigurând astfel
								formarea profesională continuă,
							</li>
							<li>
								Soluția inovativă de dobandire a competențelor
								antreprenoriale și socio-civice prin
								participarea a minim 50 de studenți la programul
								de antreprenoriat și responsabilitate socială
								dezvoltat pe modelul întreprinderilor simulate
								va aduce o nouă perspectivă asupra dezvoltarii
								unei cariere pe cont propriu prin înființarea
								unei firme,
							</li>
							<li>
								Angajarea a 126 persoane din GT (peste 39% din
								total GT) va stimula dezvoltarea socio-economică
								în județul Iași,
							</li>
							<li>
								Parteneriatele transnationale cu angajatorii
								europeni vor fi fructificate și pe viitor prin
								organizarea de stagii de practica fie prin
								atragerea altor finanțări, fie prin suportarea
								cheltuielilor de studenti sau de angajatori;
								aceste parteneriate vor deschide noi
								oportunitati de specializare și angajare iar
								studenții TU Iași, se vor familiariza cu
								cerințele profesionale de pe piețele europene.
							</li>
						</ul>
					</p>
					<p>
						Efect calitativ pe termen lung:
						<ul className="mt-2">
							<li>
								Omogenizarea studenților indiferent de etnie și
								domiciliul urban / rural și creșterea toleranței
								și a colaborării locale între studenți și
								angajatori fără a ține cont de etnie sau
								domiciliul rural / urban;
							</li>
							<li>
								Noi deprinderi de învățare, noi capacități și
								atitudini intelectuale ce-i vor ajuta pe
								studenții din grupul țintă pe întreg parcursul
								vieții, deprinderi cognitive, sociale și
								emoționale necesare aplicării cunoștintelor
								dobândite prin programele de învățare;
							</li>
							<li>
								Dezvoltarea personală a studenților, creșterea
								stimei de sine și încrederea în propriile forțe
								a grupului țintă.
							</li>
						</ul>
					</p>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default ProjectJustification;
