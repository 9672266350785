import { useEffect, useRef, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';

import parse from 'html-react-parser';

import { Link, useNavigate } from 'react-router-dom';

import logoBlue from '../../../assets/images/logo-blue.png';
import home from '../../../assets/images/Dashboard/dashboard-icons/home.png';
import internship from '../../../assets/images/Dashboard/dashboard-icons/internship.png';
import calendar from '../../../assets/images/Dashboard/dashboard-icons/calendar.png';
import student from '../../../assets/images/Dashboard/dashboard-icons/student2.png';
import profile from '../../../assets/images/Dashboard/dashboard-icons/profile.png';
import admin from '../../../assets/images/Dashboard/dashboard-icons/admin.png';
import company from '../../../assets/images/Dashboard/dashboard-icons/company.png';

import '../../Common/styles/sidebarMenuStyles/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../Common/slices/sidebarSlice';
import { AppState } from '../../../store/rootReducer';
import PageLoading from '../../../Components/PageLoading';

interface ActiveLink {
	active: string;
}

const SidebarMenu = () => {
	const dispatch = useDispatch();
	const userProfileDetails = useSelector(
		(state: AppState) => state.sidebarMenu
	);
	const path = window.location.pathname.replace(/\//g, '');
	const defaultActiveLink: string =
		path === 'dashboard'
			? 'home'
			: path === 'dashboardprofil'
			? 'profile'
			: path === 'dashboardcompanii'
			? 'companies'
			: path === 'dashboardstagii'
			? 'internship'
			: path === 'dashboardsesiunea-de-stagii'
			? 'internshipSession'
			: path === 'dashboardstudenti'
			? 'students'
			: path === 'dashboardadmin'
			? 'admins'
			: '';

	const [activeLink, setActiveLink] = useState<ActiveLink>({
		active: defaultActiveLink,
	});
	const navbarRef = useRef<HTMLDivElement>(null);

	const activeLinkStyle = {
		backgroundColor: '#dff1ff',
		borderRadius: '6px',
		padding: '2px',
	};

	const handleActiveLinkClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const activeElement: string = event.currentTarget.id;
		setActiveLink({ active: activeElement });
	};

	const navigate = useNavigate();

	const logoutBtn = () => {
		Cookies.remove('token');
		navigate('/home');
		window.location.reload();
	};

	useEffect(() => {
		dispatch(getProfile());
		window.addEventListener('scroll', () => {
			if (
				document.documentElement.scrollTop > 50 &&
				document.querySelector('.dashboard-navbar') !== null
			) {
				if (navbarRef.current !== null) {
					navbarRef.current.classList.add('dashboard-resize');
				}
			} else if (navbarRef.current !== null) {
				navbarRef.current.classList.remove('dashboard-resize');
			}
		});
	}, []);

	return (
		<div className="dashboard-navbar" ref={navbarRef}>
			{userProfileDetails.pending ? (
				<PageLoading style={{ marginRight: '0' }} />
			) : null}

			<Row className="m-0 h-100">
				<Col className="dashboard-user-info-container d-flex flex-column justify-content-center align-items-center">
					{/* <img src={logoBlue} alt="techjobs-logo" /> */}
					<div className="dashboard-avatar">
						{parse(userProfileDetails.avatar)}
					</div>
					<div className="dashboard-user-info">
						<h6>
							{userProfileDetails.firstName +
								' ' +
								userProfileDetails.lastName}
						</h6>
						<p>{userProfileDetails.userType}</p>
					</div>
					<Button
						variant="outline-primary"
						className="logout-btn"
						onClick={logoutBtn}
					>
						LOGOUT
					</Button>
				</Col>
				<hr className="solid"></hr>
				<div className="dashboard-utilities">
					<Link to="/dashboard">
						<div
							className="dashboard-utilities-style dashboard-home"
							onClick={handleActiveLinkClick}
							style={
								activeLink.active === 'home'
									? activeLinkStyle
									: {}
							}
							id="home"
						>
							<img src={home} alt="home" />

							<h6>Acasa</h6>
						</div>
					</Link>
					<Link to="/dashboard/profil">
						<div
							className="dashboard-utilities-style dashboard-profile"
							onClick={handleActiveLinkClick}
							style={
								activeLink.active === 'profile'
									? activeLinkStyle
									: {}
							}
							id="profile"
						>
							<img src={profile} alt="profile" />

							<h6>Profil</h6>
						</div>
					</Link>
					{userProfileDetails.userType !== 'Student' && (
						<>
							<Link to="/dashboard/companii">
								<div
									className="dashboard-utilities-style dashboard-companies"
									onClick={handleActiveLinkClick}
									style={
										activeLink.active === 'companies'
											? activeLinkStyle
											: {}
									}
									id="companies"
								>
									<img src={company} alt="profile" />

									<h6>Companii</h6>
								</div>
							</Link>
							<Link to="/dashboard/stagii">
								<div
									className="dashboard-utilities-style dashboard-internship"
									onClick={handleActiveLinkClick}
									style={
										activeLink.active === 'internship'
											? activeLinkStyle
											: {}
									}
									id="internship"
								>
									<img
										src={internship}
										alt="internship-logo"
									/>

									<h6>Stagii</h6>
								</div>
							</Link>
							<Link to="/dashboard/sesiunea-de-stagii">
								<div
									className="dashboard-utilities-style dashboard-internship"
									onClick={handleActiveLinkClick}
									style={
										activeLink.active ===
										'internshipSession'
											? activeLinkStyle
											: {}
									}
									id="internshipSession"
								>
									<img
										src={calendar}
										alt="internship-logo-session"
									/>

									<h6>Sesiunea de stagii</h6>
								</div>
							</Link>
							<Link to="/dashboard/admin">
								<div
									className="dashboard-utilities-style dashboard-admins"
									onClick={handleActiveLinkClick}
									style={
										activeLink.active === 'admins'
											? activeLinkStyle
											: {}
									}
									id="admins"
								>
									<img src={admin} alt="admin-logo" />
									<h6>Administratori</h6>
								</div>
							</Link>
							<Link to="/dashboard/studenti">
								<div
									className="dashboard-utilities-style dashboard-students"
									onClick={handleActiveLinkClick}
									style={
										activeLink.active === 'students'
											? activeLinkStyle
											: {}
									}
									id="students"
								>
									<img src={student} alt="student-logo" />
									<h6>Studenti</h6>
								</div>
							</Link>
						</>
					)}
				</div>
			</Row>
		</div>
	);
};

export default SidebarMenu;
