import { ListGroup, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import documenteUtile from '../../../assets/images/generic-page-images/documente-utile.jpg';
import '../../Common/styles/genericPageStyles/style.css';
import './style/style.css';

const UsefulDocumentsSelectionMethodology = () => {
	return (
		<div className="generic-page-container">
			<Row className="generic-page-background-image">
				<img
					src={documenteUtile}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header ">
					<h2 className="useful-header">
						{' '}
						Documente utile - Metodologie selectie Grup Tinta
					</h2>
				</div>
			</Row>

			<Row className="generic-page-context-container useful-documents-container">
				<Col className="col-10 offset-1 mt-5 generic-page-context-content useful-documents-content">
					<br />

					<ListGroup variant="flush">
						<ListGroup.Item>
							<Link
								to="/static/files/2022/Metodologie_selectie_Grup_Tinta/Metodologie_selectie_Grup_Tinta.pdf"
								target="_blank"
							>
								📁 Metodologie selectie Grup Tinta
							</Link>
						</ListGroup.Item>
						<ListGroup.Item>
							<Link
								to="/static/files/2022/Metodologie_selectie_Grup_Tinta/Anexe_Metodologie_selectie_Grup_Tinta.pdf"
								target="_blank"
							>
								📁 Anexe Metodologie selectie Grup Tinta
							</Link>
						</ListGroup.Item>
					</ListGroup>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default UsefulDocumentsSelectionMethodology;
