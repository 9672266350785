import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_URLS } from '../../../../helpers/http';
import {
	getAdmins,
	getAdminsSuccess,
	getAdminsError,
	deleteAdminSuccess,
	deleteAdminError,
	deleteAdmin,
	postAdminError,
	postAdmin,
	postAdminSuccess,
} from '../slices/adminsSlice';
import { AdminsState, PostAdmin } from '../types';

function* getAdminsSaga() {
	try {
		const response: AdminsState = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.ADMIN}`
		);
		yield put(getAdminsSuccess({ admins: response }));
	} catch (err: any) {
		yield put(getAdminsError({ err: err.response }));
	}
}

function* postAdminSaga(action: PayloadAction<PostAdmin>) {
	const { firstName, lastName, email, password } = action.payload;
	try {
		const response: AdminsState = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.ADMIN}`,
			{
				firstName,
				lastName,
				email,
				password,
			}
		);
		yield put(postAdminSuccess({ response }));
	} catch (err: any) {
		yield put(postAdminError({ err: err.response }));
	}
}

function* deleteAdminSaga(action: PayloadAction<string>) {
	const id = action.payload;
	try {
		const response: AdminsState = yield call(
			axios.delete,
			`${API_URLS.BASE_URL}${API_URLS.ADMIN}/${id}`
		);
		yield put(deleteAdminSuccess({ response }));
	} catch (err: any) {
		yield put(deleteAdminError({ err: err.response }));
	}
}

function* adminsSaga() {
	yield all([
		takeLatest(getAdmins, getAdminsSaga),
		takeLatest(postAdmin, postAdminSaga),
		takeLatest(deleteAdmin, deleteAdminSaga),
	]);
}

export default adminsSaga;
