import './style/style.css';

interface IProps {
	style?: any;
}

const PageLoading: React.FC<IProps> = (children: IProps | null) => {
	return (
		<div className="page-loader">
			<span
				className="loader"
				style={children !== null && children.style}
			>
				<span className="loader-inner"></span>
			</span>
		</div>
	);
};

export default PageLoading;
