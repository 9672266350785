import { createSlice } from '@reduxjs/toolkit';
import { UserState } from '../types';

const initialState: UserState = {
	firstName: '',
	lastName: '',
	email: '',
	userType: '',
	userFiles: [],
	avatar: '',
	pending: true,
	err: '',
};

export const sidebarSlice = createSlice({
	name: 'sidebarSlice',
	initialState,
	reducers: {
		getProfile: (state) => {
			return { ...state };
		},
		getProfileSuccess: (state, action) => {
			return {
				...state,
				...action.payload.profile.data,
				avatar: action.payload.avatar.data,
				pending: false,
			};
		},
		getProfileError: (state, action) => {
			return {
				...state,
				err: action.payload.err.statusText,
			};
		},
		getProfileFile: (state, action) => {
			return {
				...state,
			};
		},
		getProfileFileSuccess: (state, action) => {
			return {
				...state,
				file: action.payload.response.data,
			};
		},
		getProfileFileError: (state, action) => {
			return {
				...state,
				err: action.payload.err.statusText,
			};
		},
	},
});

export const {
	getProfile,
	getProfileSuccess,
	getProfileError,
	getProfileFile,
	getProfileFileError,
	getProfileFileSuccess,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
