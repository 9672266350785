import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	postLoginDetails,
	postLoginError,
	postLoginSuccess,
} from '../slices/userSlice';
import { API_URLS } from '../../../helpers/http';
import axios, { AxiosResponse } from 'axios';
import { UserInitialState } from '../types';

interface User {
	payload: {
		username: string;
		password: string;
	};
}

function* postLoginSaga(action: User) {
	const { username, password } = action.payload;
	try {
		const response: AxiosResponse<UserInitialState> = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.LOGIN}`,
			{
				password,
				username,
			}
		);
		yield put(
			postLoginSuccess({
				...response.data,
			})
		);
	} catch (error: any) {
		yield put(
			postLoginError({
				error,
			})
		);
	}
}

export function* loginSaga() {
	yield takeLatest(postLoginDetails, postLoginSaga);
}
