import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardCompanies } from '../types';

const initialState: DashboardCompanies = {
	companies: [],
	pending: true,
	postPending: true,
	deletePending: true,
	err: '',
};

export const dashboardCompaniesSlice = createSlice({
	name: 'dashboarCompanies',
	initialState,
	reducers: {
		getDashboardCompanies: (state) => {
			return {
				...state,
				deletePending: true,
			};
		},
		getDashboardCompaniesSuccess: (state, action) => {
			return {
				...state,
				companies: action.payload.companies.data,
				pending: false,
			};
		},
		getDashboardCompaniesFailure: (state, action: any) => {
			return {
				...state,
				pending: false,
				err: action.payload,
			};
		},
		postDashboardCompany: (state, action) => {
			return {
				...state,
				pending: true,
			};
		},
		postDashboardCompanySuccess: (state, action) => {
			return {
				...state,
				pending: false,
				postPending: false,
			};
		},
		postDashboardCompanyError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload.err.statusText,
			};
		},
		deleteDashboardCompany: (state, action) => {
			return {
				...state,
				pending: true,
			};
		},
		deleteDashboardCompanySuccess: (state, action) => {
			return {
				...state,
				pending: false,
				deletePending: false,
			};
		},
		deleteDashboardCompanyError: (state, action) => {
			return {
				...state,
				pending: false,
				deletePending: false,
				err: action.payload.err.statusText,
			};
		},
	},
});

export const {
	getDashboardCompanies,
	getDashboardCompaniesSuccess,
	getDashboardCompaniesFailure,
	postDashboardCompany,
	postDashboardCompanySuccess,
	postDashboardCompanyError,
	deleteDashboardCompany,
	deleteDashboardCompanySuccess,
	deleteDashboardCompanyError,
} = dashboardCompaniesSlice.actions;

export default dashboardCompaniesSlice.reducer;
