import { useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../../store/rootReducer';
import {
	addStudentToInternship,
	deleteApplicant,
	deleteStudentToInternship,
	getInternship,
	getInternshipAcceptedStudents,
	getInternshipApplicants,
	putInternship,
} from '../../Common/slices/internshipSlice';
import PageLoading from '../../../Components/PageLoading';

import { useState } from 'react';

import { Form, Accordion, ListGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { DateInterface } from '../../Common/types';

import moment from 'moment';

import './style/style.css';

import calendar from '../../../assets/images/Dashboard/dashboard-icons/calendar.png';

import infoLogo from '../../../assets/images/Dashboard/dashboard-icons/info.png';
import { getDashboardCompanies } from '../DashboardCompanies/slices/dashboardCompaniesSlice';
import { getInternSessions } from '../InternshipSession/slices/internSessionSlice';
import { getStudents } from '../Students/slices/studentsSlice';

const DashboardInternship = () => {
	const params = useParams();
	const dispatch = useDispatch();

	const internship = useSelector((state: AppState) => state.internshipId);
	const [showEditInternship, setShowEditInternship] = useState(false);
	const [date, setDate] = useState<DateInterface>(() => {
		return {
			startDate: internship.startDate
				? new Date(internship.startDate)
				: new Date(),
			endDate: internship.endDate
				? new Date(internship.endDate)
				: new Date(),
			edited: false,
		};
	});
	const [showAlert, setShowAlert] = useState(false);
	const initialValues = {
		name: '',
		description: '',
		startDate: '',
		endDate: '',
		edited: false,
	};
	const [inputValues, setInputValues] = useState(initialValues);
	const [defaultValue, setDefaultValue] = useState({
		companyTitle: 0,
		internshipSession: 0,
	});
	const [internshipDate, setInternshipDate] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});
	const companies = useSelector(
		(state: AppState) => state.dashboardCompanies
	);
	const internSession = useSelector(
		(state: AppState) => state.internSessions
	);
	const allStudents = useSelector(
		(state: AppState) => state.dashboardAdminStudents
	);
	const company = useRef<HTMLSelectElement>(null);
	const session = useRef<HTMLSelectElement>(null);
	const sessionStage = useRef<HTMLSelectElement>(null);
	const internshipRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		dispatch(getInternship({ id: params.id }));
		dispatch(getDashboardCompanies());
		dispatch(getInternSessions());
		dispatch(getStudents());
		dispatch(getInternshipApplicants({ id: params.id }));
		dispatch(getInternshipAcceptedStudents({ id: params.id }));
	}, []);

	useEffect(() => {
		internship.internshipSessionId &&
			setDefaultValue((prevState) => {
				return {
					...prevState,
					internshipSession: internSession.sessions
						.filter((el: any) => {
							if (
								internship.internshipSessionId ===
								el.internshipSessionId
							)
								return el.internshipSessionId;
						})
						.map((el: any) => {
							return el.title;
						})[0],
				};
			});
		internship.companyId &&
			setDefaultValue((prevState) => {
				return {
					...prevState,
					companyTitle: companies.companies
						.filter((el: any) => {
							if (el.companyId === internship.companyId)
								return el.name;
						})
						.map((el: any) => {
							return el.name;
						})[0],
				};
			});

		internship.startDate !== '' &&
			setDate({
				...date,
				startDate: new Date(internship.startDate),
				endDate: new Date(internship.endDate),
			});
		setInputValues({
			...initialValues,
			name: internship.name,
			description: internship.description,
		});
	}, [internship]);

	useEffect(() => {
		if (internship.editInternshipPending === true) {
			setInputValues({
				...inputValues,
				edited: false,
			});
			setDate({
				...date,
				edited: false,
			});
			dispatch(getInternship({ id: params.id }));
		}
	}, [internship.editInternshipPending]);

	useEffect(() => {
		!internship.addStudentPending &&
			dispatch(getInternshipAcceptedStudents({ id: params.id }));
	}, [internship.addStudentPending]);

	useEffect(() => {
		!internship.deleteStudentPending &&
			dispatch(getInternshipAcceptedStudents({ id: params.id }));
	}, [internship.deleteStudentPending]);
	useEffect(() => {
		!internship.deleteApplicantPending &&
			dispatch(getInternshipApplicants({ id: params.id }));
	}, [internship.deleteApplicantPending]);

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setInputValues({
			...inputValues,
			startDate: date.startDate,
			endDate: date.endDate,
			[name]: value,
			edited: true,
		});
	};

	const submitChanges = () => {
		inputValues.startDate = moment(date.startDate)
			.toISOString()
			.split('.')[0];
		inputValues.endDate = moment(date.endDate).toISOString().split('.')[0];
		if (inputValues.edited || date.edited) {
			if (
				company.current !== null &&
				company.current.value !== '' &&
				session.current !== null &&
				session.current.value !== '' &&
				sessionStage.current !== null &&
				sessionStage.current.value !== ''
			) {
				dispatch(
					putInternship({
						...inputValues,
						internshipId: internship.internshipId,
						companyId: companies.companies
							.filter((el: any) => {
								if (el.name === company!.current!.value)
									return el.companyId;
							})
							.map((el: any) => {
								return el.companyId;
							})[0],
						internshipStatusId: parseInt(
							sessionStage.current.value
						),
						internshipSessionId: internSession.sessions
							.filter((el: any) => {
								if (session!.current!.value === el.title)
									return el.internshipSessionId;
							})
							.map((el: any) => {
								return el.internshipSessionId;
							})[0],
					})
				);
				setShowEditInternship(false);
			}
		} else {
			if (internshipRef.current !== null) {
				internshipRef.current.scrollTop = 0;
			}
			setShowAlert(true);
		}
	};

	const handleAddStudent = (studentId: string) => {
		dispatch(addStudentToInternship({ id: params.id, studentId }));
	};

	const handleDeleteStudent = (studentId: string) => {
		dispatch(deleteStudentToInternship({ id: params.id, studentId }));
	};

	const handleDeleteApplicant = (studentId: string) => {
		dispatch(deleteApplicant({ id: params.id, studentId }));
	};

	const filterStudentsDuplicate = (
		applicantId: number,
		typeOfStudent: string
	) => {
		switch (typeOfStudent) {
			case 'applicantS':
				return internship.acceptedStudents.students.some(
					(acceptedS: any) => {
						return acceptedS.studentId === applicantId;
					}
				);
			case 'allS':
				return internship.acceptedStudents.students.some(
					(acceptedS: any) => {
						return acceptedS.studentId === applicantId;
					}
				);
			default:
				break;
		}
	};

	return (
		<>
			{internship.pending ? <PageLoading /> : null}
			<div className="internship-id-container">
				<div className="internship-id-content" ref={internshipRef}>
					<div className="internship-status">
						<p
							className={`internship-info${internship.internshipStatusId}`}
						>
							<img src={infoLogo} alt="info" />
							{internship.internshipStatusId === 1
								? 'Ascuns'
								: internship.internshipStatusId === 2
								? 'Deschis pentru candidatura'
								: internship.internshipStatusId
								? 'Stagiul nu mai accepta candidati'
								: null}
						</p>
					</div>
					{!showEditInternship && (
						<>
							{internship.error !== '' && (
								<div
									className="alert alert-danger"
									role="alert"
								>
									{internship.error}
								</div>
							)}

							<h1>{internship.name}</h1>
							<p>{internship.description}</p>

							<div className="internship-id-details">
								<img src={calendar} alt="calendar" />
								<h6>
									Start internship:
									{new Date(
										internship.startDate
									).toLocaleDateString()}
								</h6>
							</div>
							<div className="internship-id-details">
								<img src={calendar} alt="calendar" />
								<h6>
									Sfarsit internship:
									{new Date(
										internship.endDate
									).toLocaleDateString()}
								</h6>
							</div>
							<Accordion className="mb-3">
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										Studenti acceptati
									</Accordion.Header>
									<Accordion.Body>
										<ListGroup>
											{internship.acceptedStudents
												.students.length > 0
												? internship.acceptedStudents.students.map(
														(student: any) => {
															return (
																<ListGroup.Item
																	key={
																		student.studentId
																	}
																>
																	<div className="d-flex justify-content-between align-items-center">
																		{student.lastName +
																			' ' +
																			student.firstName}
																		<button
																			className="btn btn-danger"
																			onClick={() => {
																				handleDeleteStudent(
																					student.studentId
																				);
																			}}
																		>
																			Sterge
																		</button>
																	</div>
																</ListGroup.Item>
															);
														}
												  )
												: 0}
										</ListGroup>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>
										Studenti care au aplicat
									</Accordion.Header>
									<Accordion.Body>
										<ListGroup>
											{internship.applicants.length > 0
												? internship.applicants.map(
														(student: any) => {
															if (
																student.student
															) {
																return (
																	<ListGroup.Item
																		key={
																			student
																				.student
																				.studentId
																		}
																	>
																		<div className="d-flex justify-content-between align-items-center">
																			<p id="student-name">
																				{student
																					.student
																					.lastName +
																					' ' +
																					student
																						.student
																						.firstName}
																			</p>
																			<div>
																				<button
																					className="btn btn-success mx-2"
																					onClick={() => {
																						handleAddStudent(
																							student
																								.student
																								.studentId
																						);
																					}}
																					disabled={
																						filterStudentsDuplicate(
																							student
																								.student
																								.studentId,
																							'applicantS'
																						) ===
																						true
																							? true
																							: false
																					}
																				>
																					Accepta
																				</button>
																				<button
																					className="btn btn-danger"
																					onClick={() => {
																						handleDeleteApplicant(
																							student
																								.student
																								.studentId
																						);
																					}}
																				>
																					Refuza
																				</button>
																			</div>
																		</div>
																	</ListGroup.Item>
																);
															}
														}
												  )
												: 0}
										</ListGroup>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>
										Toti studentii
									</Accordion.Header>
									<Accordion.Body>
										<ListGroup>
											{allStudents.students.length > 0 &&
												allStudents.students.map(
													(student: any) => {
														return (
															<ListGroup.Item
																key={
																	student.studentId
																}
															>
																<div className="d-flex justify-content-between align-items-center">
																	{student.lastName +
																		' ' +
																		student.firstName}
																	<button
																		className="btn btn-success"
																		onClick={() => {
																			handleAddStudent(
																				student.studentId
																			);
																		}}
																		disabled={
																			filterStudentsDuplicate(
																				student.studentId,
																				'allS'
																			) ===
																			true
																				? true
																				: false
																		}
																	>
																		Adauga
																	</button>
																</div>
															</ListGroup.Item>
														);
													}
												)}
										</ListGroup>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setShowEditInternship(true);
								}}
							>
								Editeaza stagiu
							</button>
						</>
					)}

					{showEditInternship && (
						<>
							<Form className="edit-internship-id mb-4">
								{showAlert && (
									<div
										className="alert alert-danger"
										role="alert"
									>
										Va rugam modificati detaliile stagiului
									</div>
								)}
								<button
									type="button"
									className="btn-close"
									aria-label="Close"
									onClick={() => {
										setShowEditInternship(false);
									}}
								></button>
								<Form.Group className="mb-3">
									<Form.Label>Nume stagiu</Form.Label>
									<Form.Control
										type="text"
										name="name"
										onChange={handleInputChange}
										defaultValue={internship.name}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Descriere</Form.Label>
									<Form.Control
										type="text"
										name="description"
										onChange={handleInputChange}
										defaultValue={internship.description}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Alege Compania</Form.Label>
									<Form.Select
										aria-label="Default select example"
										ref={company}
										onChange={handleInputChange}
										defaultValue={defaultValue.companyTitle}
									>
										{companies.companies.map((el: any) => {
											return (
												<option key={el.companyId}>
													{el.name}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>
										Perioada de internship
									</Form.Label>
									<Form.Select
										aria-label="Default select example"
										ref={session}
										onChange={handleInputChange}
										defaultValue={
											defaultValue.internshipSession
										}
									>
										{internSession.sessions.map(
											(el: any) => {
												return (
													<option
														key={
															el.internshipSessionId
														}
													>
														{el.title}
													</option>
												);
											}
										)}
									</Form.Select>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Stadiul stagiului</Form.Label>
									<Form.Select
										aria-label="Default select example"
										ref={sessionStage}
										onChange={handleInputChange}
										defaultValue={
											internship.internshipStatusId === 1
												? internship.internshipStatusId
												: internship.internshipStatusId ===
												  2
												? internship.internshipStatusId
												: internship.internshipStatusId ===
												  3
												? internship.internshipStatusId
												: ''
										}
									>
										<option value="1">Ascuns</option>

										<option value="2">
											Deschis pentru candidatura
										</option>

										<option value="3">
											Acest stagiu nu mai accepta
											candidaturi
										</option>
									</Form.Select>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Start Stagiu</Form.Label>
									<DatePicker
										className="form-control"
										selected={date.startDate}
										onChange={(selectedDate: any) => {
											setDate((state) => {
												return {
													...state,
													startDate: selectedDate,
													edited: true,
												};
											});
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Sfarsit Stagiu</Form.Label>
									<DatePicker
										className="form-control"
										selected={date.endDate}
										onChange={(selectedDate: any) => {
											setDate((state) => {
												return {
													...state,
													endDate: selectedDate,
													edited: true,
												};
											});
										}}
									/>
								</Form.Group>
							</Form>
							<button
								type="button"
								className="btn btn-primary"
								onClick={submitChanges}
							>
								Salveaza modificarile
							</button>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default DashboardInternship;
