import { Row, Col } from 'react-bootstrap';
import './style/style.css';
import obiectiveSpecifice from '../../../assets/images/generic-page-images/obiective-specifice.jpg';

const ProjectSpecificObjectives = () => {
	return (
		<div className="generic-page-container">
			<Row className="generic-page-background-image">
				<img
					src={obiectiveSpecifice}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header">
					<h2>Obiective specifice</h2>
				</div>
			</Row>
			<Row className="generic-page-context-container">
				<Col className="col-10 offset-1  generic-page-context-content">
					<br />
					<br />
					<p>
						Obiectivele specifice ale proiectului{' '}
						<b>
							„TechJobs - Stagii de practicǎ pentru studenții
							Universitǎții Tehnice "Gheorghe Asachi" din Iași
							ȋntr-un mediu de lucru real” cod MySmis 133398{' '}
						</b>{' '}
						- nr. contract 10574/23.09.2020 sunt:
					</p>
					<p>
						1. Creşterea cu 321 a numărului studenţilor
						Universității Gheorghe Asachi din Iași sprijiniţi pentru
						tranziţia de la şcoală la piaţa muncii prin participarea
						la stagii de practică la un potenţial loc de muncă cu
						respectarea unor proceduri clare şi a unor standarde de
						calitate definite cu efect în piaţa muncii prin
						creşterea cu 129 a numărului absolvenţilor Universității
						Gheoghe Asachi din Iași care îşi găsesc un loc de muncă
						urmare a accesului la activităţi de învăţare la un
						potenţial loc de muncă / cercetare / inovare, cu accent
						pe sectoarele ec cu potential competitiv identificate
						conform SNC şi domeniilor de specializare inteligentă
						conform SNCDI, participarea a 36 studenţi din GT la
						stagii de practică în ţări ale UE. <br />
						<br />
						Prin activitățile proiectului se va interveni asupra
						îmbunătățirii ratei de absolvire până la 90% mai ales în
						cazul GT. Proiectul își propune să orienteze studenții
						TU Iasi, spre sectoarele economice cu potențial
						competitiv prin atragerea angajatorilor specifici în
						parteneriate de practică.
					</p>
					<p>
						2. OS2 Creşterea utilizării noilor tehnologii în
						facilitarea tranziţiei de la şcoală la piaţa muncii
						pentru 321 de studenţi GT prin dezvoltarea unei
						aplicaţii de conexare a Universitatii Gheorghe Asachi
						din Iași şi studenţii săi cu angajatorii. Dezvoltarea
						unui sistem de informare între angajatori și TU Iasi va
						facilita adaptarea cursurilor la cerințele reale ale
						pietei (ex. suplimentarea locurilor la anumite
						specializari din domenii de specializare inteligenta cf
						SNCDI). Proiectul va sprijini încheierii unor
						parteneriate sustenabile cu sectorul privat pentru
						facilitarea tranziţiei de la educaţie la un loc de muncă
						prin instituirea unui sistem funcţional de stagii de
						practică la un potenţial angajator, adresate studenţilor
						din instituţiilor de învăţământ superior (ISCED 5-7).
						Acest sistem va avea componenta online dezvoltata prin
						SA2.1.
					</p>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default ProjectSpecificObjectives;
