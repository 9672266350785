import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useImagePreloader from '../../../helpers/useImagePreloader';

import { AppState } from '../../../store/rootReducer';
import { deleteStudent, getStudents } from './slices/studentsSlice';

import studentsBgImg from '../../../assets/images/Dashboard/bg/students.jpg';

import { Form } from 'react-bootstrap';

import studentImg from '../../../assets/images/Dashboard/dashboard-icons/students.png';

import './style/style.css';
import PageLoading from '../../../Components/PageLoading';

const Admin = () => {
	const preloadSrcList: string[] = [studentsBgImg];
	const { imagesPreloaded } = useImagePreloader(preloadSrcList);
	const [showPopup, setShowPopup] = useState({ show: false, studentId: '' });
	const showEmail = useRef<HTMLSpanElement>(null);
	// const [studentsLimit, setStudentsLimit] = useState(30);
	const dispatch = useDispatch();
	const students = useSelector(
		(state: AppState) => state.dashboardAdminStudents
	);

	// const loadMoreStudents = () => {
	// 	setStudentsLimit((prevState) => {
	// 		if (prevState < students.students.length) {
	// 			return prevState + 30;
	// 		} else {
	// 			return prevState + students.students.length - prevState;
	// 		}
	// 	});
	// };

	const handleDeleteStudent = () => {
		dispatch(deleteStudent(showPopup.studentId));
		setShowPopup({
			show: false,
			studentId: '',
		});
	};

	const showCopyText = (e: any, email: string) => {
		e.target.lastChild.innerHTML = 'Adresa salvata!';
		setTimeout(() => {
			e.target.lastChild.innerHTML = email;
		}, 2000);
	};

	useEffect(() => {
		dispatch(getStudents());
	}, []);

	useEffect(() => {
		students.deletePending === false && dispatch(getStudents());
	}, [students.deletePending]);
	return (
		<div className="students-list">
			{students.pending && <PageLoading />}
			{showPopup.show && (
				<div className="confirmation-popup">
					<div className="confirmation-container">
						<h2>Sunteti sigur ca vreti sa stergeti studentul?</h2>
						<div className="confirm-buttons">
							<button
								className="btn btn-primary mr-5"
								onClick={() => handleDeleteStudent()}
							>
								Da
							</button>
							<button
								className="btn btn-danger"
								onClick={() =>
									setShowPopup({
										show: false,
										studentId: '',
									})
								}
							>
								Nu
							</button>
						</div>
					</div>
				</div>
			)}
			<div className="students-background-image"></div>
			{!imagesPreloaded && <PageLoading />}
			<div className="students-container">
				{students.students.length > 0 &&
					students.students.map((student: any) => {
						return (
							<div
								className="students-content-container"
								key={student.studentId}
							>
								<div className="students-logo">
									<img src={studentImg} alt="students-logo" />
								</div>
								<div
									className="students-content"
									key={student.studentId}
								>
									<Form.Label htmlFor="lastName">
										Nume
									</Form.Label>
									<h4 id="lastName">
										{student.lastName
											? student.lastName
											: '-'}
									</h4>
									<Form.Label htmlFor="firstName">
										Prenume
									</Form.Label>
									<h4 id="firstName">
										{student.firstName
											? student.firstName
											: '-'}
									</h4>

									<Form.Label htmlFor="faculty">
										Facultate
									</Form.Label>
									<h4 id="email">
										{student.faculty
											? student.faculty
											: '-'}
									</h4>
									<Form.Label htmlFor="study-year">
										Anul de studiu
									</Form.Label>
									<h4 id="study-year">
										{student.studyYear
											? student.studyYear
											: '-'}
									</h4>
									<Form.Label htmlFor="group-number">
										Numarul de grupa
									</Form.Label>

									<h4 id="group-number">
										{student.groupNumber}
									</h4>

									<div
										className="student-email-btn"
										onClick={(e) => {
											navigator.clipboard.writeText(
												student.email
											);
											showCopyText(e, student.email);
										}}
									>
										<span>Adresa email</span>
										<span className="student-email">
											{student.email}
										</span>
									</div>
									<div
										className="delete-student-btn"
										onClick={() =>
											setShowPopup({
												show: true,
												studentId: student.studentId,
											})
										}
									>
										Sterge student
									</div>
								</div>
							</div>
						);
					})}
			</div>
			{/* <div className="load-more-students">
				<button
					className="btn btn-primary"
					onClick={() => loadMoreStudents()}
				>
					Incarca mai multi studenti
				</button>
			</div> */}
		</div>
	);
};

export default Admin;
