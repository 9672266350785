import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_URLS } from '../../../../helpers/http';
import { AdminsState } from '../../Admin/types';

import {
	getAdmin,
	getAdminError,
	getAdminSuccess,
	putAdmin,
	putAdminSuccess,
	putAdminError,
} from '../slices/dashboardAdminSlice';
import { DashboardAdmin } from '../types';

function* getDashboardAdminSaga(action: PayloadAction<string>) {
	const id = action.payload;
	try {
		const response: AdminsState = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.ADMIN}/${id}`
		);
		yield put(getAdminSuccess({ response }));
	} catch (err: any) {
		yield put(getAdminError({ err: err.response }));
	}
}

function* putDashboardAdminSaga(action: PayloadAction<DashboardAdmin>) {
	const { firstName, lastName, email, id, password } = action.payload;
	try {
		const response: DashboardAdmin = yield call(
			axios.put,
			`${API_URLS.BASE_URL}${API_URLS.ADMIN}/${id}`,
			{
				firstName,
				lastName,
				email,
				password: password !== undefined ? password : '',
			}
		);
		yield put(putAdminSuccess({ response }));
	} catch (err: any) {
		yield put(putAdminError(err));
	}
}

function* dashboardAdminSaga() {
	yield all([
		takeLatest(getAdmin, getDashboardAdminSaga),
		takeLatest(putAdmin, putDashboardAdminSaga),
	]);
}

export default dashboardAdminSaga;
