import './style/style.css';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import PressDataType from './types';

const PressRelease = ({ data }: { data: PressDataType }) => {
	return (
		<div className="press-container mb-5">
			<div className="press-header-container">
				<div className="press-header-bg"></div>
				<div className="press-header-title">
					<h1>{data.title}</h1>
				</div>
			</div>

			{data.documents.map((document: any, index: number) => {
				return (
					<div
						className="press-document col-lg-8 text-center text-dark no-scroll"
						key={index}
					>
						<TransformWrapper
							wheel={{
								disabled: true,
							}}
						>
							{({ zoomIn, zoomOut, ...rest }) => (
								<>
									<TransformComponent key={index}>
										<img
											src={document}
											style={{ width: '100%' }}
										/>
									</TransformComponent>

									<div className="col-lg-12 text-center mb-3 mt-4">
										<button
											className="btn btn-primary mr-4 zoom-in"
											onClick={() => zoomIn()}
										>
											Zoom In
										</button>
										<button
											className="btn btn-secondary"
											onClick={() => zoomOut()}
										>
											Zoom Out
										</button>
									</div>
								</>
							)}
						</TransformWrapper>
					</div>
				);
			})}
			{data.links.length > 0 && (
				<div className="col-lg-8 offset-1  text-left mt-3">
					<p>Documente atasate</p>
				</div>
			)}
			<div className="col-lg-8 offset-1  text-left">
				{data.links.map((link: any, index: number) => {
					return (
						<div key={index}>
							<a href={link} target="_blank">
								{window.location.origin.toString()}
								{link}
							</a>{' '}
							<br />
						</div>
					);
				})}
			</div>
			{data.pressHtml && (
			<div className="col-lg-8 offset-1  text-left mt-3" dangerouslySetInnerHTML={{__html:data.pressHtml}} >
			
			</div>
				
			)}
		</div>
	);
};

export default PressRelease;
