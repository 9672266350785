import { useEffect, useState } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import studentsFileBackground from '../../../assets/images/Dashboard/bg/students-file.jpg';
import { AppState } from '../../../store/rootReducer';
import { getProfileFile } from '../../Common/slices/sidebarSlice';

import '../../Common/styles/genericPageStyles/style.css';
import './style/style.css';

const StudentFiles = () => {
	const userFiles = useSelector((state: AppState) => state.sidebarMenu);
	const dispatch = useDispatch();
	const [fileName, setFileName] = useState('');

	const getStudentFiles = (filePath: string, fileName: string) => {
		dispatch(getProfileFile(filePath));
		setFileName(fileName);
	};

	useEffect(() => {
		if (userFiles.file) {
			const blob = new Blob([userFiles.file], {
				type: 'application/pdf',
			});
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `${fileName}.pdf`;
			link.click();
		}
	}, [userFiles.file]);

	return (
		<div className="generic-page-container user-files-container">
			<div className="user-files-header">
				<h1>Fisierele mele</h1>
			</div>
			<img
				src={studentsFileBackground}
				alt="dashboard-companies"
				id="dashboard-company-img"
			/>
			<Row className="generic-page-context-container useful-documents-container">
				<Col className="col-10 offset-1 mt-5 generic-page-context-content useful-documents-content">
					<ListGroup variant="flush">
						{userFiles.userFiles?.length > 0 &&
							userFiles.userFiles.map((file: any) => {
								return (
									<ListGroup.Item key={file.urlFileName}>
										<h5
											onClick={() =>
												getStudentFiles(
													file.urlFileName,
													file.fileName
												)
											}
										>
											📁 {file.fileName}
										</h5>
									</ListGroup.Item>
								);
							})}
					</ListGroup>
				</Col>
			</Row>
		</div>
	);
};

export default StudentFiles;
