import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/rootReducer';
import useImagePreloader from '../../../helpers/useImagePreloader';

import lock from '../../../assets/images/Dashboard/dashboard-icons/lock.png';
import lockOpen from '../../../assets/images/Dashboard/dashboard-icons/lock-open.png';

import parse from 'html-react-parser';

import './style/style.css';
import { Form, Button, Accordion } from 'react-bootstrap';
import { checkPassword, postPassword } from './slices/profileSlice';
import PageLoading from '../../../Components/PageLoading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import profileBackgroundImage from '../../../assets/images/Dashboard/bg/profil.jpg';

const Profile = () => {
	const preloadSrcList: string[] = [profileBackgroundImage];
	const { imagesPreloaded } = useImagePreloader(preloadSrcList);
	const dispatch = useDispatch();
	const passwordRef = useRef<HTMLInputElement>(null);
	const confirmPasswordRef = useRef<HTMLInputElement>(null);
	const checkPasswordInfo = useSelector((state: AppState) => state.profile);
	const [confirmPassword, setConfirmPassword] = useState({
		errMsg: '',
		confirmPassword: 'no',
	});
	const userProfileDetails = useSelector(
		(state: AppState) => state.sidebarMenu
	);
	const [showChangePass, setShowChangePass] = useState(false);
	const [showPassword, setShowPassword] = useState('password');
	const show = (
		<FontAwesomeIcon icon={faEyeSlash} className="eye eye-closed" />
	);
	const hide = <FontAwesomeIcon icon={faEye} className="eye eye-open" />;
	const changeInputType = () => {
		showPassword === 'password'
			? setShowPassword('text')
			: setShowPassword('password');
	};
	const handleChangePassword = () => {
		if (
			passwordRef.current !== null &&
			passwordRef.current.value !== '' &&
			confirmPasswordRef.current !== null &&
			confirmPasswordRef.current.value !== ''
		) {
			if (
				passwordRef.current.value !== confirmPasswordRef.current.value
			) {
				setConfirmPassword((prevState) => {
					return {
						...prevState,
						errMsg: 'Cele doua parole nu se potrivesc',
					};
				});
			} else if (
				passwordRef.current.value === confirmPasswordRef.current.value
			) {
				setConfirmPassword((prevState) => {
					return { ...prevState, errMsg: '' };
				});
				dispatch(
					checkPassword(
						passwordRef.current !== null &&
							passwordRef.current.value
					)
				);
			}
		}

		if (confirmPassword.confirmPassword === 'yes') {
			dispatch(
				postPassword(
					passwordRef.current !== null && passwordRef.current.value
				)
			);
		}
	};

	useEffect(() => {
		if (checkPasswordInfo.err !== '') {
			setConfirmPassword((prevState) => {
				return {
					...prevState,
					errMsg: checkPasswordInfo.err,
				};
			});
		}

		if (checkPasswordInfo.err === '') {
			setConfirmPassword((prevState) => {
				return {
					...prevState,
					errMsg: '',
				};
			});
		}

		if (checkPasswordInfo.infoText === 'OK') {
			setConfirmPassword((prevState) => {
				return {
					...prevState,
					errMsg: '',
					confirmPassword: 'yes',
				};
			});
		}
	}, [checkPasswordInfo]);

	useEffect(() => {
		confirmPassword.confirmPassword === 'yes' && handleChangePassword();
	}, [confirmPassword.confirmPassword]);

	return (
		<>
			<div className="profile-container">
				<div className="profile-background-img"></div>
				{!imagesPreloaded ? (
					<PageLoading />
				) : userProfileDetails.pending ? (
					<PageLoading />
				) : null}
				<div className="section-title">
					<h1>Profil</h1>
				</div>
				<div className="profile-content-container">
					<div className="profile-content">
						<div className="user-avatar">
							{parse(userProfileDetails.avatar)}
						</div>
						{!showChangePass ? (
							<>
								<div className="profile-info">
									<Form.Label htmlFor="lastName">
										Nume
									</Form.Label>
									<h4 id="lastName">
										{userProfileDetails.lastName}
									</h4>
									<Form.Label htmlFor="firstName">
										Prenume
									</Form.Label>
									<h4 id="firstName">
										{userProfileDetails.firstName}
									</h4>
									<Form.Label htmlFor="username">
										Username
									</Form.Label>
									<h4 id="username">
										{userProfileDetails.email}
									</h4>
									<Form.Label htmlFor="userType">
										Tipul de utilizator
									</Form.Label>
									<h4 id="userType">
										{userProfileDetails.userType}
									</h4>
								</div>

								<div
									className="change-password"
									onClick={() => setShowChangePass(true)}
								>
									<h6>Schimba parola</h6>
									<img src={lock} alt="lock" id="lock" />
									<img
										src={lockOpen}
										alt="lock-open"
										id="lock-open"
									/>
								</div>
							</>
						) : (
							<div className="change-password-input">
								<button
									type="button"
									className="btn-close"
									aria-label="Close"
									onClick={() => setShowChangePass(false)}
								></button>
								<Form.Label htmlFor="Schimba parola">
									Schimba parola 🔓
								</Form.Label>
								<Form.Control
									type={showPassword}
									placeholder="Noua parola..."
									ref={passwordRef}
									className="change-pass"
								/>
								<span
									onClick={changeInputType}
									className="show-hide-pass"
								>
									{showPassword === 'password' ? show : hide}
								</span>
								<Form.Label htmlFor="Schimba parola">
									Confirma parola 🔓
								</Form.Label>
								<Form.Control
									type={showPassword}
									placeholder="Confirma parola..."
									ref={confirmPasswordRef}
								/>
								<div
									className={
										checkPasswordInfo.err !== '' ||
										confirmPassword.errMsg !== ''
											? 'invalid-pass-feedback'
											: checkPasswordInfo.infoText !== ''
											? 'success-pass-feedback'
											: 'invalid-pass-feedback'
									}
								>
									{confirmPassword.errMsg !== ''
										? confirmPassword.errMsg
										: checkPasswordInfo.passwordChanged ===
										  'OK'
										? 'Parola a fost schimbata cu succes'
										: ''}
								</div>
								<Button
									variant="info"
									id="change-pass-btn"
									onClick={handleChangePassword}
								>
									Schimba parola
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;
