import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './style/style.css';

const Companies = () => {
	const [companies, setCompanies] = useState([]);
	const getCompanies = async () => {
		await axios
			.get('https://api-techjobs-dev.marchi.ro/api/company')
			.then((res) => {
				setCompanies(res.data);
			});
	};
	useEffect(() => {
		getCompanies();
	}, []);

	return (
		<div className="companies-container">
			<h1 className="text-center mb-5 mt-5">Partenerii noștri</h1>
			<div className="card-deck ">
				{companies.map((c: any) => (
					<div className="card" key={c.companyId}>
						<a href={`/company/${c.companyId}`}>
							<img
								className="card-img-top"
								   src={`/assets/images/company/${c.companyId}.png`}
								alt=""
							></img>
							<div className="card-body mt-1">
								<h2 className="card-title">{c.name}</h2>
								<p className="card-details">
									Adresa: {c.address}
								</p>
								{c.email &&(
								<p className="card-details">
									E-mail: {c.email}
								</p>)
								}
								{ c.phone && (
								<p className="card-details">
									Telefon: {c.phone}
								</p>)
								}
							</div>
						</a>
					</div>
				))}
			</div>
		</div>
	);
};

export default Companies;
