import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from '../../../store/rootReducer';
import { getInternships } from '../../Common/slices/internshipsSlice';
import './style/style.css';

const HomePage = () => {
	const [studentFormClicked, setStudentFormClicked] = useState(false);
	const noInternshipsAlertRef = useRef<HTMLDivElement>(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const internships = useSelector((state: AppState) => state.internships);

	const handleStudentClick = () => {
		dispatch(getInternships());
		setStudentFormClicked(true);
	};

	const removeNoInternshipsAlert = () => {
		if (noInternshipsAlertRef.current !== null) {
			noInternshipsAlertRef.current.style.display = 'none';
		}
	};

	useEffect(() => {
		if (
			studentFormClicked &&
			internships.internships.length === 0 &&
			noInternshipsAlertRef.current !== null
		) {
			noInternshipsAlertRef.current.style.display = 'flex';
		}
		if (studentFormClicked && internships.internships.length > 0) {
			navigate('/formular-student');
		}
	}, [internships.internships]);

	return (
		<div className="homepage-container">
			<div className="homepage-container-background">
				<div className="homepage-container-header">
					<h1>STAGII DE PRACTICA</h1>
					<h3>PRIMUL PAS SPRE ANGAJARE!</h3>
				</div>
			</div>
			<div className="homepage-container-content">
				<div className="homepage-container-content student">
					<h1>STUDENT</h1>
					<h3>Solicita un stagiu de practica / loc de muncă</h3>
					<div className="student-info">
						<p>
							Prin intermediul aplicatiei poti să introduci datele tale (nume,
							prenume, adresa de email, telefonul tău), precum și opțiunea ta
							pentru un stagiu de practică sau loc de muncă existent.
						</p>
						<p>
							După completarea și trimiterea fără erori a informațiilor,
							administratorul site-ului va posta datele tale, în conformitate cu
							termenii și condițiile prezentului site web, respectiv politica de
							confidențialitate
						</p>
						<p>Sperăm să găsești cea mai bună variantă!</p>
						<p>Îți mulțumim!</p>
					</div>
					<button
						onClick={handleStudentClick}
						className="btn btn-primary click-btn"
					>
						Click aici
					</button>
				</div>
				<div className="homepage-container-content company">
					<h1>Angajator</h1>
					<h3>Adaugă un loc de muncă sau stagiu de practică!</h3>
					<div>
						<a href="/formular-angajator" className="btn btn-primary click-btn">
							Click aici
						</a>
					</div>
				</div>
			</div>
			<div className="no-internships-available" ref={noInternshipsAlertRef}>
				<div className="no-internships-available-content">
					Ne pare rău, momentan nu sunt active stagiu de practică / loc de
					muncă! 😞
					<button
						onClick={removeNoInternshipsAlert}
						className="btn btn-primary mt-3"
					>
						Am ințeles
					</button>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
