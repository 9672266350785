import { Route } from 'react-router-dom';
import PrivateComponent from '../../Components/PrivateComponent';
import {
	InternshipSession,
	Students,
	Admin,
	DashboardCompanies,
	DashboardCompany,
	DashboardInternSession,
} from '../../Pages/AdminDPages';
import DashboardAdmin from '../../Pages/AdminDPages/DashboardAdmin';

const adminRoutes = [
	<Route
		path="/dashboard/companii"
		element={
			<PrivateComponent>
				<DashboardCompanies />
			</PrivateComponent>
		}
	/>,
	<Route
		path="/dashboard/companii/:id"
		element={
			<PrivateComponent>
				<DashboardCompany />
			</PrivateComponent>
		}
	/>,
	<Route
		path="/dashboard/sesiunea-de-stagii"
		element={
			<PrivateComponent>
				<InternshipSession />
			</PrivateComponent>
		}
	/>,
	<Route
		path="/dashboard/sesiunea-de-stagii/:id"
		element={
			<PrivateComponent>
				<DashboardInternSession />
			</PrivateComponent>
		}
	/>,
	<Route
		path="/dashboard/admin"
		element={
			<PrivateComponent>
				<Admin />
			</PrivateComponent>
		}
	/>,
	<Route
		path="/dashboard/admin/:id"
		element={
			<PrivateComponent>
				<DashboardAdmin />
			</PrivateComponent>
		}
	/>,
	<Route
		path="/dashboard/studenti"
		element={
			<PrivateComponent>
				<Students />
			</PrivateComponent>
		}
	/>,
];

export default adminRoutes;
