import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { blob } from 'stream/consumers';
import { API_URLS } from '../../../../helpers/http';
import {
	applyToInternship,
	applyToInternshipError,
	applyToInternshipSuccess,
} from '../slices/studentInternshipSlice';

function* applyToInternshipSaga(
	action: PayloadAction<{
		studentId: string;
		internshipId: string;
		files: any;
	}>
) {
	const userToken = JSON.parse(Cookies.get('token')!).token;
	const { studentId, internshipId, files } = action.payload;
	try {
		const formData = new FormData();
		if (files !== undefined) {
			for (let i = 0; i < files.length; i++) {
				formData.append(`f${i + 1}`, files[i]);
			}
		}
		const response: [] = yield call(
			axios.post,
			`${API_URLS.BASE_URL}${API_URLS.INTERNSHIP}/${internshipId}/student/${studentId}/application`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${userToken}`,
					'Content-Type':
						'multipart/form-data; boundary=<calculated when request is sent>',
				},
			}
		);
		yield put(applyToInternshipSuccess({ response }));
	} catch (err: any) {
		yield put(applyToInternshipError({ err: err.response.data }));
	}
}

function* studentInternshipSaga() {
	yield all([takeLatest(applyToInternship, applyToInternshipSaga)]);
}

export default studentInternshipSaga;
