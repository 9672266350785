import { Container, Row, Col } from 'react-bootstrap';
import images from '../../assets/images/images.jsx';
import './style/style.css';
import { Nav } from 'react-bootstrap';
const Footer = () => {
	return (
		<Container fluid className="footer-container ">
			<Row>
				<Col>
					<Row>
						<Col>
							<img src={images.logo} alt="Logo" />
						</Col>
						<Col className="d-flex flex-column footer-links">
							<Nav.Link href="/">Acasa</Nav.Link>
							<a href="#">Echipa</a>
							<a href="#">FAQ</a>
							<Nav.Link href="/contact">Contact</Nav.Link>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col className="d-flex flex-column footer-links">
							<a href="#">Prelucrarea datelor personale</a>							
							<Nav.Link href="/politica-de-confidentialitate">Politica de confidentialitate</Nav.Link>							
							<Nav.Link href="/termene-si-conditii">Termene si conditii</Nav.Link>							
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col className="footer-images">
							<img src={images.logoeu} alt="Logo EU" />
							<img src={images.logogov} alt="Logo EU" />
							<img src={images.logois} alt="Logo EU" />
						</Col>
					</Row>
					<Col>
						<p>
							Proiect în cadrul Programului <br />
							Operaţional Capital Uman
							<br />
							2014-2020, Axa prioritară 6 <br />
							Educaţie şi competenţe
						</p>
					</Col>
				</Col>
				<Col>
					<Row>
						<Col className="footer-images-2">
							<img src={images.logocmmi} alt="Logo CMMI" />
							<img src={images.logotui1} alt="Logo TUI1" />
							<img src={images.logotui2} alt="Logo TUI2" />
						</Col>
					</Row>
					<Col>
						<p>
							Aplicație pentru management-ul
							<br />
							stagiului de practica al
							<br />
							studentilor din cadrul facultății
							<br /> CMMI (Construcții de Mașini și
							<br />
							Management Industrial)
						</p>
					</Col>
				</Col>
			</Row>
			<Row>
				<Col >
					<p>Axa prioritară 6 Educație și Competențe</p>
					<p>Obiectiv Specific 6.13 Creșterea numărului absolvenților de învățământ terțiar universitar și non universitar care își găsesc un loc de muncă urmare a accesului la activități de învățare la un potențial loc de muncă / cercetare/ inovare, cu accent pe sectoarele economice cu potențial competitiv identificate conform SNC şi domeniile de specializare inteligentă conform SNCDI</p>
					<p>Titlul proiectului: "TechJobs - Stagii de practicǎ pentru studenții Universitǎții Tehnice "Gh Asachi" din Iași ȋntr-un mediu de lucru real", cod SMIS 133398</p>
					<p>Contract POCU/626/6/13/133398 (OIPOSDRU NE 10574/23.09.2020)</p>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col className="text-center mb-2">
					© 2021 Tech Jobs. Toate drepturile rezervate.
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
