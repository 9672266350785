import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { AdminSidebarMenu } from '../../Pages/AdminDPages';
import { StudentSidebarMenu } from '../../Pages/StudentDPages';
import jwtDecode from 'jwt-decode';

import './style/style.css';

export interface Jwt {
	UserType: string;
}

const PrivateComponent = ({ children }: { children: React.ReactNode }) => {
	let isAuthenticated: any = Cookies.get('token');

	if (!isAuthenticated) {
		return <Navigate to="/login" />;
	}
	if (isAuthenticated) {
		isAuthenticated = jwtDecode(isAuthenticated);
	}

	return (
		<>
			<Container fluid className="d-flex p-0">
				{isAuthenticated &&
				isAuthenticated.UserType === 'Administrator' ? (
					<AdminSidebarMenu />
				) : isAuthenticated.UserType === 'Student' ? (
					<StudentSidebarMenu />
				) : null}
				<div className="dashboard-content">{children}</div>
			</Container>
		</>
	);
};

export default PrivateComponent;
