import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Internship } from '../types';

const initialState: Internship = {
	name: '',
	description: '',
	startDate: '',
	endDate: '',
	companyId: 0,
	isEnabled: true,
	internshipSessionId: 1,
	pending: true,
	error: '',
	internshipId: 0,
	internshipStatusId: 0,
	applicants: [],
	acceptedStudents: {
		students: [],
	},
};

export const internshipSlice = createSlice({
	name: 'Internship',
	initialState,
	reducers: {
		getInternship: (state, action) => {
			return {
				...state,
			};
		},
		getInternshipSucces: (state, action) => {
			return {
				...state,
				...action.payload.data,
				pending: false,
				editInternshipPending: false,
			};
		},
		getInternshipFailure: (state, action) => {
			return {
				...state,
				...action.payload,
			};
		},
		getInternshipApplicants: (state, action) => {
			return {
				...state,
				pending: true,
			};
		},
		getInternshipApplicantsSuccess: (state, action) => {
			return {
				...state,
				applicants: action.payload.response.data.studentApplicants,
				pending: false,
			};
		},
		getInternshipApplicantsError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload.err,
			};
		},
		getInternshipAcceptedStudents: (state, action) => {
			return {
				...state,
				pending: true,
			};
		},
		getInternshipAcceptedStudentsSuccess: (state, action) => {
			return {
				...state,
				acceptedStudents: action.payload.response.data,
				pending: false,
			};
		},
		getInternshipAcceptedStudentsError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload.err,
			};
		},
		putInternship: (state, action) => {
			return {
				...state,
				pending: true,
			};
		},
		putInternshipSuccess: (state, action) => {
			return {
				...state,
				...action.payload.data,
				pending: false,
				editInternshipPending: true,
				err: '',
			};
		},
		putInternshipError: (state, action) => {
			return {
				...state,
				...action.payload,
				pending: false,
				err: action.payload.error,
			};
		},
		addStudentToInternship: (state, action) => {
			return {
				...state,
				pending: true,
				err: '',
				addStudentPending: true,
			};
		},
		addStudentToInternshipSuccess: (state, action) => {
			return {
				...state,
				pending: false,
				err: '',
				addStudentPending: false,
			};
		},
		addStudentToInternshipError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload.err,
				addStudentPending: false,
			};
		},
		deleteApplicant: (state, action) => {
			return {
				...state,
				pending: true,
				err: '',
				deleteApplicantPending: true,
			};
		},
		deleteApplicantSuccess: (state, action) => {
			return {
				...state,
				pending: false,
				err: '',
				deleteApplicantPending: false,
			};
		},
		deleteApplicantError: (state, action) => {
			return {
				...state,
				pending: false,
				err: '',
				deleteApplicantPending: false,
			};
		},
		deleteStudentToInternship: (state, action) => {
			return {
				...state,
				pending: true,
				err: '',
				deleteStudentPending: true,
			};
		},
		deleteStudentToInternshipSuccess: (state, action) => {
			return {
				...state,
				pending: false,
				err: '',
				deleteStudentPending: false,
			};
		},
		deleteStudentToInternshipError: (state, action) => {
			return {
				...state,
				...action.payload,
				pending: false,
				err: action.payload.err,
				deleteStudentPending: false,
			};
		},
	},
});

export const {
	getInternship,
	getInternshipSucces,
	getInternshipFailure,
	getInternshipApplicants,
	getInternshipApplicantsError,
	getInternshipApplicantsSuccess,
	getInternshipAcceptedStudents,
	getInternshipAcceptedStudentsSuccess,
	getInternshipAcceptedStudentsError,
	putInternship,
	putInternshipSuccess,
	putInternshipError,
	addStudentToInternship,
	addStudentToInternshipSuccess,
	addStudentToInternshipError,
	deleteStudentToInternship,
	deleteStudentToInternshipSuccess,
	deleteStudentToInternshipError,
	deleteApplicant,
	deleteApplicantError,
	deleteApplicantSuccess,
} = internshipSlice.actions;

export default internshipSlice.reducer;
