import images from '../../../assets/images/images';
import { Row, Col } from 'react-bootstrap';
import './style/style.css';

const ProjectContext = () => {
	return (
		<div className="generic-page-container ">
			<Row className="generic-page-background-image">
				<img
					src={images.bg_generic}
					className="p-0"
					alt="context-page-background"
				/>
				<div className="generic-page-header">
					<h2>Context</h2>
				</div>
			</Row>
			<Row className="generic-page-context-container">
				<Col className="col-10 offset-1 generic-page-context-content">
					<br />
					<br />
					<p>
						Proiectul{' '}
						<b>
							„TechJobs - Stagii de practicǎ pentru studenții
							Universitǎții Tehnice "Gh Asachi" din Iași ȋntr-un
							mediu de lucru real” cod MySmis 133398{' '}
						</b>{' '}
						- nr. contract 10574/23.09.2020, se adresează
						studenților cu domiciliul în una din cele 7 regiuni mai
						puțin dezvoltate din România care urmeaza cursurile
						Facultății de Construcții de Mașini și Management
						Industrial, Facultatea de Electronică, Telecomunicații
						și Tehnologia Informației, Facultatea de Știintă și
						Ingineria Materialelor, Facultatea de Mecanică,
						Facultatea de Inginerie Electrică, Energetică și
						Informatică aplicată, din cadrul Universitatii Tehnice
						Gheorghe Asachi din Iasi.
					</p>
					<p>
						Proiectul se desfașoară în localitatea Iași, județ Iași,
						regiunea de dezvoltare Nord-Est. Universitatea Tehnică
						Gheorghe Asachi este printre primele instituţii de
						învăţământ superior de profil tehnic din ţară şi se
						încadrează în categoria universităţilor de cercetare
						avansată şi educaţie. De mai bine de două secole, de pe
						băncile TU Iași au plecat specialişti în domeniile
						ingineriei electrice, electronicii, construcţiilor,
						calculatoarelor, arhitecturii, hidrotehnicii,
						textilelor, mecanicii sau chimiei, dar şi în ale
						cercetării, presei, economiei sau politicii.
					</p>
					<p>
						Prin proiectul{' '}
						<b>
							„TechJobs - Stagii de practicǎ pentru studenții
							Universitǎții Tehnice "Gh Asachi" din Iași ȋntr-un
							mediu de lucru real” cod MySmis 133398{' '}
						</b>{' '}
						s-a stabilit o modalitate comună de derulare stagiilor
						de practică şi tutorii de practică au fost instruiţi şi
						monitorizaţi ca să o aplice. Prin proiect s-au dezvoltat
						noi parteneriate de practica cu angajatori de pe piața
						muncii din Iași și nu numai. Proiectul utilizează noile
						tehnologii în atragerea partenerilor de practica prin
						dezvoltarea aplicației online care asigură un sistem
						coordonat de informare în ambele sensuri: de la
						universitate, respectiv student, către angajator și
						invers.
					</p>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	);
};

export default ProjectContext;
