import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_URLS } from '../../../../helpers/http';
import { DashboardCompanyId } from '../types';
import {
	getDashboardCompany,
	getDashboardCompanySuccess,
	getDashboardCompanyError,
	putDashboardCompany,
	putDashboardCompanyError,
	putDashboardCompanySuccess,
} from '../slices/dashboardCompanySlice';

function* getDashboardCompanySaga(action: PayloadAction) {
	try {
		const id = action.payload;
		const response: DashboardCompanyId = yield call(
			axios.get,
			`${API_URLS.BASE_URL}${API_URLS.COMPANY}/${id}`
		);
		yield put(getDashboardCompanySuccess({ ...response }));
	} catch (err: any) {
		yield put(getDashboardCompanyError({ err: err.response }));
	}
}

function* putDashboardCompanySaga(action: PayloadAction<DashboardCompanyId>) {
	try {
		const { id, name, address, email, website, phone, isVisible } =
			action.payload;
		const response: DashboardCompanyId = yield call(
			axios.put,
			`${API_URLS.BASE_URL}${API_URLS.COMPANY}/${id}`,
			{
				name,
				address,
				email,
				website,
				phone,
				isVisible,
			}
		);
		yield put(putDashboardCompanySuccess({ response }));
	} catch (err: any) {
		yield put(putDashboardCompanyError({ err: err.response }));
	}
}

function* dashboardCompanySaga() {
	yield all([
		takeLatest(getDashboardCompany, getDashboardCompanySaga),
		takeLatest(putDashboardCompany, putDashboardCompanySaga),
	]);
}

export default dashboardCompanySaga;
