import { Button } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import './style/style.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

const ProjectPage = () => {
	return (
		<div className="generic-page-container ">
			<div className="generic-page-content text-justify">
				<Row>
					<Col className="col-10 offset-1 mt-5">
						<h1 className="text-center mt-2">Proiect</h1>
						<br />
						<h2 className="mt-3 mb-3">Context</h2>
						<p>
							Proiectul{' '}
							<b>
								„TechJobs - Stagii de practicǎ pentru studenții
								Universitǎții Tehnice "Gh Asachi" din Iași
								ȋntr-un mediu de lucru real” cod MySmis 133398{' '}
							</b>{' '}
							- nr. contract 10574/23.09.2020, se adresează
							studenților cu domiciliul în una din cele 7 regiuni
							mai puțin dezvoltate din România care urmeaza
							cursurile Facultății de Construcții de Mașini și
							Management Industrial, Facultatea de Electronică,
							Telecomunicații și Tehnologia Informației,
							Facultatea de Știintă și Ingineria Materialelor,
							Facultatea de Mecanică, Facultatea de Inginerie
							Electrică, Energetică și Informatică aplicată, din
							cadrul Universitatii Tehnice Gheorghe Asachi din
							Iasi.
						</p>
						<p>
							Proiectul se desfașoară în localitatea Iași, județ
							Iași, regiunea de dezvoltare Nord-Est. Universitatea
							Tehnică Gheorghe Asachi este printre primele
							instituţii de învăţământ superior de profil tehnic
							din ţară şi se încadrează în categoria
							universităţilor de cercetare avansată şi educaţie.
							De mai bine de două secole, de pe băncile TU Iași au
							plecat specialişti în domeniile ingineriei
							electrice, electronicii, construcţiilor,
							calculatoarelor, arhitecturii, hidrotehnicii,
							textilelor, mecanicii sau chimiei, dar şi în ale
							cercetării, presei, economiei sau politicii.
						</p>
						<p>
							Prin proiectul{' '}
							<b>
								„TechJobs - Stagii de practicǎ pentru studenții
								Universitǎții Tehnice "Gh Asachi" din Iași
								ȋntr-un mediu de lucru real” cod MySmis 133398{' '}
							</b>{' '}
							s-a stabilit o modalitate comună de derulare
							stagiilor de practică şi tutorii de practică au fost
							instruiţi şi monitorizaţi ca să o aplice. Prin
							proiect s-au dezvoltat noi parteneriate de practica
							cu angajatori de pe piața muncii din Iași și nu
							numai. Proiectul utilizează noile tehnologii în
							atragerea partenerilor de practica prin dezvoltarea
							aplicației online care asigură un sistem coordonat
							de informare în ambele sensuri: de la universitate,
							respectiv student, către angajator și invers.
						</p>
						<h2 className="mt-3 mb-3">Obiectiv general</h2>
						<p>
							Proiectul{' '}
							<b>
								„TechJobs - Stagii de practicǎ pentru studenții
								Universitǎții Tehnice "Gh Asachi" din Iași
								ȋntr-un mediu de lucru real” cod MySmis 133398
							</b>{' '}
							- nr. contract 10574/23.09.2020 se încadreaza în AXA
							PRIORITARA 6 „Educație și competențe”, OBIECTIVUL
							TEMATIC 10 Efectuarea de investiții în domeniul
							educației, al formării și al formării profesionale
							în vederea dobandirii de competente și a învțării pe
							tot parcursul vietii, PRIORITATEA DE INVESTITII
							10.iv Sporirea relevanței pe piața forțelor de muncă
							a educației și a sistemelor de formare, facilitarea
							tranziției de la educație la piața forței de muncă
							și consolidarea formării și a sistemelor de formare
							profesională, precum și a calității lor, inclusiv
							prin mecanisme de anticipare a compețentelor,
							adaptarea programelor de învățământ și instituirea
							și dezvoltarea unor sisteme de învățare la locul de
							muncă, inclusiv a unor sisteme de învățare duală și
							programe de ucenicie, OBIECTIV SPECIFIC (OS): OS
							6.13 ”Creșterea numărului absolvenților din
							învățământul terțiar universitar și non universitar
							care își găsesc un loc de muncă urmare a accesului
							la activități de învățare la un potențial loc de
							muncă / cercetare / inovare, cu accent pe sectoarele
							economice cu potențial competitiv identificate
							conform SNC și domeniile de specializare inteligentă
							conform SNCDI”.
						</p>
						<p>
							Obiectivul general proiectului constă în creşterea
							cu 129 a numărului absolvenţilor Universităţii
							Gheorghe Asachi din Iași care îşi găsesc un loc de
							muncă urmare a accesului la activităţi de învăţare
							la un potenţial loc de muncă / cercetare / inovare,
							cu accent pe sectoarele economice cu potențial
							competitiv identificate conform SNC şi domeniile de
							specializare inteligentă conform SNCDI, creşterea cu
							321 a numărului studenţilor Universităţii Gheorghe
							Asachi din Iași sprijiniţi pentru tranziţia de la
							şcoală la piaţa muncii prin participarea la stagii
							de practică la un potenţial loc de muncă / cercetare
							/ inovare, cu accent pe sectoarele economice cu
							potențial competitiv identificate conform SNC şi
							domeniile de specializare inteligentă conform SNCDI
							cu respectarea unor proceduri clare şi a unor
							standarde de calitate definite.
						</p>

						<h2 className="mt-3 mb-3">Obiective specifice</h2>
						<p>
							Obiectivele specifice ale proiectului{' '}
							<b>
								„TechJobs - Stagii de practicǎ pentru studenții
								Universitǎții Tehnice "Gheorghe Asachi" din Iași
								ȋntr-un mediu de lucru real” cod MySmis 133398{' '}
							</b>{' '}
							- nr. contract 10574/23.09.2020 sunt:
						</p>
						<p>
							1. Creşterea cu 321 a numărului studenţilor
							Universității Gheorghe Asachi din Iași sprijiniţi
							pentru tranziţia de la şcoală la piaţa muncii prin
							participarea la stagii de practică la un potenţial
							loc de muncă cu respectarea unor proceduri clare şi
							a unor standarde de calitate definite cu efect în
							piaţa muncii prin creşterea cu 129 a numărului
							absolvenţilor Universității Gheoghe Asachi din Iași
							care îşi găsesc un loc de muncă urmare a accesului
							la activităţi de învăţare la un potenţial loc de
							muncă / cercetare / inovare, cu accent pe sectoarele
							ec cu potential competitiv identificate conform SNC
							şi domeniilor de specializare inteligentă conform
							SNCDI, participarea a 36 studenţi din GT la stagii
							de practică în ţări ale UE. <br />
							<br />
							Prin activitățile proiectului se va interveni asupra
							îmbunătățirii ratei de absolvire până la 90% mai
							ales în cazul GT. Proiectul își propune să orienteze
							studenții TU Iasi, spre sectoarele economice cu
							potențial competitiv prin atragerea angajatorilor
							specifici în parteneriate de practică.
						</p>
						<p>
							2. OS2 Creşterea utilizării noilor tehnologii în
							facilitarea tranziţiei de la şcoală la piaţa muncii
							pentru 321 de studenţi GT prin dezvoltarea unei
							aplicaţii de conexare a Universitatii Gheorghe
							Asachi din Iași şi studenţii săi cu angajatorii.
							Dezvoltarea unui sistem de informare între
							angajatori și TU Iasi va facilita adaptarea
							cursurilor la cerințele reale ale pietei (ex.
							suplimentarea locurilor la anumite specializari din
							domenii de specializare inteligenta cf SNCDI).
							Proiectul va sprijini încheierii unor parteneriate
							sustenabile cu sectorul privat pentru facilitarea
							tranziţiei de la educaţie la un loc de muncă prin
							instituirea unui sistem funcţional de stagii de
							practică la un potenţial angajator, adresate
							studenţilor din instituţiilor de învăţământ superior
							(ISCED 5-7). Acest sistem va avea componenta online
							dezvoltata prin SA2.1.
						</p>

						<h2 className="mt-3 mb-3">Justificare</h2>
						<p>
							Universitatea Tehnică „Gheorghe Asachi”, Facultatea
							Construcții de Mașini și Management Industrial,
							Facultatea de Electronică, Telecomunicații și
							Tehnologia Informației, Facultatea de Știintă și
							Ingineria Materialelor, Facultatea de Mecanică,
							Facultatea de Inginerie Electrică, Energetică și
							Informatică aplicată, ale caror specializări care se
							regasesc în sectoarele economice cu potențial
							competitiv identificate conform SNC și domeniile de
							specializare inteligentă conform SNCDI au contact cu
							mediul de afaceri din sectoarele de afaceri cu
							potențial competitiv conform SNC, dar piața muncii
							este în continuă schimbare și multe companii noi
							și-au deschis punct de lucru la Iași.
						</p>
						<p>
							Studenții nu cunosc modul de lucru din țări ale
							Uniunii Europene. În lipsa deprinderii unei
							calificări, tânărul absolvent are şanse foarte mici
							să se angajeze sau primeşte un salariu mic care îl
							demotivează. Prin proiect se va stabili o modalitate
							comună de derulare stagiilor de practică şi tutorii
							de practică vor fi instruiţi şi monitorizaţi ca să o
							aplice. Se implementează astfel un set de reguli
							clare, cu obiective specifice şi standarde de
							calitate unitare. De asemenea, prin proiect se vor
							dezvolta noi parteneriate de practică cu angajatori
							noi pe piața muncii din Iași. Proiectul va utiliza
							noile tehnologii în atragerea partenerilor de
							practică prin dezvoltarea unei aplicatii online care
							va asigura un sistem coordonat de informare în
							ambele sensuri: de la universitate, respectiv
							student, către angajator și invers.
						</p>
						<p>
							Proiectul va dezvolta programe de internship /
							traineeship ca alternativa inovatoare și de
							completare a stagiilor de practică într-un mediu de
							lucru real.
						</p>
						<p>
							Proiectul va oferi unui număr de 36 de studenti din
							grupul țintă oportunitatea de a realiza stagii de
							practică în tari ale Uniunii Europene unde s-au
							inregistrat progrese vizibile în domeniile lor de
							specializare.
						</p>
						<p>
							Proiectul va avea efecte pozitive pe termen lung
							pentru grupul țintă, atât din punct de vedere
							cantitativ cât şi calitativ.
						</p>
						<p>
							Efect cantitativ pe termen lung:
							<ul className="mt-2">
								<li>
									Sistemul de informare online care aduce
									față-în-față angajatorii și TU Iasi va
									dezvolta și consolida parteneriatul de
									practică în vederea derularii stagiilor de
									practica pentru studenții TU Iasi, într-un
									mediu de munca real,
								</li>
								<li>
									Serviciile de consiliere şi orientare
									profesionala furnizate celor 321 de studenti
									vor oferi o perspectivă clară grupului țintă
									în privința viitorului lor și pot hotarâ
									continuarea studiilor și dobandirea de noi
									competențe profesionale asigurând astfel
									formarea profesională continuă,
								</li>
								<li>
									Soluția inovativă de dobandire a
									competențelor antreprenoriale și
									socio-civice prin participarea a minim 50 de
									studenți la programul de antreprenoriat și
									responsabilitate socială dezvoltat pe
									modelul întreprinderilor simulate va aduce o
									nouă perspectivă asupra dezvoltarii unei
									cariere pe cont propriu prin înființarea
									unei firme,
								</li>
								<li>
									Angajarea a 126 persoane din GT (peste 39%
									din total GT) va stimula dezvoltarea
									socio-economică în județul Iași,
								</li>
								<li>
									Parteneriatele transnationale cu angajatorii
									europeni vor fi fructificate și pe viitor
									prin organizarea de stagii de practica fie
									prin atragerea altor finanțări, fie prin
									suportarea cheltuielilor de studenti sau de
									angajatori; aceste parteneriate vor deschide
									noi oportunitati de specializare și angajare
									iar studenții TU Iași, se vor familiariza cu
									cerințele profesionale de pe piețele
									europene.
								</li>
							</ul>
						</p>
						<p>
							Efect calitativ pe termen lung:
							<ul className="mt-2">
								<li>
									Omogenizarea studenților indiferent de etnie
									și domiciliul urban / rural și creșterea
									toleranței și a colaborării locale între
									studenți și angajatori fără a ține cont de
									etnie sau domiciliul rural / urban;
								</li>
								<li>
									Noi deprinderi de învățare, noi capacități
									și atitudini intelectuale ce-i vor ajuta pe
									studenții din grupul țintă pe întreg
									parcursul vieții, deprinderi cognitive,
									sociale și emoționale necesare aplicării
									cunoștintelor dobândite prin programele de
									învățare;
								</li>
								<li>
									Dezvoltarea personală a studenților,
									creșterea stimei de sine și încrederea în
									propriile forțe a grupului țintă.
								</li>
							</ul>
						</p>
						<h2 className="mt-3 mb-3">Rezultate</h2>
						<p>
							Rezultatele urmărite sunt: creșterea cu 321 a
							numărului de studenți sprijiniți în tranziția de la
							școală la piața muncii cu accent pe sectorul creativ
							recunoscut ca sector economic cu potențial
							competitiv identificat conform SNC și domeniile de
							specializare inteligentă conform SNCDI. Prin proiect
							se va crea o conexiune între specializările TU Iași
							și domeniile de specializare inteligentă. Proiectul
							asigură continuarea/consolidarea parteneriatelor
							existente între TU Iași și partenerii de practică
							actuali prin dezvoltarea împreună cu aceștia a unui
							sistem funcțional de stagii de practică și, în
							același timp, proiectul asigură formarea de
							parteneriate între TU Iași și noi parteneri de
							practică.
						</p>
						<p>
							1. R1- Un sistem funcțional de stagii de practică la
							un potențial angajator, 30 de potențiali angajatori
							parte din sistemul multifunctional, 4 parteneriate
							noi cu potențiali angajatori, o aplicație online ca
							suport pentru sistemul funcțional de stagii de
							practică la un potențial angajator.
						</p>
						<p>
							2. R2-Sprijinirea a 321 studenți pentru trecerea pe
							piata muncii - stagii de practică, dezvoltarea și
							implementarea programelor de internship/traineeship,
							furnizarea de servicii de consiliere și orientare
							profesională pentru 321 de studenți, 36 studenți
							participanți la stagii de stagii de practică
							externă.
						</p>
						<p>
							3. R3 -Un program de antreprenoriat și CSR
							(responsabilitate socială).
						</p>
						<p>
							4. R4- 321 persoane informate asupra proiectului și
							atrase în proiect, 2 anunțuri în presa scrisa și
							online pentru lansarea, respectiv închiderea
							proiectului, o identitate vizuala a proiectului cu
							respectarea Manualului de Identitate Vizuala POCU
							2014-2020, un afiș de minim A3 expus vizibil la
							sediul fiecarui partener, toate documentele adresate
							publicului și grupului țintă vor include o mențiune
							cu privire la faptul ca operațiunea a fost
							sprijinita în cadrul FSE prin POCU 2014-2020, vor fi
							publicate informații privind proiectul: denumirea
							Beneficiarului, titlul și rezumatul Proiectului,
							valoarea totală a finanțarii, datele de începere și
							de finalizare ale Proiectului, locul de implementare
							al acestuia.
						</p>
						<h2>
							<Nav.Link href="/grup-tinta">Grup Tinta</Nav.Link>
						</h2>

						<br />
						<br />
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default ProjectPage;
