import { Button } from 'react-bootstrap';
import './style/style.css';

const PageNotFound = () => {
	return (
		<div className="page-not-found-container d-flex justify-content-center align-items-center">
			<div className="page-not-found-content text-center">
				<h1>404</h1>
				<p>Această pagină nu este disponibilă.</p>
				<Button variant="primary" href="/home" className="home-button">
					Acasă
				</Button>
			</div>
		</div>
	);
};

export default PageNotFound;
