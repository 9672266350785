import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
	studentDetails: {
		studentId: '',
	},
	ownAttendaces: [],
	pending: false,
};

const studentCommonSlice = createSlice({
	name: 'Student Common',
	initialState,
	reducers: {
		getOwnAttendance: (state) => {
			return {
				...state,
				pending: true,
				err: '',
			};
		},
		getOwnAttendanceSuccess: (state, action) => {
			return {
				...state,
				pending: false,
				studentDetails: action.payload.data.student,
				ownAttendaces: action.payload.data.ownAttendances,
			};
		},
		getOwnAttendanceError: (state, action) => {
			return {
				...state,
				pending: false,
				err: action.payload.err,
			};
		},
	},
});

export const {
	getOwnAttendance,
	getOwnAttendanceSuccess,
	getOwnAttendanceError,
} = studentCommonSlice.actions;

export default studentCommonSlice.reducer;
