import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	password: '',
	infoText: '',
	passwordChanged: '',
	pending: true,
	err: '',
};

const profileSlice = createSlice({
	name: 'profileSlice',
	initialState,
	reducers: {
		checkPassword: (state, action) => {
			return { ...state };
		},
		checkPasswordSuccess: (state, action) => {
			return {
				...state,
				...action.payload,
				pending: false,
			};
		},
		checkPasswordError: (state, action) => {
			return {
				...state,
				err: action.payload.err,
				pending: false,
			};
		},
		postPassword(state, action) {
			return { ...state };
		},
		postPasswordSuccess: (state, action) => {
			return {
				...state,
				...action.payload,
				pending: false,
			};
		},
		postPasswordError(state, action) {
			return {
				...state,
				...action.payload,
				pending: false,
			};
		},
	},
});

export const {
	checkPassword,
	checkPasswordSuccess,
	checkPasswordError,
	postPassword,
	postPasswordSuccess,
	postPasswordError,
} = profileSlice.actions;

export default profileSlice.reducer;
