import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Internships, DeleteInternship, PostInterface } from '../types';

const initialState: Internships = {
	internships: [],
	pending: true,
	error: '',
};

export const internshipsSlice = createSlice({
	name: 'internships',
	initialState,
	reducers: {
		getInternships: (state) => {
			return {
				...state,
			};
		},
		getInternshipsSuccess: (state, action) => {
			return {
				...state,
				internships: action.payload.internships.data,
				pending: false,
			};
		},
		getInternshipsFailure: (state, action) => {
			return {
				...state,
				pending: false,
				internships: [],
				error: action.payload.error,
			};
		},
		postInternship: (state, action) => {
			return {
				...state,
				pending: true,
				postPending: true,
			};
		},
		postInternshipSuccess: (state, action: PayloadAction<PostInterface>) => {
			return {
				...state,
				pending: false,
				postPending: false,
			};
		},
		postInternshipError: (state, action) => {
			return {
				...state,
				error: action.payload.error,
				postPending: false,
			};
		},
		deleteInternship: (state, action: PayloadAction<DeleteInternship>) => {
			return { ...state, ...action.payload, deletedPending: true };
		},
		deleteInternshipSuccess: (state, action) => {
			return { ...state, deletedPending: false };
		},
		deleteInternshipFailure: (state, action) => {
			return { ...state, deletedPending: false };
		},
	},
});

export const {
	getInternships,
	getInternshipsSuccess,
	getInternshipsFailure,
	postInternship,
	postInternshipSuccess,
	postInternshipError,
	deleteInternship,
	deleteInternshipSuccess,
	deleteInternshipFailure,
} = internshipsSlice.actions;

export default internshipsSlice.reducer;
