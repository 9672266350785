export const pressReleasesData = [
	{
		url: 'anunturi/2022/anunt-sept-comunicat-final-proiect',
		title: 'Comunicat final proiect',			
	},
	{
		url: 'anunturi/2022/anunt-sept-sfarsit-conferinta-finala',
		title: 'Anunt sfarsit conferinta finala',			
	},
	{
		url: 'anunturi/2022/anunt-sept-invitatie-conferinta-finala',
		title: 'Invitatie conferinta de final proiect TechJobs',			
	},		
	{
		url: 'anunturi/2022/visits-vitone-eco',
		title: 'Vizita Vitone Eco',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-ditro-designia',
		title: 'Vizita Ditro Designia',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-mimi',
		title: 'Vizita Mimi Srl',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-inreslab',
		title: 'Vizita InResLab',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-mtm-project',
		title: 'Vizita MTM Project',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-tecnopolis',
		title: 'Vizita Tecnopolis',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-sitec',
		title: 'Vizita Sitec Srl',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-fablab-poliba',
		title: 'Vizita Fablab Poliba',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-eredi-marinelli',
		title: 'Vizita Eredi Marinelli',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-politehnica-bari',
		title: 'Vizita Politehnica din Bari',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-universitatea-bari',
		title: 'Vizita Universitatea din Bari',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-primaria-bari',
		title: 'Vizita Primaria din Bari',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-alsia-ricerca',
		title: 'Vizita Alsia Ricerca',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-metapontum-agrobios',
		title: 'Vizita Metapontum Agrobios',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-giardini-d-oriente',
		title: 'Vizita Giardini D\'Oriente',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-taverna',
		title: 'Vizita Compania Taverna',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-fevi',
		title: 'Vizita Compania FEVI',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/visits-arpor',
		title: 'Vizita Arpor',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/tips-primul-pas-in-cariera',
		title: 'Căutarea - Primul pas important către o cariera de succes',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/tips-munca-la-distanta-si-joburile-hibride',
		title: 'Munca la distanța (telemunca) si job-urile hibride',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/tips-managementul-timpului',
		title: 'Managementul timpului',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/tips-tipuri-de-competente-interpersonale',
		title: 'Tipuri de competențe interpersonale',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/tips-relationarea-cu-oamenii-dificili',
		title: 'Relaționarea cu oamenii dificili la locul de muncă',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/tips-abilitati-de-gestionare-a-timpului',
		title: 'Abilități de gestionare a timpului',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/tips-munca-la-distanta-si-dezvoltarea-personala',
		title: 'Munca la distanță și dezvoltarea profesională',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/tips-cum-sa-fim-mai-productivi',
		title: 'Cum să fiți mai productivi la locul de muncă',		
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-vizite-externe',
		title: 'Anunt program vizite Italia',		
	},
	{
		url: 'anunturi/2022/anunt-cum-va-puteti-dezvolta-competentele',
		title: 'Cum vă puteti dezvolta propriile competente interpersonale?',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-inportanta_soft_skills',
		title: 'Importanta abilitătilor de relationare (Soft Skills)',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-interviul_de_angajare_intrebari_incomode',
		title: 'Interviul de angajare - întrebări incomode si recomandări',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-corespondenta-electronica-pentru-angajare',
		title: 'Corespondenta electronică pentru angajare',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-emailul-de-angajare',
		title: 'Emailul de angajare',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/interviul-video-pentru-angajare',
		title: 'Interviul video pentru angajare',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-adreasa-profesionala',
		title: 'Adresa de e-mail profesională',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-formatul-cv-ului',
		title: 'Formatul CV-ului',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-cele-mai-bune-siteuri-job',
		title: 'Cele mai bune 10 site-uri de căutare a locurilor de muncă din 2022',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-cv-ul-care-atrage-atentia',
		title: 'CV-ul care atrage atentia',
		isHidden: true,
	},
	{
		url: 'anunturi/2022/anunt-lista-premiere-august',
		title: 'Lista finala premiere',
	},
	{
		url: 'anunturi/2022/anunt-rezultate-concurs-august',
		title: 'Anunt privind rezultatele concursului din cadrul activitatii SA5.1',
	},
	{
		url: 'anunturi/2022/anunt-concurs-premiere-iulie',
		title: 'Organizarea concursului si premierea studentilor participanti la activitati',
	},
	{
		url: 'anunturi/2022/stagii-de-practica-ingineri-iulie',
		title: 'Stagii de practică la standarde europene',
	},
	{
		url: 'anunturi/2022/multumim-casa-auto-iulie',
		title: 'Multumim partenerului "Casa Auto" pentru suportul acordat',
	},
	{
		url: 'anunturi/2022/multumim-partenerilor-iulie',
		title: 'Multumim partenerilor pentru suportul acordat !',
	},
	{
		url: 'anunturi/2022/multumim-studentilor-iulie',
		title: 'Multumim tuturor studentilor care au aplicat !',
	},
	{
		url: 'anunturi/2022/anunt-sectiune-studenti-iulie',
		title: 'Sectiunea "Studenti" a fost adaugata in aplicatie',
	},
	{
		url: 'anunturi/2022/anunt-ateliere-diplome-iulie',
		title: 'Anunt ateliere diplome',
	},
	{
		url: 'anunturi/2022/organizare-concursuri-iulie',
		title: 'Organizarea concursurilor si premierea studentilor participanti la activitati',
	},
	{
		url: 'anunturi/2022/anunt-ateliere-iulie',
		title: 'Anunt ateliere',
	},
	{
		url: 'anunturi/2022/ultimele-sesiuni-de-consiliere-iulie',
		title: 'Ultimele sesiuni de consiliere',
	},
	{
		url: 'anunturi/2022/a-inceput-practica',
		title: '1,2,3.... a inceput practica !',
	},
	{
		url: 'anunturi/2022/de-ce-sa-participi-la-practica',
		title: 'De ce sa participi la practica ?',
	},
	{
		url: 'anunturi/2022/stire-job-practica',
		title: 'Esti student si nu gasesti un loc de munca?',
	},
	{
		url: 'anunturi/2022/ultimele-sesiuni-de-consiliere',
		title: 'Ultimele sesiuni de consiliere',
	},
	{
		url: 'anunturi/2022/hai-la-practica',
		title: 'Hai la practica !',
	},
	{
		url: 'anunturi/2022/te-asteptam-la-noi-la-practica',
		title: 'Te asteptam la noi la practica !',
	},
	{
		url: 'anunturi/2022/anunt-final-ateliere',
		title: 'Atelierele au luat sfarsit ! Felicitari participantilor !',
	},
	{
		url: 'anunturi/2022/apel-stagiu-casa-auto',
		title: 'Un nou stagiu de practică organizat la Casa Auto',
	},
	{
		url: 'anunturi/2022/stagiu-de-practica-conteaza-in-cv',
		title: 'Stagiu de practică - contează în CV ?',
	},
	{
		url: 'anunturi/2022/anunt-stagii-de-practica',
		title: 'Anunt stagii de practica',
	},
	{
		url: 'anunturi/2022/anunt-concursuri',
		title: 'Anunt concursuri',
	},
	{
		url: 'anunturi/2022/anunt-ateliere',
		title: 'Anunt ateliere',
	},
	{
		url: 'anunturi/2022/invitatie-ateliere',
		title: 'Invitatie ateliere',
	},
	{
		url: 'anunturi/2022/comunicat-selectie-gt',
		title: 'Selectie grup tinta',
	},
	{
		url: 'anunturi/2022/comunicat-activitati-proiect-aa2',
		title: 'Activitati proiect AA2',
	},
	{
		url: 'anunturi/2022/comunicat-activitatea-de-consiliere',
		title: 'Ativitatea de consiliere',
	},
	{
		url: 'anunturi/2022/comunicat-stagii-practica',
		title: 'Stagii de practica',
	},
	{
		url: 'anunturi/2022/regulament-concurs',
		title: 'Regulament de concurs',
	},
	
];
