import { useEffect, useState, useRef } from 'react';
import useImagePreloader from '../../../helpers/useImagePreloader';

import internshipImg from '../../../assets/images/Dashboard/DashboardImages/internship.jpg';
import './style/style.css';
import { useDispatch, useSelector } from 'react-redux';

import { getInternships } from '../../Common/slices/internshipsSlice';
import { AppState } from '../../../store/rootReducer';

import PageLoading from '../../../Components/PageLoading';
import internshipLogo from '../../../assets/images/Dashboard/dashboard-icons/internship.png';
import { getOwnAttendance } from '../Common/slices/studentCommonSlice';

import infoLogo from '../../../assets/images/Dashboard/dashboard-icons/info.png';
import { getDashboardCompanies } from '../../AdminDPages/DashboardCompanies/slices/dashboardCompaniesSlice';

const Internships = () => {
	const internships = useSelector((state: AppState) => state.internships);
	const ownAttendance = useSelector(
		(state: AppState) => state.commonStudentDetails
	);
	const preloadSrcList: string[] = [internshipImg];
	const internshipsContainer = useRef<HTMLDivElement>(null);
	const icontentStudent = useRef<HTMLDivElement>(null);
	const { imagesPreloaded } = useImagePreloader(preloadSrcList);
	const companies = useSelector(
		(state: AppState) => state.dashboardCompanies
	);
	const dispatch = useDispatch();

	const handleApplyStatus = (internshipId: string) => {
		if (ownAttendance.ownAttendaces.length > 0) {
			return ownAttendance.ownAttendaces.map((el: any) => {
				const defaultBtn = document.getElementById(
					internshipId
				) as HTMLAnchorElement | null;
				if (el.internship.internshipId === internshipId) {
					if (
						el.internship.hasAppliedForInternship === true &&
						el.internship.isAcceptedInIntership === false
					) {
						if (defaultBtn !== null) {
							defaultBtn.style.display = 'none';
						}
						return (
							<a
								href={`/dashboard/stagii/${internshipId}`}
								className="btn btn-warning text-white enroll"
								key={internshipId}
							>
								In procesare⏳
							</a>
						);
					} else if (
						(el.internship.hasAppliedForInternship === true &&
							el.internship.isAcceptedInIntership === true) ||
						(el.internship.hasAppliedForInternship === false &&
							el.internship.isAcceptedInIntership === true)
					) {
						if (defaultBtn !== null) {
							defaultBtn.style.display = 'none';
						}

						return (
							<a
								href={`/dashboard/stagii/${internshipId}`}
								className="btn btn-success text-white enroll"
								key={internshipId}
							>
								Acceptat✅
							</a>
						);
					}
				}
			});
		}
	};

	const renderStudentInternships = () => {
		if (internships.internships.length > 0) {
			return internships.internships.map((el: any) => {
				if (el.internshipStatusId === 2) {
					return (
						<div className="internship-content" key={el.name}>
							{el.internshipStatusId !== 1 && (
								<div className="internship-status">
									<p
										className={`internship-info${el.internshipStatusId}`}
									>
										<img src={infoLogo} alt="info" />
										{el.internshipStatusId === 2
											? 'Deschis pentru candidatura'
											: el.internshipStatusId
											? 'Stagiul nu mai accepta candidati'
											: null}
									</p>
								</div>
							)}

							<div className="internship-description">
								<img
									src={internshipLogo}
									alt="internshipLogo"
								/>
								<div className="ms-2 me-auto">
									<div className="fw-bold">{el.name}</div>
									{el.description}
									<div className="company-name">
										{
											companies.companies
												.filter((company: any) => {
													if (
														company.companyId ===
														el.companyId
													)
														return company.name;
												})
												.map((company: any) => {
													return company.name;
												})[0]
										}
									</div>
								</div>
								{handleApplyStatus(el.internshipId)}
								<a
									href={`/dashboard/stagii/${el.internshipId}`}
									className="btn btn-info text-white enroll apply-internship"
									id={el.internshipId}
								>
									Aplica
								</a>
							</div>
						</div>
					);
				} else if (el.internshipStatusId === 3) {
					return ownAttendance.ownAttendaces.map(
						(studentDetails: any) => {
							if (
								studentDetails.internship.internshipId ===
								el.internshipId
							) {
								if (
									studentDetails.internship
										.hasAppliedForInternship
								) {
									return (
										<div
											className="internship-content"
											key={el.name}
										>
											{el.internshipStatusId !== 1 && (
												<div className="internship-status">
													<p
														className={`internship-info${el.internshipStatusId}`}
													>
														<img
															src={infoLogo}
															alt="info"
														/>
														{el.internshipStatusId ===
														2
															? 'Deschis pentru candidatura'
															: el.internshipStatusId
															? 'Stagiul nu mai accepta candidati'
															: null}
													</p>
												</div>
											)}

											<div className="internship-description">
												<img
													src={internshipLogo}
													alt="internshipLogo"
												/>
												<div className="ms-2 me-auto">
													<div className="fw-bold">
														{el.name}
													</div>
													{el.description}
													<div className="company-name">
														{
															companies.companies
																.filter(
																	(
																		company: any
																	) => {
																		if (
																			company.companyId ===
																			el.companyId
																		)
																			return company.name;
																	}
																)
																.map(
																	(
																		company: any
																	) => {
																		return company.name;
																	}
																)[0]
														}
													</div>
												</div>
												{handleApplyStatus(
													el.internshipId
												)}
												<a
													href={`/dashboard/stagii/${el.internshipId}`}
													className="btn btn-info text-white enroll apply-internship"
													id={el.internshipId}
												>
													Aplica
												</a>
											</div>
										</div>
									);
								}
							}
						}
					);
				}
			});
		}
	};

	useEffect(() => {
		dispatch(getInternships());
		dispatch(getOwnAttendance());
		dispatch(getDashboardCompanies());
	}, []);

	useEffect(() => {
		if (
			internships.internships.length > 3 &&
			icontentStudent.current !== null
		) {
			icontentStudent.current.style.height = '100%';
		}
	}, [internships.internships]);

	return (
		<>
			{!imagesPreloaded && internships.pending && <PageLoading />}
			<div
				className="internship-content icontent-student"
				ref={icontentStudent}
			>
				<div className="internship-background"></div>
				<div className="internship-list ilist-student">
					<>
						{internships.error !== '' && (
							<div className="alert alert-danger" role="alert">
								{internships.error}
							</div>
						)}

						<div className="internships-container icontainer-student">
							<div className="internships-header iheader-student">
								<h3>Descopera stagiile disponibile</h3>
							</div>
							<div ref={internshipsContainer}>
								{internships.internships.length > 0 &&
								!renderStudentInternships().every((el: any) => {
									return el === undefined;
								}) ? (
									renderStudentInternships()
								) : (
									<div className="internship-fallback">
										<p>
											Ne pare rau, in acest moment nu
											exista niciun stagiu disponibil 😞
										</p>
									</div>
								)}
							</div>
						</div>
					</>
				</div>
			</div>
		</>
	);
};

export default Internships;
