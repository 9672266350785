import { useRef, useState } from 'react';

import { Form, NavItem } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';

import './style/style.css';

const CompanyForm = () => {
	const addressRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const companyNameRef = useRef<HTMLInputElement>(null);
	const websiteNameRef = useRef<HTMLInputElement>(null);

	const [postCompanyApplicationError, setPostCompanyApplicationError] =
		useState({ show: false, errMsg: '' });

	const handleCompanyFormSubmit = () => {
		const phoneNumber = (
			document.getElementById('company-phone-number') as HTMLInputElement
		).value;
		if (
			addressRef.current !== null &&
			addressRef.current.value !== '' &&
			emailRef.current !== null &&
			emailRef.current.value !== '' &&
			companyNameRef.current !== null &&
			companyNameRef.current.value !== '' &&
			websiteNameRef.current !== null &&
			websiteNameRef.current.value !== '' &&
			phoneNumber.length === 15
		) {
			const regex =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (regex.test(emailRef.current.value) === false) {
				setPostCompanyApplicationError({
					show: true,
					errMsg: 'Adresa de email nu este valida',
				});
			} else {
				setPostCompanyApplicationError({
					show: false,
					errMsg: '',
				});
			}
		} else if (phoneNumber.length > 3 && phoneNumber.length < 15) {
			setPostCompanyApplicationError({
				show: true,
				errMsg: 'Numarul de telefon nu este valid',
			});
		} else
			setPostCompanyApplicationError({
				show: true,
				errMsg: 'Va rugam completati toate campurile',
			});
	};

	return (
		<div className="company-form">
			<div className="company-form-content">
				<div className="company-form-content-inputs">
					<h3 className="lets-start">Să începem!</h3>
					<Form.Group className="mb-3">
						<Form.Label>Adresa</Form.Label>
						<Form.Control
							type="text"
							name="company-address"
							placeholder="Adresa..."
							ref={addressRef}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Telefon</Form.Label>
						<PhoneInput
							international
							country="RO"
							defaultCountry="RO"
							maxLength={15}
							onChange={() => console.log('')}
							id="company-phone-number"
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="text"
							name="company-email"
							placeholder="Email..."
							ref={emailRef}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Nume Companie</Form.Label>
						<Form.Control
							type="text"
							name="company-name"
							placeholder="Numele companiei..."
							ref={companyNameRef}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Website</Form.Label>
						<Form.Control
							type="text"
							name="company-name"
							placeholder="Website-ul companiei..."
							ref={websiteNameRef}
						/>
					</Form.Group>
					<button
						onClick={handleCompanyFormSubmit}
						className="btn btn-primary mt-3 mb-3"
					>
						Finalizare
					</button>
					{postCompanyApplicationError.show && (
						<div className="alert alert-danger" role="alert">
							{postCompanyApplicationError.errMsg !== '' &&
								postCompanyApplicationError.errMsg}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CompanyForm;
