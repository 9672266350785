import { useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppState } from '../../../store/rootReducer';
import { getInternship } from '../../Common/slices/internshipSlice';
import PageLoading from '../../../Components/PageLoading';

import { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import { DateInterface } from '../../Common/types';

import Dropzone from 'react-dropzone';

import './style/style.css';

import calendar from '../../../assets/images/Dashboard/dashboard-icons/calendar.png';
import { applyToInternship } from './slices/studentInternshipSlice';
import { getOwnAttendance } from '../Common/slices/studentCommonSlice';

import infoLogo from '../../../assets/images/Dashboard/dashboard-icons/info.png';

const DashboardInternship = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const dropzoneRef = useRef<HTMLDivElement>(null);
	const internship = useSelector((state: AppState) => state.internshipId);
	const ownAttendace = useSelector(
		(state: AppState) => state.commonStudentDetails
	);
	const studentInternship = useSelector(
		(state: AppState) => state.dashboardStudentInternship
	);
	const [fileNames, setFileNames] = useState([]);
	const [files, setFiles] = useState([]);
	const defaultRef = useRef<HTMLDivElement>(null);
	const handleDrop = (acceptedFiles: any) => {
		setFileNames(acceptedFiles.map((file: any) => file.name));
		setFiles(acceptedFiles.map((file: any) => file));
	};
	const [date, setDate] = useState<DateInterface>(() => {
		return {
			startDate: internship.startDate
				? new Date(internship.startDate)
				: new Date(),
			endDate: internship.endDate
				? new Date(internship.endDate)
				: new Date(),
			edited: false,
		};
	});
	const navigate = useNavigate();

	const initialValues = {
		name: '',
		description: '',
		startDate: '',
		endDate: '',
		edited: false,
	};
	const [inputValues, setInputValues] = useState(initialValues);

	const handleInternshipStatus = () => {
		if (ownAttendace.ownAttendaces.length > 0) {
			return ownAttendace.ownAttendaces.map((el: any) => {
				if (el.internship.internshipId === internship.internshipId) {
					if (
						el.internship.hasAppliedForInternship === true &&
						el.internship.isAcceptedInIntership === false
					) {
						if (defaultRef.current !== null)
							defaultRef.current.style.display = 'none';
						return (
							<p
								className="processing-application"
								key={el.internship.internshipId}
							>
								Ai aplicat cu succes la acest stagiu. <br />
								Candidatura ta este in curs procesare ⏳
							</p>
						);
					} else if (
						(el.internship.hasAppliedForInternship === true &&
							el.internship.isAcceptedInIntership === true) ||
						(el.internship.hasAppliedForInternship === false &&
							el.internship.isAcceptedInIntership === true)
					) {
						if (defaultRef.current !== null) {
							defaultRef.current.style.display = 'none';
						}
						return (
							<p
								className="success-application"
								key={el.internship.internshipId}
							>
								Ai fost acceptat la acest stagiu 🎉
							</p>
						);
					}
				}
			});
		}
	};

	const handleApplyForInternship = () => {
		if (files.length > 0) {
			dispatch(
				applyToInternship({
					studentId: ownAttendace.studentDetails.studentId,
					internshipId: params.id,
					files: files,
				})
			);
		} else {
			dispatch(
				applyToInternship({
					studentId: ownAttendace.studentDetails.studentId,
					internshipId: params.id,
				})
			);
		}
	};

	useEffect(() => {
		dispatch(getInternship({ id: params.id }));
		dispatch(getOwnAttendance());
	}, []);

	useEffect(() => {
		internship.startDate !== '' &&
			setDate({
				...date,
				startDate: new Date(internship.startDate),
				endDate: new Date(internship.endDate),
			});
		setInputValues({
			...initialValues,
			name: internship.name,
			description: internship.description,
		});
	}, [internship]);

	useEffect(() => {
		if (
			studentInternship.applyPending === false &&
			studentInternship.err === ''
		) {
			navigate('/dashboard/stagii');
		}
	}, [studentInternship.applyPending]);

	return (
		<>
			{internship.pending ? (
				<PageLoading />
			) : ownAttendace.pending ? (
				<PageLoading />
			) : studentInternship.applyPending ? (
				<PageLoading />
			) : null}
			<div className="internship-id-container">
				<div className="internship-id-content">
					{internship.internshipStatusId !== 1 && (
						<div className="internship-status">
							<p
								className={`internship-info${internship.internshipStatusId}`}
							>
								<img src={infoLogo} alt="info" />
								{internship.internshipStatusId === 2
									? 'Deschis pentru candidatura'
									: internship.internshipStatusId
									? 'Stagiul nu mai accepta candidati'
									: null}
							</p>
						</div>
					)}

					<>
						{internship.error !== '' && (
							<div className="alert alert-danger" role="alert">
								{internship.error}
							</div>
						)}

						<h1>{internship.name}</h1>
						<p>{internship.description}</p>

						<div className="internship-id-details">
							<img src={calendar} alt="calendar" />
							<h6>
								Start internship:
								{new Date(
									internship.startDate
								).toLocaleDateString()}
							</h6>
						</div>
						<div className="internship-id-details">
							<img src={calendar} alt="calendar" />
							<h6>
								Sfarsit internship:
								{new Date(
									internship.endDate
								).toLocaleDateString()}
							</h6>
						</div>
						<div ref={defaultRef}>
							<Dropzone
								onDrop={handleDrop}
								minSize={1024}
								maxSize={3072000}
								maxFiles={3}
							>
								{({
									getRootProps,
									getInputProps,
									isDragActive,
									isDragAccept,
									isDragReject,
								}) => {
									const additionalClass = isDragAccept
										? 'accept'
										: isDragReject
										? 'reject'
										: '';
									return (
										<div
											{...getRootProps({
												className: `dropzone ${additionalClass}`,
											})}
											ref={dropzoneRef}
										>
											<input {...getInputProps()} />
											<span>
												{isDragActive ? '📂' : '📁'}
											</span>
											<p>Ataseaza fisierele</p>
										</div>
									);
								}}
							</Dropzone>
							<div>
								<strong>Files:</strong>
								<ul>
									{fileNames.map((fileName) => (
										<li key={fileName}>{fileName}</li>
									))}
								</ul>
							</div>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									handleApplyForInternship();
								}}
							>
								Aplica la acest stagiu
							</button>
						</div>
						{internship.internshipId !== undefined &&
							handleInternshipStatus()}
					</>
				</div>
			</div>
		</>
	);
};

export default DashboardInternship;
