import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRoute from './routes';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './Pages/Common/styles/genericPageStyles/style.css';

import { Provider } from 'react-redux';
import store from './store';

import Footer from './Components/Footer';
import Header from './Components/Header';

const urlHistory = window.location.pathname;
urlHistory !== '/login' &&
	window.sessionStorage.setItem('previousUrl', urlHistory);
const path = window.location.pathname.replace(/[\/\d-]/g, '');
const privatePaths = [
	'login',
	'dashboard',
	'dashboardprofil',
	'dashboardcompanii',
	'dashboardstagii',
	'dashboardstagiiid',
	'dashboardsesiuneadestagii',
	'dashboardadmin',
	'dashboardstudenti',
	'dashboardfisierelemele',
];
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Header />
			<AppRoute />
			{!privatePaths.includes(path) && <Footer />}
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
