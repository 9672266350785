import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/rootReducer';
import { deleteAdmin, getAdmins, postAdmin } from './slices/adminsSlice';
import useImagePreloader from '../../../helpers/useImagePreloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Form } from 'react-bootstrap';

import adminBackgroundImg from '../../../assets/images/Dashboard/bg/admins.jpg';

import adminImg from '../../../assets/images/Dashboard/dashboard-icons/admins.png';

import './style/style.css';
import PageLoading from '../../../Components/PageLoading';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Admin = () => {
	const preloadSrcList: string[] = [adminBackgroundImg];
	const { imagesPreloaded } = useImagePreloader(preloadSrcList);

	const firstName = useRef<HTMLInputElement>(null);
	const lastName = useRef<HTMLInputElement>(null);
	const email = useRef<HTMLInputElement>(null);
	const password = useRef<HTMLInputElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const [showPopup, setShowPopup] = useState({ show: false, adminId: '' });

	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState('password');
	const [showPostAdmin, setShowPostAdmin] = useState(false);

	const show = (
		<FontAwesomeIcon icon={faEyeSlash} className="eye eye-closed" />
	);
	const hide = <FontAwesomeIcon icon={faEye} className="eye eye-open" />;

	const admins = useSelector((state: AppState) => state.admins);
	const [postAdminError, setPostAdminError] = useState({
		show: false,
		errMsg: '',
	});

	const handleDeleteAdmin = () => {
		dispatch(deleteAdmin(showPopup.adminId));
		setShowPopup({
			show: false,
			adminId: '',
		});
	};

	const changeInputType = () => {
		showPassword === 'password'
			? setShowPassword('text')
			: setShowPassword('password');
	};

	const handlePostAdmin = () => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (
			firstName.current !== null &&
			firstName.current.value !== '' &&
			lastName.current !== null &&
			lastName.current.value !== '' &&
			email.current !== null &&
			email.current.value !== '' &&
			password.current !== null &&
			password.current.value !== ''
		) {
			if (regex.test(email.current.value) === false) {
				setPostAdminError({
					show: true,
					errMsg: 'Adresa de email invalida',
				});
			} else {
				dispatch(
					postAdmin({
						firstName: firstName.current.value,
						lastName: lastName.current.value,
						email: email.current.value,
						password: password.current.value,
					})
				);
			}
		} else {
			setPostAdminError({
				show: true,
				errMsg: 'Va rugam completati toate campurile',
			});
		}
	};

	useEffect(() => {
		dispatch(getAdmins());
	}, []);

	useEffect(() => {
		if (admins.postPending === false && admins.err === '') {
			setShowPostAdmin(false);
			dispatch(getAdmins());
		}
		if (admins.err !== '') {
			setPostAdminError({
				show: true,
				errMsg: admins.err,
			});
		}
	}, [admins.postPending]);

	useEffect(() => {
		admins.deletePending === false && dispatch(getAdmins());
	}, [admins.deletePending]);

	useEffect(() => {
		if (admins.admins.length > 2) {
			if (contentRef.current !== null) {
				contentRef.current.style.height = '100%';
			}
		} else {
			if (contentRef.current !== null) {
				contentRef.current.style.height = '100vh';
			}
		}
	}, [admins.admins]);

	useEffect(() => {
		if (showPostAdmin) {
			if (contentRef.current !== null) {
				contentRef.current.style.height = '100vh';
			}
		} else if (admins.admins.length > 2) {
			if (contentRef.current !== null) {
				contentRef.current.style.height = '100%';
			}
		}
	}, [showPostAdmin]);

	return (
		<>
			{showPopup.show && (
				<div className="confirmation-popup">
					<div className="confirmation-container">
						<h2>
							Sunteti sigur ca vreti sa stergeti administratorul?
						</h2>
						<div className="confirm-buttons">
							<button
								className="btn btn-primary mr-5"
								onClick={() => handleDeleteAdmin()}
							>
								Da
							</button>
							<button
								className="btn btn-danger"
								onClick={() =>
									setShowPopup({
										show: false,
										adminId: '',
									})
								}
							>
								Nu
							</button>
						</div>
					</div>
				</div>
			)}
			{!showPostAdmin && (
				<div
					className="add-new-admin"
					onClick={() => setShowPostAdmin(true)}
				>
					<p>
						<span>➕</span>Adauga un nou administrator
					</p>
				</div>
			)}
			{admins.pending ? (
				<PageLoading />
			) : !imagesPreloaded ? (
				<PageLoading />
			) : null}
			<div className="admins-container" ref={contentRef}>
				<div className="admins-img-background"></div>
				{!showPostAdmin &&
					admins.admins.length > 0 &&
					admins.admins.map((admin: any) => {
						return (
							<div
								className="admins-content-container"
								key={admin.administratorId}
							>
								<div className="admins-logo">
									<img src={adminImg} alt="admins-logo" />
								</div>
								<button
									className="btn btn-outline-danger delete-admin"
									onClick={() =>
										setShowPopup({
											show: true,
											adminId: admin.administratorId,
										})
									}
								>
									Sterge
								</button>
								<a
									href={`/dashboard/admin/${admin.administratorId}`}
								>
									<div
										className="admins-content"
										key={admin.administratorId}
									>
										<Form.Label htmlFor="lastName">
											Nume
										</Form.Label>
										<h4 id="lastName">{admin.lastName}</h4>
										<Form.Label htmlFor="firstName">
											Prenume
										</Form.Label>
										<h4 id="firstName">
											{admin.firstName}
										</h4>
										<Form.Label htmlFor="email">
											Email
										</Form.Label>
										<h4 id="email">{admin.email}</h4>
									</div>
								</a>
							</div>
						);
					})}

				{showPostAdmin && (
					<div className="post-new-admin">
						<h3>Introduceti un nou administrator</h3>
						<div className="post-new-admin-content">
							<button
								type="button"
								className="btn-close btn-close close-post-admin"
								aria-label="Close"
								onClick={() => setShowPostAdmin(false)}
							></button>
							<Form.Group className="mb-3">
								<Form.Label>Nume</Form.Label>
								<Form.Control
									type="text"
									placeholder="Nume..."
									ref={lastName}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Prenume</Form.Label>
								<Form.Control
									type="text"
									placeholder="Prenume..."
									ref={firstName}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="text"
									placeholder="Email..."
									ref={email}
								/>
							</Form.Group>
							<Form.Group className="mb-3 add-admin-password">
								<Form.Label>Parola</Form.Label>
								<Form.Control
									type={showPassword}
									placeholder="Parola..."
									ref={password}
								/>
								<span onClick={changeInputType}>
									{showPassword === 'password' ? show : hide}
								</span>
							</Form.Group>
							<button
								className="btn btn-primary"
								onClick={() => handlePostAdmin()}
							>
								Adauga administrator
							</button>
						</div>
						{postAdminError.show && (
							<div
								className="alert alert-danger mt-3"
								role="alert"
							>
								{postAdminError.errMsg !== '' &&
									postAdminError.errMsg}
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default Admin;
